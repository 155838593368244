<template>
  <div
    v-if="tipShow"
    class="el-tooltip__popper tip-box"
    :class="`tip-box__${placement}`"
    :x-placement="placement"
  >
    <span v-if="content">{{ content }}</span>
    <!-- @slot -->
    <slot v-else></slot>
    <div x-arrow="" class="popper__arrow"></div>
  </div>
</template>

<script>
/**
 * 黄色tip提示
 *
 * 该提示主要用于第一次进入页面进行展示，后续不再展示的情况出现
 * @displayName StorageTip组件
 * @todo 说明：该组件目前只是提供左，右，右下三种方式展示，后续可按需要增加，背景色目前只是支持一种颜色
 */
export default {
  name: 'StorageTip',

  props: {
    /**
     * 显示位置
     * @values left, right,
     */
    placement: {
      type: String,
      default: 'left',
    },

    /**
     * 展示内容
     */
    content: {
      type: String,
    },

    /**
     * 是否手动在外部设置storage
     */
    autoSet: {
      type: Boolean,
      default: true,
    },

    /**
     * 是否使用storage
     */
    needStorage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tipShow: false,
    };
  },

  created() {
    try {
      const { path } = this.$route;
      const pre = path.split('/')[1];
      const key = `${pre}-tip-show`;
      const { needStorage, autoSet } = this;
      const storage = localStorage.getItem(key);
      if (!needStorage) {
        this.tipShow = true;
      } else if (!storage || storage === 'true') {
        this.tipShow = true;
      } else {
        this.tipShow = false;
      }

      needStorage && autoSet && localStorage.setItem(key, false);
    } catch {
      this.tipShow = false;
    }
  },
};
</script>

<style lang="less" scoped>
.tip-box {
  top: 0;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  background: linear-gradient(to right, #ff9f00, #ffbd00);
  box-shadow: 0 0px 8px 0;

  &__bottom-right {
    top: 100%;
    right: 0;
    .popper__arrow {
      right: 12px;
      border-bottom-color: #ffbd00;
      &::after {
        border-bottom-color: #ffbd00;
      }
    }
  }
}
.el-tooltip__popper {
  &[x-placement^='left'] {
    right: calc(100% + 10px);
  }
  &[x-placement^='left'] .popper__arrow {
    top: 11px;
    border-left-color: #ffbd00;
    &::after {
      border-left-color: #ffbd00;
    }
  }

  &[x-placement^='right'] {
    left: calc(100% + 10px);
  }
  &[x-placement^='right'] .popper__arrow {
    top: 11px;
    border-right-color: #ff9f00;
    &::after {
      border-right-color: #ff9f00;
    }
  }

  &[x-placement^='bottom'] {
    top: 25px;
  }
  &[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: #ff9f00;
    &::after {
      border-bottom-color: #ff9f00;
    }
  }
}
</style>
