<template>
  <el-pagination
    class="pagination"
    :total="total"
    :page-size.sync="scopePageSize"
    :current-page.sync="scopeCurrentPage"
    :page-sizes="pageSizes"
    :layout="layout"
    :hide-on-single-page="isHideOnSinglePage"
    :background="background"
    v-bind="$attrs"
    @size-change="onSizeChange"
    @current-change="onCurrentChange"
  ></el-pagination>
</template>

<script>
export default {
  name: 'KPagination',
  inheritAttrs: false,
  props: {
    hideOnSinglePage: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },

    total: {
      type: Number,
      default: 0,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100],
    },

    layout: {
      type: String,
      default: 'total, prev, pager, next, sizes, jumper',
    },

    background: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scopePageSize: 10,
      scopeCurrentPage: 1,
    };
  },

  computed: {
    isHideOnSinglePage() {
      const { hideOnSinglePage, layout, total } = this;

      if (layout.indexOf('sizes') > -1 && total > 0) {
        return false;
      }

      return hideOnSinglePage;
    },
  },

  watch: {
    pageSize: {
      immediate: true,
      handler(val) {
        this.scopePageSize = val;
      },
    },

    currentPage: {
      immediate: true,
      handler(val) {
        this.scopeCurrentPage = val;
      },
    },
  },
  methods: {
    onSizeChange(pageSize) {
      this.$emit('update:pageSize', pageSize);
      this.$emit('update:currentPage', 1);

      this.$emit('size-change', { pageSize, currentPage: 1 });
    },

    onCurrentChange(currentPage) {
      this.$emit('update:currentPage', currentPage);

      this.$emit('current-page-change', { currentPage });
      // 兼容另一个版本的pagination组件，原文件src\common\components\pagination\index
      this.$emit('current-change', currentPage);
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 32px 0 27px;
  padding: 2px;
  font-size: 14px;
  font-weight: normal;
  color: #76787c;
  text-align: right;

  ::v-deep .el-pagination__total {
    margin-right: 20px;
    line-height: 32px;
  }

  ::v-deep .btn-prev,
  ::v-deep .btn-next,
  ::v-deep .el-pager .number {
    width: 32px;
    min-width: auto;
    height: 32px;
    margin-right: 8px;
    border: 1px solid #e9eaf1;
    border-radius: 2px;
    line-height: 32px;

    &:not(:disabled) {
      &:hover {
        border-color: @g-primary-color;
      }
    }
  }

  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    padding: 0 6px;

    .el-icon {
      font-size: 14px;
    }
  }

  ::v-deep .el-pager .number {
    &.active {
      border: none;
      color: #fff;
      background-color: @g-primary-color;
    }
  }

  ::v-deep .el-pager li {
    &.btn-quicknext,
    &.btn-quickprev {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }

    &:hover {
      border-color: @g-primary-color;
    }
  }

  ::v-deep .el-input__inner {
    height: 32px;
    border-color: #e9eaf1;

    .el-input__suffix {
      color: #7e8091;
    }
  }

  ::v-deep .el-pagination__sizes {
    margin-right: 0;

    .el-input {
      margin: 0;

      .el-select__caret {
        color: #7e8091;
      }

      &:hover {
        .el-select__caret {
          font-weight: bold;
          color: @g-primary-color;
        }
      }
    }
  }

  ::v-deep .el-pagination__jump {
    margin-left: 8px;
  }
}
</style>
