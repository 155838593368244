import 'viewerjs/dist/viewer.css';

import Viewer from 'v-viewer';
import Vue from 'vue';

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 3000,
  },
});
