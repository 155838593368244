export default [
  {
    path: '/productDesc',
    name: 'productDesc',
    component: () => import('@/views/productDesc/entry.vue'),
    children: [
      {
        path: 'home',
        name: 'productDescHome',
        component: () => import('@/views/productDesc/home/entry.vue'),
      },
      {
        path: 'gov',
        name: 'productDescGov',
        component: () => import('@/views/productDesc/gov/entry.vue'),
      },
      {
        path: 'school',
        name: 'productDescSchool',
        component: () => import('@/views/productDesc/school/entry.vue'),
      },
      {
        path: 'schoolRecord',
        name: 'productDescSchoolRecord',
        component: () => import('@/views/productDesc/school/record/entry.vue'),
      },
      {
        path: 'schoolBot',
        name: 'productDescSchoolBot',
        component: () => import('@/views/productDesc/school/bot/entry.vue'),
      },
      {
        path: 'student',
        name: 'productDescStudent',
        component: () => import('@/views/productDesc/student/entry.vue'),
      },
      {
        path: 'parent',
        name: 'productDescParent',
        component: () => import('@/views/productDesc/parent/entry.vue'),
      },
    ],
  },
];
