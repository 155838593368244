import { SET_HOMEWORK_AUTOMATIC_DATA } from '../mutationTypes';

export default {
  [SET_HOMEWORK_AUTOMATIC_DATA](
    store,
    {
      // sourceType = 1,
      lessonIds,
      lessonMap = {},
      questionIds,
      listenIds = [],
      listenMap = [],
      questionMap = {},
      lessonCommodityMap = {},
      lessonKnowledgeMap = {},
      selectedCommodityLessonLengthMap = {},
      selectedKnowledgeQuestionLengthMap = {},
    }
  ) {
    let QuestionIds = questionIds || [];
    let LessonIds = lessonIds || [];
    store.cart.lessonIds = [...LessonIds];
    store.cart.questionIds = [...QuestionIds];
    store.cart = {
      ...store.cart,
      listenIds,
      listenMap,
      lessonMap,
      questionMap,
      lessonCommodityMap,
      lessonKnowledgeMap,
      selectedCommodityLessonLengthMap,
      selectedKnowledgeQuestionLengthMap,
    };
  },
};
