export default {
  // 获取所有课节的知识点列表
  knowledgeList: ({ cart }) => {
    // console.log(store);
    let { lessonIds, lessonKnowledgeMap } = cart;
    let list = [];
    let map = {};
    lessonIds.forEach(id => {
      if (lessonKnowledgeMap[id]) {
        lessonKnowledgeMap[id].forEach(knowledge => {
          if (!knowledge) return;
          if (!map[knowledge.id]) {
            map[knowledge.id] = true;
            list.push(knowledge);
          }
        });
      }
    });
    return list;
  },
  // 当前科目是否为素养课程， 素养课程无试题可选
  isAttainmentSubject: store => store.subject?.id === 100,
  useSchemeNoModify: store => !!(store.scheme.id && !store.modifyScheme),
};
