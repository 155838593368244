<template>
  <div class="content-layout">
    <div ref="breadcrumbWrapRef" class="k-breadcrumb__wrap">
      <div v-if="$scopedSlots.tile" class="tile-wrap">
        <slot name="tile"></slot>
      </div>
      <template v-else>
        <KBreadcrumb v-if="breadcrumb.length" :title="breadcrumb" />
        <div class="header-right">
          <slot name="headerRight"></slot>
        </div>
      </template>
    </div>
    <div class="content-body" :style="{ height: contentHeight }">
      <div class="content-body__inner" :style="{ 'background-color': bgColor }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import KBreadcrumb from './KBreadcrumb.vue';

export default {
  name: 'BreadcrumbContentLayout',

  components: {
    KBreadcrumb,
  },

  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },

    bgColor: {
      type: String,
      default: '#fff',
    },
  },

  data() {
    return {
      contentHeight: '100%',
    };
  },

  mounted() {
    const { height = 0 } = this.$refs.breadcrumbWrapRef.getBoundingClientRect();

    this.contentHeight = `calc(100% - ${height}px)`;
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  position: relative;
  height: 100%;
  overflow: hidden;

  .k-breadcrumb__wrap {
    position: relative;
    border-bottom: 1px solid #e9eaf1;
    // margin-bottom: 12px;
    .header-right {
      position: absolute;
      top: 0;
      right: 20px;
      display: flex;
      align-items: center;
      height: 60px;
    }
  }

  .tile-wrap {
    background-color: #fff;
  }
}

.content-body {
  box-sizing: border-box;
  height: calc(100% - 60px);
  padding: 16px;
  overflow: auto;

  &__inner {
    min-height: 100%;
    background-color: #fff;
  }
}
</style>
