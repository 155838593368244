import {
  CHOOSE_HOMEWORK_SCHEME,
  SET_HOMEWORK_ABNORMALITY_QUESTION_NUMBER,
} from '../mutationTypes';

export default {
  [CHOOSE_HOMEWORK_SCHEME](
    store,
    {
      // sourceType = 1,
      id,
      title,
      releaseCount,
      description,
      version,
      lessonIds,
      lessonMap = {},
      questionIds,
      subjectName,
      subjectId,
      listenIds = [],
      listenMap = [],
      shareTypes = [],
      planStatus = 1,
      degree = 0,
      tagId = 0,
      termId = 3,
      shareStatus = false,
      knowledgeNames = [],
      questionMap = {},
      workCreator = false,
      lessonCommodityMap = {},
      lessonKnowledgeMap = {},
      abnormalityQuestionNum = 0,
      selectedCommodityLessonLengthMap = {},
      selectedKnowledgeQuestionLengthMap = {},
      collection = false,
      provinceCode,
      cityCode,
      countyCode,
    }
  ) {
    store.version = version;
    store.subject = {
      ...store.subject,
      name: subjectName,
      id: subjectId,
    };
    // store.sourceType = sourceType;
    store.scheme = {
      ...store.scheme,
      id,
      title,
      shareTypes,
      workCreator,
      description,
      releaseCount,
      knowledgeNames,
      abnormalityQuestionNum,
      planStatus,
      shareStatus,
      degree,
      termId,
      tagId,
      collection,
      provinceCode,
      cityCode,
      countyCode,
    };
    let QuestionIds = questionIds || [];
    let LessonIds = lessonIds || [];
    store.cart.lessonIds = [...LessonIds];
    store.cart.questionIds = [...QuestionIds];
    store.cart = {
      ...store.cart,
      listenIds,
      listenMap,
      lessonMap,
      questionMap,
      lessonCommodityMap,
      lessonKnowledgeMap,
      selectedCommodityLessonLengthMap,
      selectedKnowledgeQuestionLengthMap,
    };
    store.modifyScheme = false;
  },
  [SET_HOMEWORK_ABNORMALITY_QUESTION_NUMBER](store, abnormalityQuestionNum) {
    store.scheme.abnormalityQuestionNum = abnormalityQuestionNum;
  },
};
