<template>
  <button class="btn" :type="nativeType" :data-type="type" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'KButton',
  props: {
    type: {
      default: 'default',
    },
    disabled: {
      default: false,
    },
    nativeType: {
      default: 'button',
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import './style.less';
</style>
