/*
 * @Author: your name
 * @Date: 2022-01-04 10:54:00
 * @LastEditTime: 2022-03-02 16:28:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \kkl-online-school\src\store\modules\liveCourse\modules\liveRoom.js
 */

import { queryLiveInfo } from '@/fetchs/liveservice/LiveRoomFacade';

export const SETCHANNELINFO = 'SETCHANNELINFO';
export const SETIMINFO = 'SETIMINFO';
export const SETUSERSHOWNAME = 'SETUSERSHOWNAME';
export const SETCONTROLLERBARSHOWFLAG = 'SETCONTROLLERBARSHOWFLAG';

const state = {
  channel: {
    channel: '',
    appid: '',
    nonce: '',
    timestamp: 0,
    token: '',
    gslb: '',
  },
  IM: {
    groupId: '',
    appId: '',
    sign: '',
    /* 互动聊天频道当前状态（1:自由发言；2:全局禁言；） */
    groupStatus: 1, 
    /* 互动聊天用户当前状态（1:自由发言；2:全局禁言；） */
    userStatus: 1,
  },
  userShowName: '',
  controllerBar: {
    show: false,
    duration: 5 * 1000,
  },
};

const mutations = {
  [SETCHANNELINFO](state, data) {
    state.channel = Object.assign(state.channel, data);
  },
  [SETIMINFO](state, data) {
    state.IM = Object.assign(state.IM, data);
  },
  [SETUSERSHOWNAME](state, name) {
    state.userShowName = name;
  },
  [SETCONTROLLERBARSHOWFLAG](state, flag) {
    state.controllerBar.show = flag;
  },
}

const actions = {
  getSDKAuthInfo({ state, commit }, liveCode) {
    return queryLiveInfo({
      liveCode: liveCode, 
    })
    
  },
}

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default store;