import { ADD_HOMEWORK_LISTEN, REMOVE_HOMEWORK_LISTEN } from '../mutationTypes';

export default {
  [ADD_HOMEWORK_LISTEN](store, item) {
    const id = item.id || item.testPaperId;
    store.cart.listenIds = [id];
    store.cart.listenMap[id] = item;
  },
  [REMOVE_HOMEWORK_LISTEN](store) {
    if (store.cart.listenIds.length) {
      store.cart.listenIds.forEach(oldId => {
        delete store.cart.listenMap[oldId];
      });
      store.cart.listenIds = [];
    }
    store.modifyScheme = true;
  },
};
