<template>
  <header id="appHeader" class="header">
    <div class="header_container">
      <slot v-if="$slots.left" name="left"></slot>
      <div v-else class="school_info">
        <img class="school_logo" :src="schoolLogin" />
        <span class="school_title">{{ schoolTitle }}</span>
      </div>

      <slot></slot>

      <el-dropdown @command="handleCommand">
        <div class="user">
          <img class="user_photo" :src="user.photoPath" />
          <span class="user-info">{{ userName }}</span>
          <i class="icon-more"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in dropdownMenuList"
            :key="item.key"
            :command="item.key"
            class="dropdown-item"
          >
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 退出登录 -->
    <k-dialog
      v-if="showLogOutDialogVisible"
      :showTitle="true"
      :showIcon="true"
      :showContent="true"
      content="确定要退出吗？"
      @update:visible="showLogOutDialogVisible = false"
      @sure="logOut"
    ></k-dialog>

    <!-- 修改密码 -->
    <EditPasswordDialog
      v-if="editPasswordDialogVisible"
      :visible.sync="editPasswordDialogVisible"
    />
  </header>
</template>

<script>
import EditPasswordDialog from './EditPasswordDialog.vue';
import KDialog from '@/common/components/dialog';

import { mapState, mapGetters } from 'vuex';

import store from '@/store/modules/user';
import logo from '@/assets/index/logo.png';
// import yucaiLogo from '@/assets/logo/yucai-logo.png';

import { getRedictPageName } from '@/common/scripts/utils/business/menu.js';

export default {
  name: 'WlxHeader',

  components: {
    EditPasswordDialog,
    KDialog,
  },

  data() {
    const memType = this.$getCache('memType');

    return {
      memType,
      // dropdownMenuList: [],
      showLogOutDialogVisible: false,
      editPasswordDialogVisible: false,
    };
  },

  computed: {
    ...mapGetters('user', ['getResultRouters']),

    ...mapState(store.name, {
      user: (state) => state,
    }),

    schoolLogin() {
      const {
        user: { showSchoolBadgeUrl },
      } = this;
      const schoolLogin = showSchoolBadgeUrl || logo;
      return schoolLogin;
    },

    schoolTitle() {
      const {
        user: { showSchoolName },
      } = this;
      return showSchoolName;
    },

    userName() {
      const {
        memType,
        user: { name },
      } = this;
      return `${name}${memType === 99 ? '' : '老师'}`;
    },

    dropdownMenuList() {
      const list = this.getDropdownMenuList();

      return list;
    },

    // teacherCenterFirstPageName() {
    //   const list = this.getResultRouters;
    //   const l = list.length;

    //   for (let i = 0; i < l; i++) {
    //     const item = list[i];
    //     const { name, children } = item;

    //     if (name === 'teachingCenter' && children.length) {
    //       return children[0].name;
    //     }
    //   }
    // },
    arrangeRecordFirstPageName() {
      const list = this.getResultRouters;
      const l = list.length;

      for (let i = 0; i < l; i++) {
        const item = list[i];
        const { name, children } = item;

        if (name === 'arrangeCenter' && children.length) {
          return getRedictPageName(children);
        }
      }
      return '';
    },
  },

  created() {
    this.commandMap = {
      HELP_CENTER: this.gotoHelpCenter,
      ARRANGE_RECORD: this.gotoArrangeRecord,
      EDIT_PASSWORD: this.showEditPasswordDialog,
      EXIT: this.showLogOutDialog,
      TEACH_CENTER: this.gotoTeachCenter,
    };
  },

  methods: {
    getDropdownMenuList() {
      const { memType, arrangeRecordFirstPageName } = this;

      const dropdownMenuList = [
        memType === 2 &&
          arrangeRecordFirstPageName && {
            key: 'ARRANGE_RECORD',
            name: '布置中心',
          },
        memType === 2 && {
          key: 'HELP_CENTER',
          name: '帮助中心',
        },
        // teacherCenterFirstPageName && {
        //   key: 'TEACH_CENTER',
        //   name: '教学中心',
        // },
        {
          key: 'EDIT_PASSWORD',
          name: '修改密码',
        },
        {
          key: 'EXIT',
          name: '退出登录',
        },
      ].filter(Boolean);

      return dropdownMenuList;
    },

    handleCommand(command) {
      this.commandMap[command]();
    },

    gotoHelpCenter() {
      const route = this.$router.resolve('/help-center');
      window.open(route.href, '_blank');
    },

    gotoArrangeRecord() {
      this.$router.push({
        name: this.arrangeRecordFirstPageName,
      });
    },

    gotoTeachCenter() {
      const route = this.$router.resolve({
        name: this.teacherCenterFirstPageName,
      });
      window.open(route.href, '_blank');
    },

    showEditPasswordDialog() {
      this.editPasswordDialogVisible = true;
    },

    showLogOutDialog() {
      this.showLogOutDialogVisible = true;
    },

    logOut() {
      this.$store.commit('LOGOUT');
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 8px 0;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.1);
  position: relative;
  z-index: 10;
}
.header_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.school_info {
  line-height: 48px;
}
.school_logo {
  height: 48px;
  // 添加width,防止在IE10中样式乱
  width: auto;
  margin-left: 24px;
}
.school_title {
  font-size: 14px;
  color: #333333;
  letter-spacing: 0.7px;
  font-weight: bold;
  margin-left: 8px;
}

.user {
  display: inline-flex;
  margin-right: 16px;
  width: 250px;
  align-items: center;
  justify-content: flex-end;
  line-height: 48px;
  text-align: right;
  cursor: pointer;
  .user_photo {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 16px;
  }
  .user-info {
    display: inline-block;
    max-width: 120px;
    text-align: left;
    .gSingleLine();
  }
  .icon-more {
    .square(16px);
    margin-left: 16px;
    background: url('~@/assets/icons/icon-more.png') no-repeat;
    background-size: 100%;
  }
}

.dropdown-item {
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: @g-primary-color;
  }
}
</style>
