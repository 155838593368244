<template>
  <div class="no-data">
    <div class="empty-area">
      <div class="flex-area">
        <img class="img" :src="placeholderImg" :alt="desc" />
        <div class="content">
          <p class="description" v-html="desc"></p>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoData',
  props: {
    placeholderImg: {
      type: String,
      default:
        'https://festatic.estudy.cn/assets/kkl-online-school/public/no-homeork-record.png',
    },
    desc: {
      type: String,
      default: '当前暂无数据',
    },
  },
};
</script>
<style lang="less" scoped>
.no-data {
  margin: 0 auto;
  height: 420px;
  display: table;
  width: 100%;
  .empty-area {
    vertical-align: middle;
    display: table-cell;
  }
  .flex-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .img {
    width: 218px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .description {
    font-size: 14px;
    color: @g-font-light-color;
    padding: 16px 0;
    text-align: center;
  }
}
</style>
