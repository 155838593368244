import { extend } from '@/common/scripts/utils/object';
import {
  SET_HOMEWORK,
  INIT_HOMEWORK,
  SET_HOMEWORK_TITLE,
  SET_HOMEWORK_STATUS,
  SET_HOMEWORK_SOURCE_TYPE,
  SET_SELECT_HOMEWORK_CACHE_FLAG,
  RESET_SELECT_HOMEWORK_CACHE_FLAG,
  EMPTY_HOMEWORK,
  SET_IS_TAB_TIP_SHOW,
  INIT_TAB_TIP_SHOW,
  SET_QUESTION_LIST,
  SET_SUBJECT,
  SET_CART_INFO,
} from '../mutationTypes';
import state from '../state';

const initStateStr = JSON.stringify(state);

export default {
  [INIT_HOMEWORK](store, version = 2) {
    let initState = JSON.parse(initStateStr);
    initState.version = version; // 新建的作业默认使用新题库
    // setter getter 监听引用类型内存地址变化，所有每次初始化需要重新分配内存；
    extend(store, initState);
  },
  [SET_HOMEWORK](store, homework) {
    // console.log(store, title);
    extend(store, homework);
  },
  [SET_SUBJECT](store, subject) {
    store.subject = subject;
  },
  [SET_HOMEWORK_STATUS](store, homeworkStatus) {
    store.homeworkStatus = homeworkStatus;
  },
  [SET_HOMEWORK_TITLE](store, { title }) {
    // console.log(store, title);
    store.curPageTitle = title;
  },
  [SET_HOMEWORK_SOURCE_TYPE](store, type) {
    // console.log(store, title);
    store.sourceType = type;
  },
  [SET_SELECT_HOMEWORK_CACHE_FLAG](store, key) {
    store.isResetCache = true;
    store.resetCacheKey = key;
  },
  [RESET_SELECT_HOMEWORK_CACHE_FLAG](store) {
    store.isResetCache = false;
    store.resetCacheKey = '';
  },
  [EMPTY_HOMEWORK](store) {
    store.cart = {
      lessonIds: [],
      lessonMap: {},
      lessonCommodityMap: {},
      selectedCommodityLessonLengthMap: {},
      lessonKnowledgeMap: {},
      questionIds: [],
      questionMap: {},
      selectedKnowledgeQuestionLengthMap: {},
      listenIds: [],
      listenMap: {},
      schoolPaperIds: [],
      schoolPaperMap: {},
    };
  },
  [SET_IS_TAB_TIP_SHOW](store) {
    store.isTabTipShow = false;
  },
  [INIT_TAB_TIP_SHOW](store) {
    store.isTabTipShow = true;
  },
  [SET_QUESTION_LIST](store, payload) {
    store.questionList = payload;
  },

  [SET_CART_INFO](store, payload) {
    store.cart = { ...store.cart, ...payload };
  },
};
