/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: zgz
 * @Last Modified time: 2018-11-30 10:10:37
 */

/**
 * 根据学校和入学年份查询班级
 * @export
 * @returns promise
 */
export function getMemberInfoById() {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.MemberInfoFacade.getMemberInfoById',
      param: {},
    },
  });
}

// 校验学生名字存在
export function countDuplicateName({ name }) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.MemberQueryFacade.countDuplicateName',
      param: {
        bparam: {
          name,
        },
      },
    },
  });
}

// 查询账号
export function listFindMember({ name, schoolId, grade, phase }) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.MemberQueryFacade.listFindMember',
      param: {
        bparam: {
          name,
          schoolId,
          grade,
          phase,
        },
      },
    },
  });
}

/**
 * 通过学生name搜索学生信息
 * @param {string} studentName [学生名字]
 * @returns
 */
export function searchStudentsByName(studentName) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.MemberQueryFacade.searchStudentsByName',
      param: {
        studentName,
      },
    },
  });
}

// 查看心理权限校验
export const queryMentalArchiveCheck = () => {
  return KKL.kapi({
    serviceName: 'aischool.MemberQueryFacade.queryMentalArchiveCheck',
  });
};
