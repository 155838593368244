import omoIcon from '@/assets/nav-icon/周培优课程1@2x.png';
import omoActiveIcon from '@/assets/nav-icon/周培优课程2@2x.png';
import reportIcon from '@/assets/nav-icon/周培优数据报告1@2x.png';
import reportActiveIcon from '@/assets/nav-icon/周培优数据报告2@2x.png';
import analysisIcon from '@/assets/nav-icon/学情分析1@2x.png';
import analysisActiveIcon from '@/assets/nav-icon/学情分析2@2x.png';
import applySettingIcon from '@/assets/nav-icon/applySettingIcon.png';
import applySettingIconActive from '@/assets/nav-icon/applySettingIconActive.png';

export default [
  {
    path: '/weekTrain',
    name: 'weekTrain',
    redirect: '/weekTrain/course',
    component: () => import('@/views/week-train/entry.vue'),
    meta: {
      menuName: 'OMO自主学习',
      roleId: '11011',
      role: 'weektrain',
    },
    children: [
      {
        path: 'aiCourse',
        name: 'aiCourse',
        redirect: '/weekTrain/aiCourse/list',
        component: () => import('@/views/week-train/aiCourse/entry.vue'),
        meta: {
          menuName: 'OMO课程',
          icon: omoIcon,
          activeIcon: omoActiveIcon,
          roleId: '11012',
          role: 'weektrain.course',
        },
        children: [
          {
            path: 'list',
            name: 'aiCourseList',
            component: () => import('@/views/week-train/aiCourse/list/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11013',
              role: 'weektrain.course.list',
            },
          },
          {
            path: 'detail',
            name: 'aiCourseDetail',
            component: () => import('@/views/week-train/aiCourse/detail/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11014',
              role: 'weektrain.course.detail',
            },
          },
          {
            path: 'download',
            name: 'downloadPlan',
            component: () => import('@/views/week-train/aiCourse/downloadPlan/entry.vue'),
            meta: {
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'dataReport',
        name: 'dataReport',
        redirect: '/weekTrain/dataReport/list',
        component: () => import('@/views/week-train/dataReport/entry.vue'),
        meta: {
          menuName: '数据报告',
          icon: reportIcon,
          activeIcon: reportActiveIcon,
          roleId: '11015',
          role: 'weektrain.datareport',
        },
        children: [
          {
            path: 'list',
            name: 'dataReportList',
            component: () => import('@/views/week-train/dataReport/list/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11016',
              role: 'weektrain.datareport.list',
            },
          },
          {
            path: 'detail',
            name: 'dataReportDetail',
            component: () => import('@/views/week-train/dataReport/detail/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11017',
              role: 'weektrain.datareport.detail',
            },
          },
          {
            path: 'overview',
            name: 'completeOverview',
            component: () => import('@/views/week-train/dataReport/overview/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11018',
              role: 'weektrain.datareport.overview',
            },
          },
          {
            path: 'studentData',
            name: 'studentData',
            component: () => import('@/views/week-train/dataReport/studentData/entry.vue'),
            meta: {
              hideInMenu: true,
              // roleId: '11018',
              // role: 'weektrain.datareport.studentdata',
            },
          },
        ],
      },
      {
        path: 'learningAnalysis',
        name: 'learningAnalysis',
        redirect: '/weekTrain/learningAnalysis/detail',
        component: () => import('@/views/week-train/learningAnalysis/entry.vue'),
        meta: {
          menuName: '学情分析',
          icon: analysisIcon,
          activeIcon: analysisActiveIcon,
          roleId: '11047',
          role: 'weektrain.learninganalysis',
        },
        children: [
          {
            path: 'detail',
            name: 'learningAnalysisDetail',
            component: () => import('@/views/week-train/learningAnalysis/detail/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11048',
              role: 'weektrain.learninganalysis.detail',
            },
          },
        ],
      },
      {
        path: 'learningTools',
        name: 'learningTools',
        redirect: '/weekTrain/learningTools/detail',
        component: () => import('@/views/week-train/learningTools/entry.vue'),
        meta: {
          menuName: '应用设置',
          icon: applySettingIcon,
          activeIcon: applySettingIconActive,
          roleId: '11171',
          role: 'weektrain.learningTools',
        },
        children: [
          {
            path: 'detail',
            name: 'learningToolsDetail',
            component: () => import('@/views/week-train/learningTools/detail/entry.vue'),
            meta: {
              hideInMenu: true,
              roleId: '11172',
              role: 'weektrain.learningTools.detail',
            },
          },
        ],
      },
    ],
  },
];
