import {
  saveByMemberAndKey,
  queryByMemberAndKey,
  deleteByMemberAnyKey,
} from '@/fetchs/week-train/CommonKeyValueFacade';
import { listQuestionByIdsV2 } from '@/fetchs/aischool/WrongQuestionStatQueryFacade';
import { getScript } from '@/common/scripts/utils/getScript';
import { Message } from 'element-ui';

const state = {
  questionBasketList: [],
  questionBasketIds: [],
  questionBaskSectionList: [],
};

const mutations = {
  SET_QUESTION_BASKET_LIST(state, payload) {
    state.questionBasketList = payload;
    console.log(state.questionBasketList);
  },
  SET_QUESTION_BASKET_IDS(state, payload) {
    state.questionBasketIds = payload;
  },
};

const key = 'basketDataV2';

const actions = {
  async saveBaskData(context, payload) {
    try {
      if (!payload || !payload.length) {
        await deleteByMemberAnyKey(key);
        Message.success('操作成功');
        context.commit('SET_QUESTION_BASKET_IDS', []);
        context.commit('SET_QUESTION_BASKET_LIST', []);
      } else {
        const formateData = payload.map((item) => ({ id: item.id, questionId: item.questionId }));
        await getScript('lzbase62');
        // 压缩字符串
        const value = lzbase62.compress(JSON.stringify(formateData)); // eslint-disable-line
        await saveByMemberAndKey(key, value);
        Message.success('操作成功');
        const ids = payload.map((item) => item.id);
        context.commit('SET_QUESTION_BASKET_IDS', ids);
        await context.dispatch('getQuestionBasketList', payload);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getBaskData(context) {
    try {
      const { value } = await queryByMemberAndKey(key);
      if (value) {
        await getScript('lzbase62');
        // 解压缩字符串
        const dataStr = lzbase62.decompress(value); // eslint-disable-line
        const data = JSON.parse(dataStr);
        const ids = data.map((item) => item.id);
        context.commit('SET_QUESTION_BASKET_IDS', ids);
        context.dispatch('getQuestionBasketList', data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getQuestionBasketList(context, payload) {
    try {
      const data = await listQuestionByIdsV2(context.state.questionBasketIds);
      const list = payload.map((item) => {
        return {
          ...item,
          question: data.find((q) => q.id === item.questionId),
        };
      });
      context.commit('SET_QUESTION_BASKET_LIST', list);
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  name: 'questionBasketList',
  namespaced: true,
  state,
  mutations,
  actions,
};
