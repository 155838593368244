<template>
  <div class="page-title">
    <h3>{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.page-title {
  display: flex;
  h3 {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-right: 16px;
    align-self: center;
    white-space: nowrap;
  }
}
</style>
