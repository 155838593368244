// 后端接口对应的前端权限管理字段
export const interfaceKeyToPermissionKeyMap = {
  // mailAA: 'headmasterMail', // 校长信箱(校级管理员+超管)
  haveAA: 'teacherManage', // 教师管理权限
  // psyHaveAA: 'testingManage', // 测评权限
  // psyLayoutEnable: 'testingArrange', // 测评布置
  // schResourceHaveAA: 'schoolResourceManage', // 校本资源权限
  // psyTrainingReleaseHaveAA: 'trainReleaseManage', // 心理训练布置权限
  // psyTrainingClassHaveAA: 'trainClassManage', // 心理训练上课权限
  // campusReaderAA: 'campusReaderManage', // 晨读学习权限
  // liveAA: 'liveManage', // 直播权限
};

/**
 * 权限map字段
 */
export const permissionKeyMap = Object.keys(
  interfaceKeyToPermissionKeyMap
).reduce((prev, key) => {
  prev[interfaceKeyToPermissionKeyMap[key]] = false;
  return prev;
}, {});

