/*
 * @Author: sanqi
 * @Date:   2017-09-21 14:36:23
 * @Last Modified by: sanqi.zgz
 * @Last Modified time: 2017-11-06 10:33:48
 */
const isObject = object =>
  Object.prototype.toString.call(object) === '[object Object]';

/**
 * extend 继承方法
 * @param {*} oldObject  继承的对象
 * @param {*} newObject  被继承的对象
 * @param {*} deepFlag  是否深度继承
 */
const extend = (oldObject, newObject, deepFlag) => {
  if (deepFlag) {
    Object.keys(newObject).forEach(key => {
      if (isObject[oldObject[key]] && isObject[newObject[key]]) {
        extend(oldObject[key], newObject[key]);
        return;
      }
      oldObject[key] = newObject[key];
    });
    return;
  }
  Object.assign(oldObject, newObject);
};

export { extend };
