/**
 * date format
 * @param {*} parm   key 或者 src
 */
import libEnums from '@/libs/enums';
const loadMap = {}; // 资源已加载清单

export function getScript(target) {
  if (!loadMap[target]) {
    loadMap[target] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const libSrc = libEnums[target] || target;
      if (!libSrc) {
        reject(Error(`lib or resource '${target}' not defined`));
        return;
      }
      script.src = libSrc;
      script.onload = () => {
        document.body.removeChild(script);
        resolve();
      };
      script.onerror = () => {
        document.body.removeChild(script);
        reject(); // eslint-disable-line
        return;
      };
      document.body.appendChild(script);
    });
  }
  return loadMap[target];
}

/**
 * @description: 获取lottie库
 * @return {type: Promise}
 */
export function getLottie() {
  if (typeof lottie === 'undefined') {
    let url = 'https://festatic.estudy.cn/libs/lottie/5.5.9/lottie.min.js';
    // 是否支持svg
    if (
      !!document.createElementNS &&
      !!document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        .createSVGRect
    ) {
      url = 'https://festatic.estudy.cn/libs/lottie/5.5.9/lottie_svg.min.js';
    }
    return getScript(url);
  } else {
    return Promise.resolve(window.lottie);
  }
}
