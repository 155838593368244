import {
  SET_HOMEWORK_SUBJECT,
  SET_HOMEWORK_SUBJECTLIST,
  SET_HOMEWORK_SUBJECTQUESTIONTYPELIST,
} from '../mutationTypes';

export default {
  [SET_HOMEWORK_SUBJECT](store, subject) {
    store.subject = {
      ...store.subject,
      ...subject,
    };
  },
  [SET_HOMEWORK_SUBJECTLIST](store, subjectList) {
    store.subjectList = [...subjectList];
  },
  [SET_HOMEWORK_SUBJECTQUESTIONTYPELIST](store, subjectQuestionTypeList) {
    store.subjectQuestionTypeList = subjectQuestionTypeList;
  },
};
