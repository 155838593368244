// 初始化数据
export const INIT_PAPER_DETAIL = 'SET_PAPER_DETAIL';

const state = {
  // 试卷名称
  title: '',
  /**
   * @description questionTypeMap是关于key为questionType，value为{ name: '题目类型', count: '该题型的数量' }
   */
  questionTypeMap: null,
  // 试题数目
  questionCount: void 0,
};

const mutations = {
  [INIT_PAPER_DETAIL](store, payload) {
    Object.assign(store, payload);
  },
};

export default {
  name: 'paperDetail',
  namespaced: true,
  state,
  mutations,
};
