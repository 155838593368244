// 初始化数据
export const SET_LIVE_COURSE_DETAIL = 'SET_LIVE_COURSE_DETAIL';

const state = {
  // 课程名称
  title: '',
  color: null,
  studentCnt: null,
  id: null,
  lessonCnt: null,
};

const mutations = {
  [SET_LIVE_COURSE_DETAIL](store, payload) {
    Object.assign(store, payload);
  },
};

export default {
  name: 'liveCourseDetail',
  namespaced: true,
  state,
  mutations,
};
