<template>
  <header class="header-tile" :class="{ 'no-border': !hasBorder }">
    <h4>
      <span v-if="hasBack" class="back" @click="onBack"
        ><i class="el-icon-back"></i><span class="back-title"> 返回</span></span
      >
      <span v-if="title" class="tw-relative">
        <span
          v-if="hasBack"
          class="tw-absolute -tw-left-[6px] tw-top-1/2 tw-h-[16px] tw-w-[1px] -tw-translate-y-1/2 tw-bg-[#dcdfe6]"
        ></span>
        {{ title }}
      </span>
      <slot></slot>
    </h4>
    <slot name="right"></slot>
  </header>
</template>

<script>
export default {
  name: 'HeaderTile',

  props: {
    title: {
      type: String,
    },

    hasBorder: {
      type: Boolean,
      default: true,
    },

    hasBack: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onBack() {
      if (this._events.back?.length) {
        this.$emit('back');
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 20px 0;
  height: 60px;
  margin: 0 24px;
  font-weight: bold;

  &:not(.no-border) {
    border-bottom: 1px solid #eeeff3;
  }

  .back {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: @primary-text-2;
    cursor: pointer;

    .el-icon-back {
      margin-right: 4px;
    }

    .back-title {
      display: inline-block;
      margin-right: 20px;
    }
  }

  > h4 {
    font-size: 16px;
    color: @primary-text-2;
  }
}
</style>
