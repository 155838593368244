<template>
  <el-dialog
    title="修改密码"
    width="530px"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :before-close="onBeforeClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input
          v-model="form.oldPassword"
          placeholder="请输入原密码"
          show-password
        ></el-input>
      </el-form-item>

      <el-form-item label="新密码" prop="newPassword">
        <el-input
          v-model="form.newPassword"
          placeholder="请输入新密码，6-16位数字或字母"
          show-password
        ></el-input>
      </el-form-item>

      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input
          v-model="form.confirmPassword"
          placeholder="请再次输入新密码"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="onBeforeClose">取 消</el-button>
      <el-button
        type="primary"
        :disabled="isSubmit"
        :loading="isSubmit"
        @click="onConform"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import md5 from 'md5';

import regex from '@/common/scripts/utils/regex';

import { modifyPassword } from '@/fetchs/aischool/MemberInfoFacade';

export default {
  name: 'EditPasswordDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validatePassword = (rule, value, callback) => {
      console.log(regex.password.test(value));
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!regex.password.test(value)) {
        callback(new Error('请输入6-16位数字或字母'));
      } else {
        callback();
      }
    };

    const validatePassword2 = (rule, value, callback) => {
      console.log(this.form.newPassword);
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入的新密码不一致'));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: true,
      isSubmit: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            validator: validatePassword,
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          {
            validator: validatePassword2,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    onBeforeClose() {
      this.$emit('update:visible', false);
    },

    onConform() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        const { newPassword, oldPassword } = this.form;
        const pwdLevel = this.getPwdLevel(newPassword);

        const bparam = {
          pwdLevel,
          oldPassword: md5(oldPassword),
          newPassword: md5(newPassword),
        };
        console.log(pwdLevel);
        // return;
        this.isSubmit = true;
        modifyPassword(bparam)
          .then(() => {
            KKL.message.success('修改成功');
            this.onBeforeClose();
          })
          .finally((this.isSubmit = false));
      });
    },

    getPwdLevel(pwd) {
      const weakRegExp = /^([A-Za-z])\1+$|(^[0-9]+$)/;

      // const middleRegExp = /(?!^([A-Za-z])\1+$)(?!^[0-9]+$)^[a-zA-Z0-9]+$/;
      // 只有数字或者字母是相同的
      if (pwd.match(weakRegExp)) {
        return 1;
      }

      // 存在两种以上字母，或者字母+数字
      if (
        !pwd.match(/^[0-9]+$/) &&
        !pwd.match(/^([A-Za-z])\1+$/) &&
        pwd.match(/^[a-zA-Z0-9]+$/)
      ) {
        return 2;
      }

      return 3;
    },
  },
};
</script>
