import personnalResourceIcon from '@/assets/nav-icon/个人资源库1@2x.png';
import personnalResourceActiveIcon from '@/assets/nav-icon/个人资源库2@2x.png';
import yucaiVideoIcon from '@/assets/nav-icon/学生端预览1@2x.png';
import yucaiVideoActiveIcon from '@/assets/nav-icon/学生端预览2@2x.png';
import courseIcon from '@/assets/nav-icon/我的系列课1@2x.png';
import courseActiveIcon from '@/assets/nav-icon/我的系列课2@2x.png';
import schoolResourceIcon from '@/assets/nav-icon/校本资源库1@2x.png';
import schoolResourceActiveIcon from '@/assets/nav-icon/校本资源库2@2x.png';
import toolIcon from '@/assets/nav-icon/班级管理1@2x.png';
import toolActiveIcon from '@/assets/nav-icon/班级管理2@2x.png';
import resourceIcon from '@/assets/nav-icon/精选课程1@2x.png';
import resourceActiveIcon from '@/assets/nav-icon/精选课程2@2x.png';

export default [
  {
    path: '/teachingCenter',
    name: 'teachingCenter',
    redirect: '/teachingCenter/course',
    component: () => import('@/views/teaching-center/entry.vue'),
    meta: {
      role: 'teachingCenter',
      roleId: '300',
      menuName: '教学资源',
    },
    children: [
      {
        path: 'schoolResource',
        name: 'schoolResource',
        redirect: '/teachingCenter/schoolResource/index',
        component: () =>
          import('@/views/teaching-center/schoolResource/entry.vue'),
        meta: {
          role: 'teachingCenter.schoolresource',
          roleId: '11049',
          menuName: '校本资源库',
          icon: schoolResourceIcon,
          activeIcon: schoolResourceActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'schoolResourceIndex',
            component: () =>
              import('@/views/teaching-center/schoolResource/index/entry.vue'),
            meta: {
              role: 'teachingCenter.schoolresource.list',
              roleId: '11050',
              hideInMenu: true,
            },
          },
          {
            path: 'basket',
            name: 'basket',
            component: () =>
              import('@/views/teaching-center/schoolResource/basket/entry.vue'),
            meta: {
              role: 'teachingCenter.schoolresource.list',
              roleId: '11050',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'personalResource',
        name: 'personalResource',
        redirect: '/teachingCenter/personalResource/index',
        component: () =>
          import('@/views/teaching-center/personalResource/entry.vue'),
        meta: {
          role: 'teachingCenter.peopleresource',
          roleId: '11051',
          menuName: '个人资源库',
          icon: personnalResourceIcon,
          activeIcon: personnalResourceActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'personalResourceIndex',
            component: () =>
              import(
                '@/views/teaching-center/personalResource/index/entry.vue'
              ),
            meta: {
              role: 'teachingCenter.peopleresource.list',
              roleId: '11052',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '',
        name: 'teachingResource',
        redirect: '/teachingCenter/videoResource/index',
        component: () =>
          import('@/views/teaching-center/teaching-resource/entry.vue'),
        meta: {
          roleId: '11000',
          role: 'teachingCenter.resource',
          menuName: '铭师堂资源库',
          hasSubmenu: true,
          icon: resourceIcon,
          activeIcon: resourceActiveIcon,
        },
        children: [
          {
            path: 'videoResource',
            name: 'videoResource',
            redirect: '/teachingCenter/videoResource/index',
            component: () =>
              import('@/views/teaching-center/video_repo/entry.vue'),
            meta: {
              role: 'teachingCenter.video',
              roleId: '325',
              menuName: '视频资源库',
            },
            children: [
              {
                path: 'index',
                name: 'videoResourceIndex',
                component: () =>
                  import('@/views/teaching-center/video_repo/entry.vue'),
                meta: {
                  role: 'teachingCenter.video.list',
                  roleId: '326',
                  hideInMenu: true,
                },
              },
            ],
          },

          {
            path: 'questionResource',
            name: 'questionResource',
            redirect: '/teachingCenter/questionResource/index',
            component: () =>
              import('@/views/teaching-center/question-resource/entry.vue'),
            meta: {
              role: 'teachingCenter.question',
              roleId: '11007',
              menuName: '试题资源库',
            },
            children: [
              {
                path: 'index',
                name: 'questionResourceIndex',
                component: () =>
                  import(
                    '@/views/teaching-center/question-resource/index/entry.vue'
                  ),
                meta: {
                  role: 'teachingCenter.question.list',
                  roleId: '11008',
                  hideInMenu: true,
                },
              },
            ],
          },

          {
            path: 'homeworkRecord',
            name: 'homeworkRecord',
            redirect: '/teachingCenter/homeworkRecord/list',
            component: () =>
              import('@/views/teaching-center/homework_record/entry.vue'),
            meta: {
              roleId: '308',
              role: 'teachingCenter.assign',
              menuName: '布置记录',
            },
            children: [
              {
                path: 'list',
                name: 'homeworkRecordList',
                component: () =>
                  import('@/views/teaching-center/homework_record/entry.vue'),
                meta: {
                  roleId: '309',
                  role: 'teachingCenter.assign.list',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'homeworkRecordDetail',
                component: () =>
                  import(
                    '@/views/teaching-center/homework_record/homework_detail/entry.vue'
                  ),
                meta: {
                  roleId: '310',
                  role: 'teachingCenter.assign.detail',
                  hideInMenu: true,
                },
              },
              {
                path: 'studentHomeworkDetail',
                name: 'studentHomeworkDetail',
                component: () =>
                  import(
                    '@/views/teaching-center/homework_record/student_homework_detail/entry.vue'
                  ),
                meta: {
                  roleId: '315',
                  role: 'teachingCenter.assign.homeworkDetail',
                  hideInMenu: true,
                },
              },
              {
                path: 'recordSubjective',
                name: 'recordSubjective',
                component: () =>
                  import(
                    '@/views/teaching-center/homework_record/homework_subjective/entry.vue'
                  ),
                meta: {
                  roleId: '311',
                  role: 'teachingCenter.assign.subjectiveCheck',
                  hideInMenu: true,
                },
              },
              {
                path: 'recordPreview',
                name: 'recordPreview',
                component: () =>
                  import(
                    '@/views/teaching-center/components/preview_homework_content/entry.vue'
                  ),
                meta: {
                  roleId: '312',
                  role: 'teachingCenter.assign.preview',
                  hideInMenu: true,
                },
              },
              {
                path: 'recordArrange',
                name: 'recordArrange',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange/entry.vue'
                  ),
                meta: {
                  roleId: '313',
                  role: 'teachingCenter.assign.homework',
                  hideInMenu: true,
                },
              },
              {
                path: 'recordArrangeOver',
                name: 'recordArrangeOver',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange_over/entry.vue'
                  ),
                meta: {
                  roleId: '314',
                  role: 'teachingCenter.assign.success',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'course',
            name: 'course',
            component: () => import('@/views/teaching-center/course/entry.vue'),
            redirect: '/teachingCenter/course/list',
            meta: {
              roleId: '301',
              role: 'teachingCenter.lesson',
              menuName: '精选课程',
            },
            children: [
              {
                path: 'list',
                name: 'courseList',
                component: () =>
                  import('@/views/teaching-center/course/index/entry.vue'),
                meta: {
                  roleId: '302',
                  role: 'teachingCenter.lesson.list',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'courseDetail',
                component: () =>
                  import('@/views/teaching-center/course/detail/entry.vue'),
                meta: {
                  roleId: '303',
                  role: 'teachingCenter.lesson.detail',
                  hideInMenu: true,
                },
              },
              {
                path: 'completeArrangeBatch',
                name: 'completeArrangeBatch',
                component: () =>
                  import(
                    '@/views/teaching-center/course/complete_arrange_batch/entry.vue'
                  ),
                meta: {
                  roleId: '307',
                  role: 'teachingCenter.lesson.batchAssign',
                  hideInMenu: true,
                },
              },
              {
                path: 'completeArrangeOver',
                name: 'completeArrangeOver',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange_over/entry.vue'
                  ),
                meta: {
                  roleId: '306',
                  role: 'teachingCenter.lesson.assignSuccess',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangePreview',
                name: 'arrangePreview',
                component: () =>
                  import(
                    '@/views/teaching-center/components/preview_homework_content/entry.vue'
                  ),
                meta: {
                  roleId: '304',
                  role: 'teachingCenter.lesson.assignPreview',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeBatch',
                name: 'arrangeBatch',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange/entry.vue'
                  ),
                meta: {
                  roleId: '305',
                  role: 'teachingCenter.lesson.assignHomework',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/preClassStudy',
        name: 'preClassStudy',
        redirect: '/preClassStudy/list',
        component: () =>
          import('@/views/teaching-center/preClassStudy/entry.vue'),
        meta: {
          role: 'teachingCenter.prepare',
          roleId: '11314',
          menuName: '我的预习课',
          icon: courseIcon,
          activeIcon: courseActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'preClassStudyList',
            component: () =>
              import('@/views/teaching-center/preClassStudy/list/entry.vue'),
            meta: {
              role: 'teachingCenter.prepare.list',
              roleId: '11315',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '/course-setting',
        name: 'courseSetting',
        redirect: '/course-setting/list',
        component: () =>
          import(
            '@/views/management-center/course-management/course-setting/index.vue'
          ),
        meta: {
          role: 'teachingCenter.yucai.course',
          roleId: '11053',
          menuName: '我的系列课',
          icon: courseIcon,
          activeIcon: courseActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'courseSettingList',
            component: () =>
              import(
                '@/views/management-center/course-management/course-setting/list/index.vue'
              ),
            meta: {
              role: 'teachingCenter.yucai.course.list',
              roleId: '11054',
              hideInMenu: true,
            },
          },
          {
            path: 'edit',
            name: 'courseSettingEdit',
            component: () =>
              import(
                '@/views/management-center/course-management/course-setting/edit/index.vue'
              ),
            meta: {
              hideInMenu: true,
              hasSideMenu: false,
            },
          },
          {
            path: 'detail',
            name: 'courseSettingDetail',
            component: () =>
              import(
                '@/views/management-center/course-management/course-setting/detail/index.vue'
              ),
            meta: {
              role: 'teachingCenter.yucai.course.list',
              roleId: '11054',
              hideInMenu: true,
            },
          },
          {
            path: 'lesson',
            name: 'courseSettingLessonInfo',
            component: () =>
              import(
                '@/views/management-center/course-management/course-setting/lesson/index.vue'
              ),
            meta: {
              role: 'teachingCenter.yucai.course.list',
              roleId: '11054',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '/vod-course',
        name: 'vodCourse',
        redirect: '/vod-course/index',
        component: () =>
          import('@/views/management-center/vod-course/index.vue'),
        meta: {
          role: 'teachingCenter.yucai.video',
          roleId: '11055',
          menuName: '学生端预览',
          icon: yucaiVideoIcon,
          activeIcon: yucaiVideoActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'vodCourseIndex',
            component: () =>
              import('@/views/management-center/vod-course/index/index.vue'),
            meta: {
              role: 'teachingCenter.yucai.video.overview',
              roleId: '11056',
              hideInMenu: true,
            },
          },
          {
            path: 'list',
            name: 'vodCourseList',
            component: () =>
              import('@/views/management-center/vod-course/list/index.vue'),
            meta: {
              role: 'teachingCenter.yucai.video.list',
              roleId: '11057',
              hideInMenu: true,
            },
          },
          {
            path: 'detail',
            name: 'vodCourseDetail',
            component: () =>
              import('@/views/management-center/vod-course/detail/index.vue'),
            meta: {
              role: 'teachingCenter.yucai.video.detail',
              roleId: '11058',
              hideInMenu: true,
              // 隐藏侧边栏
              // hasSideMenu: false
            },
          },
          {
            path: 'vod-room',
            name: 'vodCourseVideoRoom',
            component: () =>
              import('@/views/management-center/vod-course/vod-room/index.vue'),
            meta: {
              role: 'teachingCenter.yucai.video.play',
              roleId: '11059',
              hideInMenu: true,
              // 隐藏侧边栏
              hasSideMenu: false,
            },
          },
        ],
      },
      {
        path: '',
        name: 'teachingTools',
        redirect: '/teachingCenter/liveCourse/lessonsList',
        component: () =>
          import('@/views/teaching-center/teaching-tools/entry.vue'),
        meta: {
          role: 'teachingCenter.tool',
          roleId: '11001',
          menuName: '教学工具',
          hasSubmenu: true,
          icon: toolIcon,
          activeIcon: toolActiveIcon,
        },
        children: [
          {
            path: 'paperResource',
            name: 'paperResource',
            redirect: '/teachingCenter/paperResource/list',
            component: () =>
              import('@/views/teaching-center/paper-resource/entry.vue'),
            meta: {
              roleId: '319',
              role: 'teachingCenter.testPaper',
              menuName: '组卷系统',
            },
            children: [
              {
                path: 'list',
                name: 'paperResourceList',
                component: () =>
                  import(
                    '@/views/teaching-center/paper-resource/paper-index/entry.vue'
                  ),
                meta: {
                  roleId: '320',
                  role: 'teachingCenter.testPaper.list',
                  hideInMenu: true,
                },
              },
              {
                path: 'paperPreview',
                name: 'paperPreview',
                component: () =>
                  import(
                    '@/views/teaching-center/components/preview_homework_content/entry.vue'
                  ),
                meta: {
                  roleId: '321',
                  role: 'teachingCenter.testPaper.detail',
                  hideInMenu: true,
                },
              },
              {
                path: 'paperArrange',
                name: 'paperArrange',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange/entry.vue'
                  ),
                meta: {
                  roleId: '322',
                  role: 'teachingCenter.testPaper.assign.info',
                  hideInMenu: true,
                },
              },
              {
                path: 'paperArrangeSuccess',
                name: 'paperArrangeSuccess',
                component: () =>
                  import(
                    '@/views/teaching-center/components/complete_arrange_over/entry.vue'
                  ),
                meta: {
                  roleId: '323',
                  role: 'teachingCenter.testPaper.assign.success',
                  hideInMenu: true,
                },
              },
              {
                path: 'selectHomeworkContent',
                name: 'selectHomeworkContent',
                component: () =>
                  import(
                    '@/views/teaching-center/paper-resource/select_homework_content/entry.vue'
                  ),
                meta: {
                  roleId: '324',
                  role: 'teachingCenter.testPaper.combine',
                  hideInMenu: true,
                },
              },
            ],
          },
          // {
          //   path: 'liveCourse',
          //   name: 'liveCourse',
          //   redirect: '/teachingCenter/liveCourse/lessonsList',
          //   component: () =>
          //     import('@/views/teaching-center/live-course/entry.vue'),
          //   meta: {
          //     role: 'teachingCenter.live',
          //     roleId: '316',
          //     menuName: '直播系统',
          //   },
          //   children: [
          //     {
          //       path: 'lessonsList',
          //       name: 'lessonsList',
          //       component: () =>
          //         import(
          //           '@/views/teaching-center/live-course/lessons-list/entry.vue'
          //         ),
          //       meta: {
          //         role: 'teachingCenter.live.lesson',
          //         roleId: '317',
          //         hideInMenu: true,
          //       },
          //     },
          //     {
          //       path: 'studentsList',
          //       name: 'studentsList',
          //       component: () =>
          //         import(
          //           '@/views/teaching-center/live-course/students-list/entry.vue'
          //         ),
          //       meta: {
          //         role: 'teachingCenter.live.student',
          //         roleId: '318',
          //         hideInMenu: true,
          //       },
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
];
