export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_PREMISSIONLIST = 'SET_USER_PREMISSIONLIST';
export const SET_USER_HASNEWLIST = 'SET_USER_HASNEWLIST';

const state = {
  name: '',
  school: '',
  schoolId: null,
  provinceId: null,
  provinceName: '',
  cityName: '',
  cityId: null,
  sex: '',
  id: 0,
  schoolBadgeUrl: '',
  dataViewUrl: '',
  countrIdEd: '',
  countyName: '',
  memType: '',
  resultRouters: [],
  permissionList: null,
  hasNewList: [],
  mobileNo: '',
};

const mutations = {
  [SET_USER_INFO](store, payload) {
    Object.assign(store, payload);
  },
  [SET_USER_PREMISSIONLIST](state, payload) {
    state.permissionList = payload;
  },
  [SET_USER_HASNEWLIST](state, payload) {
    state.hasNewList = payload;
  },
};

const getters = {
  getResultRouters(state) {
    return state.resultRouters;
  },
  getPermissions(state) {
    return state.permissionList;
  },
};

export default {
  name: 'user',
  namespaced: true,
  state,
  getters,
  mutations,
};
