/*
 * @Author: your name
 * @Date: 2021-10-29 16:48:15
 * @LastEditTime: 2022-01-05 13:39:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xhx-web\src\common\components\IM\store\index.ts
 */

import parseCustomSystemMessage from '../scripts/parseCustomSystemMessage.js';

const state = {
  /** 实例初始化参数 */
  instanceConfig: {},
  /** im实例，挂载 tim实例对象 */
  instance: null,
  conversationList: [],
  currentMessageList: [],
  currentConversation: {},
  nextReqMessageID: '',
  isCompleted: false,
  isLogin: false,
  isSDKReady: false,
};

export default {
  name: 'IM',
  namespaced: true,
  state,
  modules: {},
  mutations: {
    setImInstance(state, instance) {
      state.instance = instance;
    },
    setInstanceConfig(state, config) {
      state.instanceConfig = config;
    },
    setIsLogin(state, flag) {
      state.isLogin = flag;
    },
    setIsCompleted(state, flag) {
      state.isCompleted = flag;
    },
    setIsNextReqMessageID(state, flag) {
      state.nextReqMessageID = flag;
    },
    setIsSDKReady(state, flag) {
      state.isSDKReady = flag;
    },
    setConversationList(state, list = []) {
      state.conversationList = list;
    },
    setCurrentMessageList(state, list = []) {
      state.currentMessageList = list;
    },
    /**
     * 更新当前会话
     * 调用时机: 切换会话时
     * @param {Object} state
     * @param {Conversation} conversation
     */
    updateCurrentConversation(state, conversation = {}) {
      state.currentConversation = conversation;
      state.currentMessageList = [];
      state.nextReqMessageID = '';
      state.isCompleted = false;
    },
    resetConversation(state) {
      Object.assign(state, {
        currentConversation: {},
        currentMessageList: [],
        nextReqMessageID: '',
        isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
      });
    },
    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
    pushCurrentMessageList(state, data) {
      console.log('[pushCurrentMessageList]', data);
      const isArray = Array.isArray(data);
      // 解析系统自定义消息 IM双通道
      parseCustomSystemMessage(isArray ? data : [data]);
      // 还没当前会话，则跳过
      if (!state.currentConversation.conversationID) {
        return;
      }
      if (isArray) {
        // 筛选出当前会话的消息
        const result = data.filter(
          item =>
            item.conversationID === state.currentConversation.conversationID
        );
        state.currentMessageList = [...state.currentMessageList, ...result];
      } else if (
        data.conversationID === state.currentConversation.conversationID
      ) {
        state.currentMessageList = [...state.currentMessageList, data];
      }

      // 群会话，限制最大消息数量 - 200，冗余数量 20
      if (
        state.currentConversation.type === TIM.TYPES.CONV_GROUP &&
        state.currentMessageList.length > 220
      ) {
        state.currentMessageList.splice(0, 20);
      }
    },
  },
  actions: {
    /**
     * 获取消息列表
     * 调用时机：打开某一会话时或下拉获取历史消息时
     * @param {Object} context
     * @param {String} conversationID
     */
    getMessageList(context, payload) {
      if (!context.state.isSDKReady) return;
      const tim = payload.tim;
      const conversationID = payload.conversationID;
      if (context.state.isCompleted) {
        return;
      }
      const { nextReqMessageID, currentMessageList } = context.state;
      tim
        .getMessageList({ conversationID, nextReqMessageID, count: 20 })
        .then((imReponse) => {
          console.log('[getMessageList]', imReponse.data);
          // 更新messageID，续拉时要用到
          context.commit('setIsNextReqMessageID', imReponse.data.nextReqMessageID);
          context.commit('setIsCompleted', imReponse.data.isCompleted);
          context.commit('setCurrentMessageList', [
            ...imReponse.data.messageList,
            ...currentMessageList,
          ])
        });
    },
    /**
     * 切换会话
     * 调用时机：切换会话时
     * @param {Object} context
     * @param {String} conversationID
     */
    checkoutConversation(context, payload) {
      console.log('[checkoutConversation]', payload);
      if (!context.state.isSDKReady) return;
      const tim = payload.tim;
      const conversationID = payload.conversationID;
      // 1.切换会话前，将切换前的会话进行已读上报
      if (context.state.currentConversation.conversationID) {
        const prevConversationID =
          context.state.currentConversation.conversationID;
        tim.setMessageRead({ conversationID: prevConversationID });
      }
      // 2.待切换的会话也进行已读上报
      tim.setMessageRead({ conversationID });
      // 3. 获取会话信息
      return tim
        .getConversationProfile(conversationID)
        .then((imResponse) => {
          const data = imResponse.data;
          // 3.1 更新当前会话
          context.commit(
            'updateCurrentConversation',
            Object.assign(payload.userInfo || {}, data.conversation)
          );
          // 3.2 获取消息列表
          if (data.conversation.type === TIM.TYPES.CONV_C2C) {
            context.dispatch('getMessageList', payload);
          }

          // 3.3 拉取第一页群成员列表
          // if (data.conversation.type === TIM.TYPES.CONV_GROUP) {

          // }
          return Promise.resolve();
        });
    },
  },
};
