import Vue from 'vue';
import Router from 'vue-router';

import loginLayout from '@/loginLayout/index.vue';

import productDesc from './modules/productDesc';
import { beforeEachRoute } from './permission';
import { authRoutes, authRoutesNoLoginLayout, noAuthRoutes } from './router';
Vue.use(Router);

const allRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
  },
  {
    path: '',
    name: 'loginLayout',
    component: loginLayout,
    children: [
      ...authRoutes,
      {
        path: '/no_permission',
        name: 'noAuth',
        component: () => import('@/no-permission.vue'),
      },
    ],
  },
  ...authRoutesNoLoginLayout,
  ...noAuthRoutes,
  ...productDesc,
];

const router = new Router({
  routes: allRoutes,
  base: '/',
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.path != from.path) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach(beforeEachRoute);

router.afterEach((to, from) => {
  const App = document.getElementById('app');
  App && App.scrollTo && App.scrollTo(0, 0);
});

export default router;
