import Vue from 'vue';
import { Notification } from 'element-ui';
import './style.less';
import component from './index.vue';
import Notify from './Notify.vue';

/**
 * param.type 类型 error: 错误提示   success 成功提示    默认   toast
 * title   error/success 有用   非必填
 * content toast/error 有用    必填
 * sure    error 可使用   非必填
 * @param {any} [param={}]
 */
function render(param = {}) {
  const {
    dom,
    type = 'warn',
    sure,
    icon,
    title,
    cancel,
    content,
    hidnIcon,
    sureText,
    showTitle,
    iconColor,
    cancelText,
    footerBtnText,
    width = 240,
    showClose = false,
  } = param;
  const _component = new Vue({
    render: (h) =>
      h(component, {
        props: {
          dom,
          sure,
          type,
          icon,
          title,
          cancel,
          content,
          hidnIcon,
          sureText,
          showTitle,
          iconColor,
          cancelText,
          footerBtnText,
          width,
          showClose,
        },
      }),
  }).$mount();
  document.body.appendChild(_component.$el);
  return _component.$el;
}
render.error = (param = {}) => {
  let d = render({
    ...param,
    type: 'error',
    icon: 'el-icon-error',
  });
  return d;
};

render.confirm = (param = {}) => {
  let parameter = param;
  if (typeof param === 'string') {
    parameter = {
      content: param,
    };
  }
  let d = render({
    ...parameter,
    type: 'confirm',
  });
  return d;
};

render.warn = (param = {}) => {
  let parameter = param;
  if (typeof param === 'string') {
    parameter = {
      content: param,
    };
  }
  let d = render({
    icon: 'el-icon-warning',
    ...parameter,
    type: 'warn',
  });
  return d;
};

const newMessage = ({ type, message, ...args }) => {
  const obj = Notification({ message, type, customClass: 'kkl-notify', ...args });
  // const vm = new Vue({
  //   template:
  //     '<Notify @close="close" :type="type" :message="message" ref="notify" />',
  //   components: {
  //     Notify,
  //   },
  //   data() {
  //     return {
  //       type,
  //       message,
  //     };
  //   },
  //   methods: {
  //     close() {
  //       obj.close();
  //     },
  //   },
  // });
  // let objDom = obj.$el.querySelector('.el-notification__content');
  // obj.$el.classList.add('kkl');
  // objDom.innerHTML = '';
  // vm.$mount(objDom);
  return obj;
};

render.toast = (param = {}) => {
  let parameter = param;
  if (typeof param === 'string') {
    parameter = {
      message: param,
    };
  }
  let d = newMessage({
    ...parameter,
    type: 'info',
  });
  return d;
};
render.success = (param = {}) => {
  let parameter = param;
  if (typeof param === 'string') {
    parameter = {
      message: param,
    };
  }
  let d = newMessage({
    ...parameter,
    type: 'success',
  });
  return d;
};

export default render;
