<template>
  <el-menu
    class="left-menu"
    text-color="#333"
    :default-active="active"
    :active-parent-index="activeParentIndex"
    :default-openeds="openeds"
    @select="select"
    @open="open"
    @close="close"
  >
    <template v-for="item in data">
      <template v-if="item.children">
        <LeftNavSubMenu
          :key="item.name"
          :data="item"
          :active="active"
          :active-parent-index="activeParentIndex"
          :has-new-list="hasNewList"
        />
      </template>
      <template v-else>
        <el-menu-item
          v-if="!item.meta.hideInMenu"
          :key="item.name"
          class="el-menu-item-hover"
          :index="`${item.name}-${item.meta.roleId}`"
        >
          <div>
            <img
              v-if="item.meta.icon"
              class="icon"
              :src="active === `${item.name}-${item.meta.roleId}` ? item.meta.activeIcon : item.meta.icon"
            />
            <span class="menu__name">
              {{ item.meta.menuName }}
            </span>
          </div>
          <i v-if="hasNewList.includes(item.meta.roleId)" class="icon-new"></i>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>

<script>
import LeftNavSubMenu from './leftNavSubMenu.vue';

export default {
  name: 'NavList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: '',
    },
    openeds: {
      type: Array,
      default: () => [],
    },
    activeParentIndex: {
      type: String,
      default: '',
    },
    hasNewList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    LeftNavSubMenu,
  },

  methods: {
    select(index, indexPath) {
      // console.group('NavList select');
      // console.log(index, indexPath);
      // console.groupEnd();
      this.$emit('select', index, indexPath);
    },

    open(index, indexPath) {
      this.$emit('open', index, indexPath);
    },

    close(index, indexPath) {
      this.$emit('close', index, indexPath);
    },
  },
};
</script>

<style lang="less" src="./menu.less" scoped></style>
