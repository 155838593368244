import {
  queryQuestionListByTemplateId,
  queryTemplateById,
} from '@/fetchs/aischool/HomeworkTemplateFacade';
import { queryPageListen } from '@/fetchs/aischool/ListeningTypeFacade';
import { queryLesson } from '@/fetchs/aischool/CourseLessonFacade';

import { INIT_HOMEWORK, CHOOSE_HOMEWORK_SCHEME } from '../mutationTypes';

async function setHomeworkScheme({ commit }, { id, releaseCount = 0 }) {
  let homeworkScheme = await queryTemplateById(id);
  // debugger
  homeworkScheme.shareTypes = homeworkScheme.shareTypeDTOs;
  let lessonIds = homeworkScheme.lessonIds || [];
  if (lessonIds.length) {
    const lessonMap = {};
    const lessonCommodityMap = {};
    const lessonKnowledgeMap = {};
    const selectedCommodityLessonLengthMap = {};
    const lessonCommodityList = homeworkScheme.lessonAndCommodityDTOs || [];
    const lessons = await queryLesson({
      lessonIds,
    });
    // debugger
    lessons.forEach((lesson, index) => {
      const { knowledgeDTOs } = lesson;
      const { commodityId } = lessonCommodityList[index];
      if (!selectedCommodityLessonLengthMap[commodityId]) {
        selectedCommodityLessonLengthMap[commodityId] = 0;
      }
      selectedCommodityLessonLengthMap[commodityId] += 1;
      lessonMap[lesson.id] = lesson;
      lessonCommodityMap[lesson.id] = commodityId;
      if (Array.isArray(knowledgeDTOs)) {
        lessonKnowledgeMap[lesson.id] = knowledgeDTOs;
      }
    });
    homeworkScheme = {
      ...homeworkScheme,
      lessonMap,
      lessonKnowledgeMap,
      lessonCommodityMap,
      selectedCommodityLessonLengthMap,
    };
  }
  let questionIds = homeworkScheme.questionIds || [];
  let abnormalityQuestionNum = 0;
  if (questionIds.length) {
    const selectedKnowledgeQuestionLengthMap = {};
    const questionMap = {};
    // const questions = await getQuestionsBySubject({
    const homeworkInfo = await queryQuestionListByTemplateId(id);
    // debugger
    const questions = homeworkInfo.usableQuestionList ?? [];
    // 存在的questionId
    let existQuestionIds = [];
    abnormalityQuestionNum = homeworkInfo.removeQuestionNum;
    questions.forEach(question => {
      question.knowledgeIds = question.newKnowledges || [];
      // debugger
      const { knowledgeIds = [] } = question;
      knowledgeIds.forEach(knowledgeId => {
        if (!selectedKnowledgeQuestionLengthMap[knowledgeId]) {
          selectedKnowledgeQuestionLengthMap[knowledgeId] = 0;
        }
        selectedKnowledgeQuestionLengthMap[knowledgeId] += 1;
      });
      question.knowledgeStrs = question.knowledges;
      question.questionType = question.kklQuestionType || question.questionType;
      questionMap[question.id] = question;
      existQuestionIds.push(question.id);
    });
    // debugger
    homeworkScheme = {
      ...homeworkScheme,
      questionMap,
      selectedKnowledgeQuestionLengthMap,
    };
    questionIds = [...existQuestionIds];
    homeworkScheme.questionIds = questionIds;
  }
  homeworkScheme.abnormalityQuestionNum = abnormalityQuestionNum;
  let { testPaperId, listenTestSoldOut } = homeworkScheme;
  if (testPaperId && !listenTestSoldOut) {
    const res = await queryPageListen({
      testPaperId,
      pageSize: 1000,
      pageCurrent: 1,
    });
    // debugger
    let testPaperresultList = res.resultList;
    // window.testPaperresultList = testPaperresultList;
    // setTimeout(() => {
    //     delete window.testPaperresultList;
    // }, 2000);
    let forecastTimeSec = 0;
    let subQuestionCount = testPaperresultList.reduce((prev, next) => {
      let sum = prev;
      next.listenUnitQuestionDTOS &&
        next.listenUnitQuestionDTOS.forEach(v => {
          sum += v.subQuestionCount || 0;
          let arr = (v.accessToken && v.accessToken.split('-')) || [];
          let second = (arr.length && Number(arr[arr.length - 1])) || 0;
          forecastTimeSec += second;
        });
      return sum;
    }, 0);
    forecastTimeSec = Math.floor(forecastTimeSec / 1000);

    homeworkScheme.listenIds = [testPaperId];
    homeworkScheme.listenMap = {
      [testPaperId]: {
        subQuestionCount,
        forecastTimeSec: homeworkScheme.totalListenDuration || forecastTimeSec,
      },
    };
  }
  if (listenTestSoldOut) {
    KKL.message({
      content: '该听力卷已下架',
    });
  }
  homeworkScheme.releaseCount = releaseCount;

  commit(INIT_HOMEWORK, homeworkScheme.version);
  commit(CHOOSE_HOMEWORK_SCHEME, homeworkScheme);
}

export default {
  setHomeworkScheme,
};
