let promiseResolve = () => {};
let promiseReject = () => {};
// 获取权限的promise
export const permissionPromise = new Promise((resolve, reject) => {
  promiseResolve = resolve;
  promiseReject = reject;
});
// 获取权限的promise的成功回调
export const permissionPromiseResolve = promiseResolve;
// 获取权限的promise的失败回调
export const permissionPromiseReject = promiseReject;
