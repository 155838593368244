const getServiceName = str => `aischool.UserClickRecordFacade.${str}`;

/**
 * 查询用户new标签
 * @returns
 */
export const queryHasNewList = () => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('queryHasNewList'),
      param: {},
    },
  });
};

/**
 * 记录用户点击菜单
 * @param {String} authId
 * @returns
 */
export const menuClick = authId => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('menuClick'),
      param: { authId },
    },
  });
};
