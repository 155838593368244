export const INIT_HOMEWORK = 'INIT_HOMEWORK'; // 初始化数据
export const SET_HOMEWORK = 'SET_HOMEWORK'; // 设置数据
export const RESET_SELECT_HOMEWORK_CACHE_FLAG =
  'RESET_SELECT_HOMEWORK_CACHE_FLAG'; // 重置科目缓存标识
export const SET_SELECT_HOMEWORK_CACHE_FLAG = 'SET_SELECT_HOMEWORK_CACHE_FLAG'; // 设置置科目缓存标识
export const SET_HOMEWORK_STATUS = 'SET_HOMEWORK_STATUS'; // 设置作业状态
export const SET_HOMEWORK_TITLE = 'SET_HOMEWORK_TITLE'; // 设置页面title
export const SET_HOMEWORK_SOURCE_TYPE = 'SET_HOMEWORK_SOURCE_TYPE'; // 设置作业来源
export const EMPTY_HOMEWORK = 'EMPTY_HOMEWORK'; // 清空作业
export const SET_IS_TAB_TIP_SHOW = 'SET_IS_TAB_TIP_SHOW'; // 设置智能组卷预览页tab提示是否出现
export const INIT_TAB_TIP_SHOW = 'INIT_TAB_TIP_SHOW'; // 设置智能组卷预览页tab提示初始值
export const SET_QUESTION_LIST = 'SET_QUESTION_LIST';

// 设置课目信息
export const SET_SUBJECT = 'SET_SUBJECT';
// 设置cart信息
export const SET_CART_INFO = 'SET_CART_INFO';
