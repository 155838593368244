const state = {
  guideDialogVisible: false,
  stepDialogVisible: false,
};

export const SET_GUIDE_DIALOG_VISIBLE = 'SET_GUIDE_DIALOG_VISIBLE';
export const SET_STEP_DIALOG_VISIBLE = 'SET_STEP_DIALOG_VISIBLE';

const mutations = {
  [SET_GUIDE_DIALOG_VISIBLE](store, payload) {
    store.guideDialogVisible = payload;
  },
  [SET_STEP_DIALOG_VISIBLE](store, payload) {
    store.stepDialogVisible = payload;
  },
};

export default {
  name: 'guide',
  namespaced: true,
  state,
  mutations,
};
