<template>
  <div
    v-show="visibility"
    :class="['layer-prompt', type, position]"
    :style="`width: ${width - 20}px; height: ${height - 20}px`"
    @click.stop
  >
    <div v-if="type === 'feature'">
      <div class="layer-arrow feature-arrow"></div>
      <p class="layer-text feature-text">{{ message }}</p>
      <div class="feature-button" @click.stop="removeLayerPrompt()">
        {{ button }}
      </div>
    </div>
    <div v-if="type === 'operator'">
      <div class="layer-arrow operator-arrow"></div>
      <p class="layer-text operator-text">{{ message }}</p>
      <i class="el-icon-circle-close" @click.stop="removeLayerPrompt()"></i>
    </div>
  </div>
</template>

<script>
import { syncCacheStatus } from './utils';

export default {
  name: 'layer-prompt',
  props: {
    type: String, // 组件类型 feature:功能提示, operator:操作提示
    message: [String, Number], // 主体内容提示
    visibility: Boolean, // 组件是否显示
    button: String, // 关闭按钮文案
    position: String, // 基于父容器的定位点
    width: Number, // 组件配置宽度
    height: Number, // 组件配置高度
    cacheKey: Object, // web端配置cacheKey缓存键值对
    storageSettingsDev: Boolean, // 是否启用缓存、方便调试
    storageSettingsLocal: Boolean, // 是否仅启用本地缓存
  },
  data() {
    return {};
  },
  methods: {
    // 隐藏组件实例
    async removeLayerPrompt() {
      const { cacheKey, storageSettingsDev, storageSettingsLocal } = this;
      // 用户点击缓存cacheKey值
      await syncCacheStatus(cacheKey, storageSettingsDev, storageSettingsLocal);
      this.visibility = false;
    },
  },
};
</script>

<style lang="less" scoped src="./index.less"></style>
