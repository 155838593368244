import { SET_FINISH_HOMEWORK_INFO } from '../mutationTypes';

export default {
  [SET_FINISH_HOMEWORK_INFO](
    store,
    {
      batch = false,
      failIdList = [],
      successList = [],
      precisionLessonSum = 0, // 精讲视频个数（重难点突破方案中的视频数量）
      isTimed,
      publishTime,
      id,
      type,
      title,
      description,
      classList,
      groupList,
      endTime,
      questionSum,
      lessonSum,
      reviewEndTime,
      subjectiveCount,
      totalListenQuestion,
      totalSchoolPaper,
      totalSchoolPaperQuestion,
    }
  ) {
    store.finish = {
      ...store.finish,
      id,
      type,
      title,
      endTime,
      isTimed,
      publishTime,
      batch,
      groupList,
      classList,
      lessonSum,
      failIdList,
      successList,
      questionSum,
      description,
      reviewEndTime,
      subjectiveCount,
      totalSchoolPaper,
      totalListenQuestion,
      totalSchoolPaperQuestion,
      precisionLessonSum,
    };
  },
};
