import recordIcon from '@/assets/nav-icon/布置记录1@2x.png';
import recordActiveIcon from '@/assets/nav-icon/布置记录2@2x.png';

export default [
  {
    path: '/arrangeCenter',
    name: 'arrangeCenter',
    redirect: '/arrangeCenter/record',
    component: () => import('@/views/arrange-center/entry.vue'),
    meta: {
      role: '',
      roleId: '',
      menuName: '布置中心',
      hideInMenu: true,
    },
    children: [
      {
        path: '',
        name: 'arrangeCenterRecord',
        redirect: '/arrangeCenter/subjectHomeworkRecord/list',
        component: () => import('@/views/arrange-center/record/entry.vue'),
        meta: {
          role: '',
          roleId: '',
          menuName: '布置中心',
          hasSubmenu: true,
          icon: recordIcon,
          activeIcon: recordActiveIcon,
        },
        children: [
          {
            path: 'subjectHomeworkRecord',
            name: 'subjectHomeworkRecord',

            redirect: '/arrangeCenter/subjectHomeworkRecord/list',
            component: () =>
              import(
                '@/views/arrange-center/record/subject-homework-record/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              menuName: '学科作业',
            },
            children: [
              {
                path: 'list',
                name: 'subjectHomeworkRecordList',
                component: () =>
                  import('@/views/teaching-center/homework_record/entry.vue'),
                meta: {
                  role: '',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'psyHomeworkRecord',
            name: 'psyHomeworkRecord',
            redirect: '/arrangeCenter/psyHomeworkRecord/list',
            component: () =>
              import(
                '@/views/arrange-center/record/psy-homework-record/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              menuName: '心理作业',
            },
            children: [
              {
                path: 'list',
                name: 'psytHomeworkRecordList',
                component: () =>
                  import(
                    '@/views/arrange-center/record/psy-homework-record/list/entry.vue'
                  ),
                meta: {
                  role: '',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'homeSchoolHomeworkRecord',
            name: 'homeSchoolHomeworkRecord',
            redirect: '/arrangeCenter/homeSchoolHomeworkRecord/list',
            component: () =>
              import(
                '@/views/arrange-center/record/home_school-homework-record/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              menuName: '家校共育',
            },
            children: [
              {
                path: 'list',
                name: 'homeSchoolHomeworkRecordList',
                component: () =>
                  import(
                    '@/views/homeAndSchool/parent-college/record/entry.vue'
                  ),
                meta: {
                  role: '',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
