import { LogProject, LogStrore, LogTopic, LogEnv, default as KKLLOG } from '@kkl/kkl-log';
import { getMemberId } from './login';

let bigDataInstange = null;

// 获取 kklbigData;
const KKLBigData = window.KKLbigdata;

// 通过单例模式初始化
function initLogAndBigData() {
  if (bigDataInstange) {
    return bigDataInstange;
  }
  bigDataInstange = new KKLLOG(
    LogProject.log,
    LogStrore.bigData,
    LogTopic.page,
    LogEnv[process.env.npm_config_env] || 'dev',
  );
  setBaseInfo();
  KKLBigData.init({
    isSpa: true,
    si: 2, // 0 pc, 1 h5, 2 都有
    sendFunc: function(params) {
      try {
        bigDataInstange.info(params);
      } catch (err) {
        console.log(err);
      }
    },
  });
  return bigDataInstange;
}
// 添加路由钩子
function routerAfterEach(to, from) {
  if (KKLBigData) {
    KKLBigData.vueSpa.beforeRouteLeave()(to, from);
    KKLBigData.vueSpa.beforeRouteEnter()(to, from);
    setBaseInfo();
  }
}

// 扩展Vue原型
function expandVueProperty(Vue) {
  Vue.prototype.$sendBuryInfo = async (param = {}) => {
    if (KKLBigData) {
      KKLBigData.send(param);
    }
  };
}
// 设置基础信息
export function setBaseInfo() {
  bigDataInstange?.addBaseInfo({
    app: 'xhxwlx',
    member_id: getMemberId(),
  });
}

// 将bigdata和 当前vue跟实例和路由组件进行合并
export function install(Vue, router) {
  initLogAndBigData();
  expandVueProperty(Vue);
  router.afterEach(routerAfterEach);
}
