/**
 * 新手引导步骤操作组件
 * 按照传入参数数组、依次展示操作步骤
 * 方法基于js数组队列先入先出规则
 *
 * @function LayerPromptObserver
 */

import Vue from 'vue';

import LayerPrompt from './';

/**
 * 对原生数组进行变异、改写push及shift方法
 * 监听数组元素出队列、进行后续操作
 *
 * @returns {Function} Mutation
 */

/*eslint-disable*/
function Mutation(...args) {
  const self = [...args];
  Object.setPrototypeOf(self, Mutation.prototype);
  return self;
}

Mutation.prototype = Object.create(Array.prototype);

// 监听元素入队列
Mutation.prototype.push = function () {
  console.log('queue has been observered, one instance added');
  Array.prototype.push.call(this, ...arguments);
};

// 监听元素出队列、则实例化后续组件
Mutation.prototype.shift = function () {
  console.log('queue has been observered, one instance deleted');
  Array.prototype.shift.call(this, ...arguments);
};

// 基于变异Mutation对象的操作队列
let queue = new Mutation();

/**
 * 新手引导步骤操作组件对外暴露函数
 * 监听实例的visibility、变化则推进队列
 *
 * @param {Array} [options=[]] 依次需渲染的组件
 * @returns {Promise}
 */

async function LayerPromptObserver(options = []) {
  if (!options.length) {
    console.warn('options must be Array');
    return false;
  }
  options.map((item) => queue.push(item));
  for (let i = 0; i < options.length; i++) {
    let prompt = await LayerPrompt(options[i]);
    if (prompt.isCached) {
      // 过滤已缓存实例
      queue.shift();
    } else {
      LayerPromptObserver.watcher(prompt);
      break;
    }
  }
}

// 监听实例属性变化
LayerPromptObserver.watcher = (instance) => {
  instance.$watch('visibility', function (props, prevProps) {
    if (props !== prevProps && !props) {
      queue.shift();
      // 队列中还有实例则展示第一个
      if (queue.length) {
        LayerPromptObserver.render();
      }
    }
  });
};

// 渲染组件实例
LayerPromptObserver.render = async () => {
  const prompt = await LayerPrompt(queue[0]);
  LayerPromptObserver.watcher(prompt);
};

Vue.prototype.$LayerPromptObserver = LayerPromptObserver;

export default LayerPromptObserver;
