import schoolManaIcon from '@/assets/nav-icon/概况1@2x.png';
import schoolManaActiveIcon from '@/assets/nav-icon/概况2@2x.png';

export default [
  {
    path: '/homePage',
    name: 'homePage',
    redirect: '/homePage/index',
    component: () => import('@/views/homePage/entry.vue'),
    meta: {
      role: 'administrative',
      roleId: '100',
      menuName: '首页',
      directLink: true,
    },
    children: [
      {
        path: 'index',
        name: 'homePageIndex',
        component: () => import('@/views/homePage/entry.vue'),
        meta: {
          role: 'administrative',
          roleId: '100',
          menuName: '首页',
          needQueryHelpCenter: false,
        },
      },
    ],
  },
  {
    path: '/yucaihomePage',
    name: 'yucaihomePage',
    redirect: '/yucaihomePage/index',
    component: () => import('@/views/yucai/homePage/entry.vue'),
    meta: {
      role: 'yucai.administrative',
      roleId: '11092',
      menuName: '首页',
      directLink: true,
    },
    children: [
      {
        path: 'index',
        name: 'yucaihomePageIndex',
        component: () => import('@/views/yucai/homePage/entry.vue'),
        meta: {
          role: 'yucai.administrative.index.view',
          roleId: '11094',
          menuName: '首页',
          needQueryHelpCenter: false,
        },
      },
    ],
  },
  {
    path: '/schoolManage',
    name: 'schoolManage',
    redirect: '/schoolManage/schoolOverview',
    component: () => import('@/views/administration/entry.vue'),
    meta: {
      roleId: '200',
      role: 'schoolmng.education.bureau',
      menuName: '学校管理',
    },
    children: [
      {
        path: 'schoolOverview',
        name: 'schoolOverview',
        redirect: '/schoolManage/schoolOverview/schoolOverviewIndex',
        component: () => import('@/views/administration/entry.vue'),
        meta: {
          roleId: '201',
          role: 'schoolmng.education.bureau.index',
          menuName: '概况',
          icon: schoolManaIcon,
          activeIcon: schoolManaActiveIcon,
        },
        children: [
          {
            path: 'schoolOverviewIndex',
            name: 'schoolOverviewIndex',
            component: () =>
              import('@/views/administration/overview/entry.vue'),
            meta: {
              roleId: '202',
              role: 'schoolmng.education.bureau.index.view',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];
