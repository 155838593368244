/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: zgz
 * @Last Modified time: 2018-05-11 11:57:37
 */

/**
 * 根据试题id list查询 试题list
 * @export
 * @returns promise
 */
export function queryPageListen({ pageSize, pageCurrent, testPaperId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.ListeningTypeFacade.queryPageListen',
      param: {
        reqDTO: {
          pageSize,
          pageIndex: pageCurrent,
          testPaperId,
        },
      },
    },
  });
}
