/*
 * @Author: your name
 * @Date: 2021-11-10 10:08:40
 * @LastEditTime: 2022-04-19 16:20:16
 * @LastEditors: Please set LastEditors
 * @Description: 解析自定义的系统消息，会话类型包括 C2C / GROUP
 * @FilePath: \xhx-web\src\common\components\IM\scripts\parseCustomSystemMessage.js
 */
/** 系统消息描叙字段 extension */
const SystemMessageExt = 'system';

/** 自定义群系统通知 operationType 类型 */
const CustomGroupSystemNotice_operationType = 255;

import { ImMuteFunctionType, muteBizTypeList, startLessonBizType, StartLessonFunctionType } from '../enums';

const {
  GROUP_CANCEL_MUTE,
  GROUP_CANCEL_MUTE_CAUSEBY_INTERACTION_END,
  USER_CANCEL_MUTE,
  GROUP_MUTE,
  USER_MUTE,
  GROUP_MUTE_CAUSEBY_INTERACTION_START,
} = ImMuteFunctionType;

const muteAndStatusMap = {
  [GROUP_CANCEL_MUTE]: {
    groupStatus: 1,
  },
  [GROUP_CANCEL_MUTE_CAUSEBY_INTERACTION_END]: {
    groupStatus: 1,
  },
  [USER_CANCEL_MUTE]: {
    userStatus: 1,
  },
  [GROUP_MUTE]: {
    groupStatus: 2,
  },
  [USER_MUTE]: {
    userStatus: 2,
  },
  [GROUP_MUTE_CAUSEBY_INTERACTION_START]: {
    groupStatus: 2,
  },
};

/**
 * 解析自定义的系统消息，会话类型包括 C2C / GROUP
 * @param {Array} messageList
 */
function parseCustomSystemMessage(messageList = []) {
  for (let i = 0, length = messageList.length; i < length; i++) {
    const { type, payload } = messageList[i];

    if (isCustomGroupSystemNotice(type, payload) || isCustomSystemMessage(type, payload)) {
      console.log('IM parseCustomSystemMessage [dispatchSystemMessage]', payload.data || payload.userDefinedField);
      let dataString = payload.data || payload.userDefinedField;
      dispatchSystemMessage(JSON.parse(dataString));
    }
  }
}

/**
 * 自定义C2C系统消息
 * @returns boolean
 */
function isCustomSystemMessage(type, payload) {
  if (type === 'TIMCustomElem' && payload.extension === SystemMessageExt && payload.data) {
    return true;
  }
  return false;
}

/**
 * 自定义群（GROUP）系统通知
 * @returns boolean
 */
function isCustomGroupSystemNotice(type, payload) {
  if (
    type === 'TIMGroupSystemNoticeElem' &&
    payload.operationType === CustomGroupSystemNotice_operationType &&
    payload.userDefinedField
  ) {
    return true;
  }
  return false;
}

/**
 * 将系统消息添加到消息中心
 */
function dispatchSystemMessage(message) {
  if (message && message.bizType) {
    if (typeof message.content === 'string') {
      message.content = JSON.parse(message.content);
    }
    // $_EventLib.dispatch('AddLiveSystemMsg', message);
    const { bizType, content } = message;
    // 禁言/取消禁言
    if (muteBizTypeList.includes(bizType)) {
      const imInfo = muteAndStatusMap[content.functionType];
      window.app.$eventBus.emit('muteMsg', imInfo);
    }

    // 上课
    if (bizType === startLessonBizType && content.functionType === StartLessonFunctionType) {
      $eventBus.emit('startLessonMsg', content);
    }
  }
}

export default parseCustomSystemMessage;
