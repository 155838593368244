import { setBaseInfo } from '@/common/scripts/utils/business/bigDataLog';
import { setMemberId, setToken } from '@/common/scripts/utils/business/login';
export async function setLoginInfo(context, data) {
  const { dispatch } = context;
  const { token, memberId, noGetData } = data;
  setToken(token);
  setMemberId(memberId);
  setBaseInfo();
  if (noGetData) return;
  // await dispatch('initAppAndUserInfo');
  await dispatch('initApp');
}
