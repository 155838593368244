const getServeName = (str) => `aischool.MemberInfoFacade.${str}`;

/**
 * 重置学生密码
 * @param {String} studentId
 */

export const modifyStudentPassword = (studentId) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServeName('modifyStudentPassword'),
      param: {
        bparam: {
          studentId,
        },
      },
    },
  });
};

/**
 * 学生密码重置次数校验
 * @param {String} studentId
 */
export const chkModifyStudentPassword = (studentId) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServeName('chkModifyStudentPassword'),
      param: {
        bparam: {
          studentId,
        },
      },
    },
  });
};

/**
 * 修改密码
 * @param {Object} bparam
 * @returns
 */
export const modifyPassword = (bparam) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServeName('modifyPassword'),
      param: bparam,
    },
  });
};

/**通过学生id查询信息 */
export const queryStudentInfo = (studyId) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.MemberInfoFacade.queryStudentInfo',
      param: {
        studyId,
      },
    },
  });
};
