import { EMPTY_HOMEWORK } from '../mutationTypes';

/**
 * @description 重置作业数据 购物车清空
 * @param {*} { commit }
 */
function resetHomeworkCart({ commit }) {
  commit(EMPTY_HOMEWORK);
}

export default {
  resetHomeworkCart,
};
