import { setMemberId } from '@/common/scripts/utils/business/login';
import { cacheTools } from '@/common/scripts/utils/localStorage';
import { listUserAuth } from '@/fetchs/aischool/AiSchoolAuthFacade';
import { getCurrentQuestionLibVersion } from '@/fetchs/aischool/HomeworkTemplateFacade';
import { getMemberInfoById } from '@/fetchs/aischool/MemberQueryFacade';
import { getAA } from '@/fetchs/aischool/TeacherFacade';
import { queryHasNewList } from '@/fetchs/aischool/UserClickRecordFacade';
import {
  permissionPromiseReject,
  permissionPromiseResolve,
} from '@/permission';
import { authRoutes, authRoutesNoLoginLayout } from '@/router/router';

function getResultRouters(target, condition, res = []) {
  target.forEach((item) => {
    const role = item.meta?.role;
    if (process.env.npm_config_mock) {
      // mock忽略权限
      res.push(normalizeData(item));
      return res;
    }
    if (Array.isArray(role)) {
      let isExist = false;
      for (const roleItem of role) {
        if (condition.indexOf(roleItem) > -1 || !role) {
          isExist = true;
          break;
        }
      }
      if (isExist) {
        res.push(normalizeData(item, condition));
      }
    } else {
      if (condition.indexOf(role) > -1 || !role) {
        res.push(normalizeData(item, condition));
      }
    }
  });
  return res;
}

function normalizeData(item, condition) {
  const resItem = {
    path: item.path,
    name: item.name,
    meta: item.meta,
    component: item.component,
  };
  if (item.redirect) resItem.redirect = item.redirect;
  if (item.children) {
    resItem.children = [];
    getResultRouters(item.children, condition, resItem.children);
  }
  return resItem;
}

// 获取用户信息
const getUserInfo = async () => {
  const user = await getMemberInfoById();
  const {
    avatarUrl,
    firstName,
    nickName,
    schoolId,
    schoolName,
    sex,
    cityName,
    cityIdEd,
    cityCode,
    provinceName,
    provinceIdEd,
    countyName,
    countrIdEd,
    countyCode,
    id,
    teacherEntry,
    dataViewUrl,
    schoolBadgeUrl,
    showSchoolName,
    showSchoolBadgeUrl,
    mobileNo,
  } = user;
  if (teacherEntry !== 1) {
    if (location.pathname !== '/stu2') {
      // 跳转到学生端首页
      window.location.href = './stu2/';
      return;
    }
  }
  setMemberId(id);
  const userInfo = {
    id,
    sex,
    nickName,
    schoolId,
    cityName,
    provinceName,
    dataViewUrl,
    schoolBadgeUrl,
    name: firstName || nickName,
    cityId: cityIdEd,
    cityCode,
    school: schoolName,
    photoPath:
      avatarUrl ||
      'https://festatic.estudy.cn/assets/kkl-online-school/public/default-photo.png',
    provinceId: provinceIdEd,
    countyName,
    countrIdEd,
    countyCode,
    teacherEntry,
    showSchoolName,
    showSchoolBadgeUrl,
    mobileNo,
  };
  return userInfo;
};

// 获取用户NEW标签
const getHasNewList = async () => {
  let list = [];
  try {
    list = (await queryHasNewList()) ?? [];
  } catch (err) {}

  return list;
};
// 获取用户权限动态添加教师管理模块
const getUserPermission = async (curryCommit, userCommit) => {
  try {
    const permissionInfo = await getAA();
    if (permissionInfo) {
      Object.keys(permissionInfo).forEach((key) => {
        // if (key === 'schResourceHaveAA') {
        //   curryCommit({
        //     key,
        //     value: permissionInfo[key] || true,
        //   });
        //   return;
        // }
        // FIXME
        curryCommit({
          key,
          value: permissionInfo[key],
        });
      });

      permissionInfo.liveUrl && userCommit({ liveUrl: permissionInfo.liveUrl });

      permissionInfo.evaluationUrl &&
        userCommit({ evaluationUrl: permissionInfo.evaluationUrl });
    }
    setTimeout(permissionPromiseResolve, 10);
  } catch (err) {
    permissionPromiseReject(err);
    throw err;
  }
};

// 获取试题库版本
const getQuestionLibVersion = async (curryCommit) => {
  try {
    const version = await getCurrentQuestionLibVersion();
    curryCommit(version || 2);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// 老版本初始化应用
export async function initAppAndUserInfo(context) {
  const { commit, getters } = context;
  if (getters.isLogin) return;
  const userInfo = await getUserInfo();
  commit('user/SET_USER_INFO', userInfo);
  commit('SET_SCHOOL_ID', userInfo.schoolId);
  const curryPermissionCommit = (data) => {
    commit('permission/SET_PERMISSION_ATTR', data);
  };
  const curryQuestionLib = (data) => {
    commit('app/SET_QUESTION_LIBRARY_VERSION', data);
  };
  const userCommit = (data) => {
    commit('user/SET_USER_INFO', { ...userInfo, ...data });
  };

  await Promise.all([
    getUserPermission(curryPermissionCommit, userCommit),
    getQuestionLibVersion(curryQuestionLib),
  ]);
  commit('SET_LOGIN_STATUS', 2);
}

// 新版初始化应用
export async function initApp({ commit, getters }) {
  if (getters.isLogin) return;
  const userInfo = await getUserInfo();
  const hasNewList = await getHasNewList();

  let permissionList = [];
  if (userInfo?.teacherEntry === 1) {
    permissionList =
      cacheTools.getCache('permissionList', 'sessionStorage') ||
      (await listUserAuth()).map((item) => item.authority);
  }
  const resultRouters = getResultRouters(
    [...authRoutes, ...authRoutesNoLoginLayout],
    permissionList,
    [],
  );

  // 老权限同时控制路由和按钮，目前按钮权限没做所以目前需要保留，按钮权限完成后删除
  const curryPermissionCommit = (data) => {
    commit('permission/SET_PERMISSION_ATTR', data);
  };
  const userCommit = (data) => {
    commit('user/SET_USER_INFO', { ...userInfo, ...data });
  };
  await getUserPermission(curryPermissionCommit, userCommit);
  // 老权限同时控制路由和按钮，目前按钮权限没做所以目前需要保留，按钮权限完成后删除
  commit('user/SET_USER_INFO', {
    ...userInfo,
    permissionList,
    resultRouters,
    hasNewList,
  });
  commit('SET_SCHOOL_ID', userInfo.schoolId);
  const curryQuestionLib = (data) => {
    commit('app/SET_QUESTION_LIBRARY_VERSION', data);
  };
  getQuestionLibVersion(curryQuestionLib);
  commit('SET_LOGIN_STATUS', 2);
  cacheTools.setCache('permissionList', permissionList, 'sessionStorage');
}
