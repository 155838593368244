import {
  ADD_HOMEWORK_LESSON,
  REMOVE_HOMEWORK_LESSON,
  ADD_HOMEWORK_LESSON_INFO,
  REMOVE_ALL_HOMEWORK_LESSON,
  REMOVE_HOMEWORK_LESSON_INFO,
  ADD_HOMEWORK_LESSON_COMMODITY,
  ADD_HOMEWORK_LESSON_KNOWLEDGE,
  REMOVE_HOMEWORK_LESSON_KNOWLEDGE,
  PLUS_HOMEWORK_COMMODITY_LESSON_LENGTH,
  MINUS_HOMEWORK_COMMODITY_LESSON_LENGTH,
  INIT_HOMEWORK_ALL_COMMODITY_LESSON_LENGTH,
  CHANGE_HOMEWORK_LESSON,
  ADD_CURRENT_HOMEWORK_LESSON,
} from '../mutationTypes';

export default {
  [ADD_HOMEWORK_LESSON](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.lessonIds.push(...newIds);
    if (newIds.length) {
      store.modifyScheme = true;
    }
  },
  [ADD_HOMEWORK_LESSON_COMMODITY](store, arr) {
    let newArr = arr;
    if (!Array.isArray(arr)) {
      newArr = [arr];
    }
    newArr.forEach(({ id, commodityId }) => {
      store.cart.lessonCommodityMap[id] = commodityId;
    });
  },
  [REMOVE_HOMEWORK_LESSON](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.lessonIds = store.cart.lessonIds.filter(
      id => newIds.indexOf(id) === -1
    );
    // 清除lessonId => commidityId 映射
    newIds.forEach(id => {
      delete store.cart.lessonCommodityMap[id];
    });
    store.modifyScheme = true;
  },
  [REMOVE_ALL_HOMEWORK_LESSON](store) {
    store.cart.lessonIds = [];
    // 清空lessonId => commidityId 映射
    store.cart.lessonCommodityMap = {};
    store.modifyScheme = true;
  },
  [ADD_HOMEWORK_LESSON_INFO](store, { id, item }) {
    store.cart.lessonMap[id] = item;
  },
  [REMOVE_HOMEWORK_LESSON_INFO](store, id) {
    delete store.cart.lessonMap[id];
  },
  [ADD_HOMEWORK_LESSON_KNOWLEDGE](store, { id, knowledges }) {
    // console.log(store, knowledges);
    store.cart.lessonKnowledgeMap[id] = knowledges;
  },
  [REMOVE_HOMEWORK_LESSON_KNOWLEDGE](store, id) {
    delete store.cart.lessonKnowledgeMap[id];
  },
  [PLUS_HOMEWORK_COMMODITY_LESSON_LENGTH](store, id) {
    let { selectedCommodityLessonLengthMap } = store.cart;
    if (selectedCommodityLessonLengthMap[id] === undefined) {
      selectedCommodityLessonLengthMap[id] = 0;
    }
    selectedCommodityLessonLengthMap[id] += 1;
  },
  [MINUS_HOMEWORK_COMMODITY_LESSON_LENGTH](store, id) {
    store.cart.selectedCommodityLessonLengthMap[id] -= 1;
  },
  [INIT_HOMEWORK_ALL_COMMODITY_LESSON_LENGTH](store) {
    let { selectedCommodityLessonLengthMap } = store.cart;
    Object.keys(selectedCommodityLessonLengthMap).forEach(key => {
      delete selectedCommodityLessonLengthMap[key];
    });
  },
  [CHANGE_HOMEWORK_LESSON](store, { id, changeId }) {
    let newIds = id;
    if (!Array.isArray(id)) {
      newIds = [id];
    }
    store.cart.lessonIds.forEach((item, index) => {
      if (id === item) {
        store.cart.lessonIds.splice(index, 1, changeId);
      }
    });
    // 清除lessonId => commidityId 映射
    newIds.forEach(item => {
      delete store.cart.lessonCommodityMap[item];
    });
    store.modifyScheme = true;
  },
  [ADD_CURRENT_HOMEWORK_LESSON](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.lessonIds.forEach((item, index) => {
      if (ids === item) {
        store.cart.lessonIds.splice(index, 1, ids);
      }
    });
    // store.cart.lessonIds.push(...newIds);
    if (newIds.length) {
      store.modifyScheme = true;
    }
  },
};
