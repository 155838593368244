import cart from './cart';
import cache from './cache';
import scheme from './scheme';
import subject from './subject';
import automatic from './automatic';

export default {
  ...cart,
  ...cache,
  ...scheme,
  ...subject,
  ...automatic,
};
