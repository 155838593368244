/*
 * @Author: sanqi
 * @Date:   2017-09-21 14:34:21
 * @Last Modified by: sanqi.zgz
 * @Last Modified time: 2017-11-10 13:35:29
 */
const localStorageNamespace = 'KKL';
export const cacheTools = {
  getCache(key, type = 'localStorage') {
    let value =
      window[type].getItem(`${localStorageNamespace}_${key}`) ||
      window[type].getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  setCache(key, value, type = 'localStorage') {
    window[type].setItem(
      `${localStorageNamespace}_${key}`,
      JSON.stringify(value)
    );
  },
  clearCache(key, type = 'localStorage') {
    const keyList = Array.isArray(key) ? key : [key];
    keyList.forEach(item => {
      window[type].removeItem(`${localStorageNamespace}_${item}`);
    });
  },
  clearAllCache(type = 'localStorage') {
    window[type].clear();
  },
};

export const localStorageTools = {
  install(vue) {
    Object.keys(cacheTools).forEach(item => {
      vue.prototype[`$${item}`] = cacheTools[item];
    });
  },
};

export default localStorage;
