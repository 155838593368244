export const difficultyList = [
  {
    id: 0,
    name: '全部',
  },
  {
    id: 2,
    name: '基础',
  },
  {
    id: 3,
    name: '提优',
  },
  {
    id: 4,
    name: '拓展',
  },
  {
    id: 5,
    name: '夺冠',
  },
];

export const difficultyMap = difficultyList.slice(1).reduce((prev, next) => {
  prev[next.id] = next.name;
  return prev;
}, {});
