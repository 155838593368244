/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: sanqi.zgz
 * @Last Modified time: 2017-10-28 11:34:53
 */

/**
 * 根据试题id list查询 试题list
 * @export
 * @returns promise
 */
export function queryLesson({ lessonIds }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.CourseLessonFacade.queryLesson',
      param: {
        lessonQuery: {
          lessonIds,
        },
      },
    },
  });
}

/**
 * 根据试题课节id 查询课节信息
 * @export
 * @returns promise
 */
export function querySingleLesson(lessonId) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.CourseLessonFacade.querySingleLesson',
      param: {
        lessonId,
      },
    },
  });
}
