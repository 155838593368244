import {
  SET_HOMEWORK_AUTOMATIC_DATA,
  ADD_HOMEWORK_LISTEN,
  ADD_HOMEWORK_LESSON,
  ADD_HOMEWORK_LESSON_INFO,
  ADD_HOMEWORK_LESSON_KNOWLEDGE,
  ADD_HOMEWORK_LESSON_COMMODITY,
} from '../mutationTypes';

/**
 * @description 存贮智能组卷数据 到 cart
 * @param {*} { commit }
 * @param {*} data 智能组卷后接口返回的数据
 */
async function setHomeworkAutomaticData({ commit }, data) {
  if (!data) {
    return;
  }
  let { questionList, lessonList, listenList } = data;
  if (questionList && questionList.length) {
    const questionMap = {};
    const questions = data.questionList;
    // 存在的questionId
    let existQuestionIds = [];
    questions.forEach(question => {
      question.knowledgeIds = question.newKnowledges || [];
      question.knowledgeStrs = question.knowledges;
      question.questionType = question.kklQuestionType || question.questionType;
      questionMap[question.id] = question;
      existQuestionIds.push(question.id);
    });
    let obj = {
      questionMap,
      // selectedKnowledgeQuestionLengthMap,
    };
    let questionIds = [...existQuestionIds];
    obj.questionIds = questionIds;
    // debugger
    commit(SET_HOMEWORK_AUTOMATIC_DATA, obj);
  }
  if (listenList && listenList.length) {
    listenList.forEach(element => {
      commit(ADD_HOMEWORK_LISTEN, element);
    });
  }
  if (lessonList && lessonList.length) {
    lessonList.forEach(element => {
      let { id, knowledgeIds, commodityId } = element;
      let obj = {
        ...{ id },
        ...{ item: element },
        ...{ knowledges: knowledgeIds },
        ...{ commodityId },
      };
      commit(ADD_HOMEWORK_LESSON, id);
      commit(ADD_HOMEWORK_LESSON_INFO, obj);
      commit(ADD_HOMEWORK_LESSON_KNOWLEDGE, obj);
      commit(ADD_HOMEWORK_LESSON_COMMODITY, obj);
    });
  }
}

export default {
  setHomeworkAutomaticData,
};
