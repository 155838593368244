/*
 * @Author: zgz
 * @Email: zhangguozhong@kaike.la
 * @Date: 2017-11-29 19:55:23
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-01-21 14:50:35
 */
import NumToLetterMap from '@/common/enums/numToLetter';

/**
 * 截取指定长度中文字符串
 * @export
 * @param {String} string
 * @param {Number} len
 * @returns  String
 */
export function cutString(string, len) {
  const str = string || '';
  // 包含中文的字数限制
  const targetLen = len * 2;
  const strArr = str.split('');
  let resultStr = '';
  let currentLen = 0;
  const reg = '[^\x00-\xff]';
  const regExp = new RegExp(reg);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    if (regExp.test(strArr[i]) === true) {
      currentLen += 2;
    } else {
      currentLen += 1;
    }
    if (currentLen <= targetLen) {
      resultStr += strArr[i];
    } else {
      let sliceLen;
      if (regExp.test(resultStr.slice(-1)) === true) {
        sliceLen = 1;
      } else {
        sliceLen = 2;
      }
      resultStr = resultStr.slice(0, -sliceLen);
      resultStr += '...';
      break;
    }
  }
  return resultStr;
}
/**
 * 题干中可编辑标签改为不可编辑
 * @export
 * @param {String} html
 * @returns  String
 */
export function noEditFormat(html) {
  let _html = html || '';
  _html = _html
    .replace(/contenteditable='true'/gi, "contenteditable='false'")
    .replace(/<input/gi, '<input readOnly disabled ');
  return _html;
}

export function getLetterByNum(num) {
  if (!num) {
    return '';
  }
  return getLettersByNum(num).join('');
}

function getLettersByNum(Num) {
  const num = Num + 1;
  const arr = [];
  if (num <= 26) {
    arr.push(NumToLetterMap[num]);
    return arr;
  }
  const nextNum = Math.ceil((num - 26) / 26);
  let surplusNum = (num - 26) % 26;
  if (!surplusNum) {
    surplusNum = 26;
  }
  arr.push(NumToLetterMap[nextNum], NumToLetterMap[surplusNum]);
  return arr;
}

/**
 * 在字符串中每l位添加一个空格
 * 例如354857463 -> 3548 5746 3
 * @param {String} str 字符串
 * @param {Number} l 间隔位数（默认为4）
 */
export const formatSpaceStr = (str, l = 4) => {
  const regex = new RegExp(`(.{${l}})`, 'g');

  return str.replace(/\s/g, '').replace(regex, '$1 ');
};

export const randomString = (size) => {
  const builder = [];
  const randomString = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const maxCount = randomString.length - 1;
  for (let i = 0; i < size; i++) {
    const n = parseInt(Math.random() * maxCount, 10);
    builder.push(randomString[n]);
  }
  return builder.join('');
};
