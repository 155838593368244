/*
 * @Author: your name
 * @Date: 2021-12-31 10:50:02
 * @LastEditTime: 2022-01-04 16:11:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \kkl-online-school\src\fetchs\liveservice\LiveRoomFacade.js
 */
import KKL from '@/kkl';

const getServiceName = str => `liveservice.LiveRoomFacade.${str}`;

/**
 * 加入直播间
 * @param {Number} param.liveCode 
 */
 export const queryLiveInfo = (param = {}) => {

  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('join'),
      param: {
        bparam: param
      },
    },
  });
};

/**
 * 获取直播间用户详情
 * @param {Number} param.liveCode 
 */
 export const queryUserInfo = (param = {}) => {

  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('myInfo'),
      param: {
        bparam: param
      },
    },
  });
};