import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import user from './modules/user';
import homework from './modules/homework/index';
import permission from './modules/permission';
import paperDetail from './modules/paperDetail';
import liveCourseDetail from './modules/liveCourseDetail';
import guide from './modules/guide';
import liveCourse from './modules/liveCourse';
import IM from '@/common/components/IM/store/index';
import questionBasketList from './modules/questionBasketList';

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'; // 设置登录状态
export const SET_SCHOOL_ID = 'SET_SCHOOL_ID'; // 设置学校id
export const LOGOUT = 'LOGOUT'; // 退出登录
import { removeMemberId, removeToken } from '@/common/scripts/utils/business/login';

import * as actions from './actions';

Vue.use(Vuex);
const state = {
  loginStatus: 0, // 0, 未登录 1. 过渡状态 2. 已登录
  isLogin: false,
  schoolId: null,
};
const getters = {
  isLogin({ loginStatus }) {
    return loginStatus === 2;
  },
  userInfo({ user }) {
    return user;
  },
};

const mutations = {
  [SET_LOGIN_STATUS](state, status) {
    state.loginStatus = status;
  },
  [SET_SCHOOL_ID](state, id) {
    state.schoolId = id;
  },
  [LOGOUT](state) {
    // 修改登录状态
    state.loginStatus = 0;
    removeMemberId();
    removeToken();
    const removeCacheList = [
      'newTermId',
      'subjectId',
      'homeworkRecordSearchParams',
      'videoRepoSearchParams',
      'PsyIndexSearchParams',
      'newMoralEducationSearchParams',
      'newMoralEducationTrainingSearchParams',
      'newParentCollegeSearchParams',
      'newParentCollegeRecordSearchParams',
      'testRecordListSearchParams',
      'memType',
      'homeworkListIndex_form'
    ];
    Vue.prototype.$clearCache(removeCacheList);
    Vue.prototype.$clearCache(['permissionList'], 'sessionStorage');
    state.user.resultRouters = [];
    state.user.permissionList = null;
  },
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  getters,
  mutations,
  modules: {
    app,
    user,
    homework,
    // homeworkTest,
    permission,
    // homeworkDetail
    paperDetail,
    liveCourseDetail,
    guide,
    liveCourse,
    IM,
    questionBasketList,
  },
});

export default store;
