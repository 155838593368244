import { getToken, setToken } from '@/common/scripts/utils/business/login';
import { getRequest } from '@/common/scripts/utils/getRequest';
import { cacheTools } from '@/common/scripts/utils/localStorage';
import { checkMentalArchive } from '@/components/business/MentalArchiveCheckDialog/utils';
import { listUserAuth } from '@/fetchs/aischool/AiSchoolAuthFacade';
import { noAuthRoutes } from '@/router/router';
import store from '@/store/index';

const MentalArchiveCheckPaths = [
  /** 心理预警 */
  '/psychologyCenter/psychologicalWarning/list',
  /** 预警名单 */
  '/psychologyCenter/psychologicalWarning/detail',
  /** 告警列表 */
  '/psychologyCenter/noPerceptualWarning/list',
  /** 咨询记录 */
  '/psychologyCenter/consultRecord/consultRecordList',
  /** 成长档案 */
  '/psychologyCenter/psyRecord/list',
  /** 成长档案详情 */
  '/psychologyCenter/psyRecord/detail',
];

export async function beforeEachRoute(to, from, next) {
  const {
    query: { redirect },
  } = from;

  const {
    path,
    name,
    fullPath,
    meta: { role },
  } = to;
  let permissionList = [];

  if (to.query.token) {
    setToken(to.query.token);
    cacheTools.setCache('memType', 2, 'localStorage');
    const queryObj = getRequest(fullPath);
    delete queryObj.token;
    next({
      path,
      query: queryObj,
    });
  }
  const token = getToken();
  const inNoAuthRouters = noAuthRoutes.some((item) => item.name === name);
  if (inNoAuthRouters || !role) {
    next();
    return;
  }

  if (!token) {
    next({
      path: '/login',
      query: {
        redirect: fullPath,
      },
    });

    return;
  }

  const memType = cacheTools.getCache('memType');
  // 学生端登录，但是访问得是教师端得地址，为了不调用获取权限接口，需要判断用户类型，同时中断路由跳转
  if (memType === 1) {
    next(false);
    return;
  }

  permissionList =
    store.getters['user/getPermissions'] ||
    cacheTools.getCache('permissionList', 'sessionStorage') ||
    (await listUserAuth()).map((item) => item.authority);

  store.commit('user/SET_USER_PREMISSIONLIST', permissionList);
  cacheTools.setCache('permissionList', permissionList, 'sessionStorage');

  if (process.env.npm_config_mock) {
    next();
    return;
  }

  // 没有权限列表
  if (!permissionList.length) {
    next({
      name: 'noAuth',
      query: {
        from: 'login',
      },
    });

    return;
  }

  // 有权限列表
  // 有权限
  if (getHasAuth(permissionList, role)) {
    /**
     * 特定页面+特定权限的账号
     * moralismCenter.mentalfile.check
     */
    if (MentalArchiveCheckPaths.includes(to.path)) {
      // 二级密码校验
      const canGoNext = await checkMentalArchive();

      if (canGoNext) {
        next();
      } else {
        next(from.path);
      }

      return;
    }

    next();
    return;
  }

  if (redirect) {
    next({
      name: store.getters['user/getResultRouters'][0].children[0].name,
    });
  } else {
    next({ name: 'noAuth' });
  }
}

const getHasAuth = (permissionList, role) => {
  if (Array.isArray(role)) {
    let haveAuth = false;
    for (const roleItem of role) {
      if (permissionList.indexOf(roleItem) > -1) {
        haveAuth = true;
        break;
      }
    }

    return haveAuth;
  }

  return permissionList.indexOf(role) > -1;
};
