// 查学生课程
export function listCourse() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listCourse',
      param: {},
    },
  });
}
// 查课程下的课节
export function listLesson(opt) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listLesson',
      param: {
        ...opt,
      },
    },
  });
}
// 分页查询题目信息

export function page({ qry }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.page',
      param: {
        qry,
      },
    },
  });
}
// 查询题目统计信息

export function listOption(wqStatId) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listOption',
      param: {
        wqStatId,
      },
    },
  });
}

export function listQuestionByIds(wqStatIds) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listQuestionByIds',
      param: {
        wqStatIds,
      },
    },
  });
}

export function listQuestionForPdf(param) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listQuestionForPdf',
      param,
    },
  });
}

// 学习情况名单
export function listCompleteName(param) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listCompleteName',
      param,
    },
  });
}

// 获取班级列表
export function listWrongQuestionGroups(param) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listWrongQuestionGroups',
      param,
    },
  });
}


// V2版本
export function listOptionV2(wqStatKey) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listOptionV2',
      param: {
        wqStatKey,
      },
    },
  });
}

export function listQuestionByIdsV2(statKeys) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listQuestionByIdsV2',
      param: {
        statKeys,
      },
    },
  });
}
export function listQuestionForPdfV2(param) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listQuestionForPdfV2',
      param,
    },
  });
}
