/**
 * 新手引导及功能提示组件
 * 可配置网关api缓存及localstorage本地存储
 * 若被缓存命中则不在显示改组件
 * 后端对于cacheKey的key值长度限制64位
 *
 * @function LayerPrompt
 * @author liaochengxing
 */

import Vue from 'vue';
import VueLayerPrompt from './LayerPrompt';
import {
  isObjHasOwnProperty,
  isHitCache,
  insertAfterParentNode,
  parseOptions,
} from './utils';

// 默认参数配置
const defaultOptions = {
  type: 'feature', // 组件类型 feature:功能提示, operator:操作提示
  cacheKey: {}, // 网关api配置key、value缓存键值对
  visibility: true, // 组件是否显示
  message: '', // 主体内容提示
  button: '知道了', // 关闭按钮文案
  parentNode: '', // dom父节点id属性
  position: 'right', // 基于父容器的定位点、值为right、bottom、left、top之一
  size: {
    // 不同类型组件的默认宽高配置、该参数不进行修改、若要改变组件宽高需配置width、height参数
    feature: {
      width: 204,
      height: 86,
    },
    operator: {
      width: 160,
      height: 34,
    },
  },
  width: 0, // 组件配置宽度
  height: 0, // 组件配置高度
  duration: 0, // 多少秒后自动隐藏
  storageSettingsDev: true, // 是否启用缓存、方便调试
  storageSettingsLocal: false, // 仅进行本地localstorage缓存
};

// 组件实例存储队列
let queue = [];
// 是否单例模式
let singleton = true;
// 复制默认参数
let currentOptions = { ...defaultOptions };

/**
 * 组件实例化
 * 若没配置cacheKey或者未命中缓存则实例化显示组件
 *
 * @param {Object} options 调用时传入参数配置
 * @returns {Promise<Array>}
 */

async function createInstance(options) {
  // 队列为空或者非单例模式则实例化组件并添加进队列
  if (!queue.length || !singleton) {
    const prompt = new (Vue.extend(VueLayerPrompt))({
      el: document.createElement('div'),
    });
    const cacheKey = options.cacheKey || defaultOptions.cacheKey;
    const storageSettingsLocal =
      options.storageSettingsLocal || defaultOptions.storageSettingsLocal;
    const isCached = await isHitCache(
      cacheKey,
      defaultOptions.storageSettingsDev,
      storageSettingsLocal
    );
    // 组件实例是否被缓存标记、用于步骤提示组件、缓存过的实例将不被加入队列
    prompt.isCached = true;
    // 未配置cacheKey参数或者未命中缓存则显示组件并添加进实例队列
    if (!isObjHasOwnProperty(cacheKey) || !isCached) {
      prompt.isCached = false;
      insertAfterParentNode(options, prompt, defaultOptions);
    }
    queue.push(prompt);
  }
  return queue[queue.length - 1];
}

/**
 * 组件构造函数
 * createInstance创建实例
 * 将传入参数合并到vue组件实例对象上、方便后续组件模版及生命周期上调用
 * 若配置了duration参数、则设置定时器隐藏
 *
 * @param {Object} [options={}] 调用时传入参数配置
 * @returns {Promise<Array>}
 */

async function LayerPrompt(options = {}) {
  const prompt = await createInstance(options);
  const _options = {
    ...currentOptions,
    ...parseOptions(options),
    clear() {
      prompt.visibility = false;
    },
  };
  Object.assign(prompt, _options);
  clearTimeout(prompt.timer);
  if (_options.duration > 0) {
    prompt.timer = setTimeout(() => {
      prompt.clear();
    }, _options.duration);
  }
  return prompt;
}

/**
 * LayerPrompt函数向外暴露api接口
 * 隐藏组件实例
 *
 * @param {Boolean} all 是否隐藏队列中所有实例
 * @returns {Promise<Array>}
 */

LayerPrompt.clear = all => {
  if (queue.length) {
    if (all) {
      queue.forEach(prompt => {
        prompt.clear();
      });
      queue = [];
    } else if (singleton) {
      queue[0].clear();
    } else {
      queue.shift().clear();
    }
  }
};

// 设置默认参数
LayerPrompt.setDefaultOptions = options => {
  Object.assign(currentOptions, options);
};

// 重置默认参数
LayerPrompt.resetDefaultOptions = () => {
  currentOptions = { ...defaultOptions };
};

// 是否允许多实例
LayerPrompt.instanceMultiple = (multiple = true) => {
  singleton = !multiple;
};

LayerPrompt.install = () => {
  Vue.use(VueLayerPrompt);
};

Vue.prototype.$LayerPrompt = LayerPrompt;

export default LayerPrompt;
