import { Message } from 'element-ui';

import { cacheTools } from '@/common/scripts/utils/localStorage';
import { queryMentalArchiveCheck } from '@/fetchs/aischool/MemberQueryFacade';

import MentalArchiveCheckDialog from './index';

export const checkMentalArchive = () => {
  return new Promise((resolve) => {
    const permissionList = cacheTools.getCache(
      'permissionList',
      'sessionStorage',
    );

    /** 特定权限 */
    if (!permissionList.includes('moralismCenter.mentalfile.check')) {
      resolve(true);
      return;
    }

    // 30分钟内是否校验过
    queryMentalArchiveCheck()
      .then((resultObject: { pass: boolean }) => {
        const { pass } = resultObject;
        if (pass) {
          resolve(true);
        } else {
          setTimeout(async () => {
            try {
              // 校验弹窗
              await MentalArchiveCheckDialog();
              resolve(true);
            } catch (e) {
              resolve(false);
            }
          }, 100);
        }
      })
      .catch((e: any) => {
        Message.error(e.message);
        resolve(false);
      });
  });
};
