/*
 * @Author: your name
 * @Date: 2021-12-20 17:54:49
 * @LastEditTime: 2022-02-28 17:16:54
 * @LastEditors: Please set LastEditors
 * @Description: 直播教学
 * @FilePath: \kkl-online-school\src\store\modules\liveCourse\index.js
 */

export const SET_MIN_CONTENT_HEIGHT = 'SET_MIN_CONTENT_HEIGHT'; // 设置最小内容高度

const state = {
  minContentHeight: 0,
};

const mutations = {
  [SET_MIN_CONTENT_HEIGHT](state, data) {
    state.minContentHeight = data;
  },
}

import playback from './modules/playback';
import liveRoom from './modules/liveRoom';
import course from './modules/course';

const modules = {
  playback, 
  liveRoom,
  course,
};

export default {
  name: 'liveCourse',
  namespaced: true,
  state,
  mutations,
  modules,
};
