<template>
  <div class="content-layout">
    <slot name="header"></slot>
    <slot name="other"></slot>
    <div class="auto-height" :class="{ top, transparent: noBg }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLayout',
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less" scoped>
.content-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  margin-left: 16px;
  padding: 16px 16px 16px 0;
  .auto-height {
    background-color: @g-white;
    flex: 1;
    border-radius: 2px;
    &.top {
      margin-top: 8px;
    }
    &.transparent {
      background-color: transparent;
    }
  }
}
</style>
