/*
 * @Author: your name
 * @Date: 2021-10-28 13:49:52
 * @LastEditTime: 2022-03-02 15:14:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xhx-web\src\common\components\IM\enums\index.ts
 */

/** 聊天模式 */
export const ChatMode = {
  /** 单聊 */
  ALLOW: 1,
  /** 群聊 */
  GROUP: 2,
  /** 全部类型 */
  ALL: 3,
}

/* 聊天模式 与 会话类型 映射关系 */
export const ChatMode_CONVTYPE = {
  [ChatMode.ALLOW]: 'C2C',
  [ChatMode.GROUP]: 'GROUP',
};

/** C2C会话发送失败 error code 集合 */
export const C2CSendErrorCodeList = [120002, 80001];

export const GroupSendErrorCodeMap = {
  10101: '发送失败，当前处于禁言状态',
  10102: '发送失败，好好发言不要违规哦',
  10103: '发送失败，内容过长',
  10104: '发送失败，发言频次太快了，请稍后再试',
};

/**
* 聊天用户身份类型
*/
export const RoleType = {
  /** 学生 */
  student: 1,
  /** 辅导老师私聊 */
  advisor: 3,
  /** 主讲老师 */
  master: 2,
}

// 禁言 bizType
export const muteBizType = 26;
// 取消禁言 bizType
export const cancelMuteBizType = 25;
// 上课开始消息 bizType
export const startLessonBizType = 5;

export const muteBizTypeList = [muteBizType, cancelMuteBizType];


// 禁言/取消禁言functionType
export const ImMuteFunctionType = {
  // 频道发言
  GROUP_CANCEL_MUTE: '2501',
  // 答题结束，频道取消禁言
  GROUP_CANCEL_MUTE_CAUSEBY_INTERACTION_END: '2503',
  // 用户发言
  USER_CANCEL_MUTE: '2502',
  // 频道禁言
  GROUP_MUTE: '2601',
  // 用户禁言
  USER_MUTE: '2602',
  // 答题开始，频道禁言
  GROUP_MUTE_CAUSEBY_INTERACTION_START: '2603'
};

// 上课functionType
export const StartLessonFunctionType = '616';