export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const padZero = (str, len = 2) => {
  return `${str}`.padStart(len, '0');
};

export const parseTimeData = time => {
  const days = Math.floor(time / DAY);
  const hours = Math.floor((time % DAY) / HOUR);
  const minutes = Math.floor((time % HOUR) / MINUTE);
  const seconds = Math.floor((time % MINUTE) / SECOND);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 *
 * @param {String} format DD:HH:MM:SS
 * @param {*} timeData
 */
export const parseTimeFormat = (format, timeData) => {
  let { days, hours, minutes, seconds } = timeData;

  // 不需要处理天
  if (~format.indexOf('DD')) {
    format = format.replace('DD', padZero(days));
  } else {
    hours += days * 24;
  }

  if (~format.indexOf('HH')) {
    format = format.replace('HH', padZero(hours));
  } else {
    minutes += hours * 60;
  }

  if (~format.indexOf('MM')) {
    format = format.replace('MM', padZero(minutes));
  } else {
    seconds += minutes * 1000;
  }

  // if (~format.indexOf('SS')) {
  format = format.replace('SS', padZero(seconds));
  // } else {
  //   format = format.replace('SS', padZero(seconds));
  //   console.log(111)
  // }

  return format;
};
