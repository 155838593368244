<template>
  <div class="left-nav-block">
    <Help v-if="memType === 2" />
    <IconPopover v-else />
  </div>
</template>

<script>
import Help from './Help.vue';
import IconPopover from '@/common/components/popover/iconPopover.vue';

export default {
  name: 'LeftNavBottom',

  components: {
    Help,
    IconPopover,
  },

  data() {
    return {
      content: '',
      isShowStorageTip: false,
      isShowQrcode: true,
      loading: false,
      questionList: [],
      selectedId: null,
      randomQuestionMap: null,
    };
  },

  computed: {
    memType() {
      return this.$getCache('memType');
    },
  },
};
</script>

<style lang="less" scoped>
.left-nav-block {
  padding: 5px 5px 0;
  background: linear-gradient(180deg, #fff 0%, #e4eeff 100%);
}
</style>
