import Vue from 'vue';

import { cacheTools } from '@/common/scripts/utils/localStorage';

/**
 * 因为现在目前没有完整的权限系统
 * 所以现在只能根据memType来判断
 * memTypeMap其实是关于memType和没有对应权限范围的一个List的映射
 */
const memTypeMap = {
  /**
   * 3表示教育局
   * 不在教育局账号角色范围中的事情
   */
  99: [/* 布置 */ 'ASSIGN', /* 心理训练上课权限 */ 'PSYTRAININGCLASS'],
};

/**
 * v-permission="'ASSIGN'"
 */
export default Vue.directive('permission', {
  inserted: function(el, binding) {
    const memType = cacheTools.getCache('memType') ?? 2;
    console.log('memType', memType);
    const { value } = binding;
    // console.log(binding);
    // let val = '';
    // let role = '';
    // if (arg === 3) {
    //   val = value;
    //   role = arg;
    // } else {
    //   const { roleType, permission } = value;
    //   val = permission;
    //   role = roleType;
    // }
    if (memTypeMap[memType]?.includes(value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
});
