/*
 * @Author: your name
 * @Date: 2021-12-20 21:09:49
 * @LastEditTime: 2021-12-20 21:14:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \kkl-online-school\src\store\modules\liveCourse\modules\playback.js
 */


const state = {
  /**播放器全屏 */
  fullScreen: false,
  /**播放器控制条 */
  controllerBar: {
    // 是否锁定控制栏
    show: false,
    duration: 5 * 1000,
  },
  immersionMode: false, // 播放器区域进入沉浸模式
};

const mutations = {
  setFullScreen(state, status) {
    state.fullScreen = status;
  },
  setControllerBarShowFlag(state, flag) {
    state.controllerBar.show = flag;
  },
  setImmersionMode(state, status) {
    state.immersionMode = status;
  },
}

const store = {
  namespaced: true,
  state,
  mutations,
};

export default store;