<template>
  <el-submenu v-if="!renderChildren" class="first__menu" :index="`${data.name}-${data.meta.roleId}`">
    <template v-slot:title>
      <div>
        <img
          v-if="data.meta.icon"
          class="icon"
          :src="
            active === `${data.name}-${data.meta.roleId}` || `${data.name}-${data.meta.roleId}` === activeParentIndex
              ? data.meta.activeIcon
              : data.meta.icon
          "
          alt=""
        />
        <span class="menu__name">
          {{ data.meta.menuName }}
        </span>
      </div>
      <i v-if="hasNewList.includes(data.meta.roleId)" class="icon-new"></i>
    </template>
    <template v-for="item in data.children">
      <template v-if="item.children">
        <SubMenu :key="item.name" :data="item" :has-new-list="hasNewList" />
      </template>
      <template v-else>
        <el-menu-item
          v-if="!item.meta.hideInMenu"
          :key="item.name"
          class="el-menu-item-hover"
          :index="`${item.name}-${item.meta.roleId}`"
        >
          <div>
            <img
              v-if="item.meta.icon"
              :src="active === `${item.name}-${item.meta.roleId}` ? item.meta.activeIcon : item.meta.icon"
              alt=""
            />
            <span>{{ item.meta.menuName }}</span>
          </div>
          <i v-if="hasNewList.includes(item.meta.roleId)" class="icon-new"></i>
        </el-menu-item>
      </template>
    </template>
  </el-submenu>
  <el-menu-item v-else class="el-menu-item-hover" :index="`${data.name}-${data.meta.roleId}`">
    <div>
      <img
        v-if="data.meta.icon"
        class="icon"
        :src="active === `${data.name}-${data.meta.roleId}` ? data.meta.activeIcon : data.meta.icon"
        alt=""
      />
      <span class="menu__name">{{ data.meta.menuName }}</span>
    </div>
    <i v-if="hasNewList.includes(data.meta.roleId)" class="icon-new"></i>
  </el-menu-item>
</template>

<script>
export default {
  name: 'SubMenu',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String,
    },
    activeParentIndex: {
      type: String,
    },
    hasNewList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    renderChildren() {
      return this.data.children.every((item) => item.meta.hideInMenu);
    },
  },
};
</script>

<style lang="less" scoped>
@import './menu.less';
</style>
