import { permissionKeyMap, interfaceKeyToPermissionKeyMap } from '@/permission';

export const SET_TEACHER_MANAGE_PERMISSION = 'SET_TEACHER_MANAGE_PERMISSION'; // 设置教师权限
export const SET_TESTING_MANAGE_PERMISSION = 'SET_TESTING_MANAGE_PERMISSION'; // 设置测评权限
export const SET_SCHOOL_RESOURCE_MANAGE_PERMISSION =
  'SET_SCHOOL_RESOURCE_MANAGE_PERMISSION'; // 设置测评权限
export const SET_TESTING_ARRANGE_PERMISSION = 'SET_TESTING_ARRANGE_PERMISSION'; // 测评布置权限
export const SET_TRAIN_MANAGE_PERMISSION = 'SET_TRAIN_MANAGE_PERMISSION'; // 设置心理训练权限
export const SET_PERMISSION_ATTR = 'SET_PERMISSION_ATTR'; // 设置权限属性

const state = {
  ...permissionKeyMap,
};

const mutations = {
  [SET_TEACHER_MANAGE_PERMISSION](store, payload) {
    store.teacherManage = payload;
  },
  [SET_TESTING_MANAGE_PERMISSION](store, payload) {
    store.testingManage = payload;
  },
  [SET_SCHOOL_RESOURCE_MANAGE_PERMISSION](store, payload) {
    store.schoolResourceManage = payload;
  },
  [SET_TESTING_ARRANGE_PERMISSION](store, payload) {
    store.testingArrange = payload;
  },
  [SET_TRAIN_MANAGE_PERMISSION](store, payload) {
    const { psyTrainingReleaseHaveAA, psyTrainingClassHaveAA } = payload;
    store.trainReleaseManage = psyTrainingReleaseHaveAA;
    store.trainClassManage = psyTrainingClassHaveAA;
  },
  [SET_PERMISSION_ATTR](store, { key, value }) {
    let permissionKey = interfaceKeyToPermissionKeyMap[key];
    if (permissionKey) {
      store[permissionKey] = !!value;
    }
  },
};

export default {
  name: 'permission',
  namespaced: true,
  state,
  mutations,
};
