/** 这里是数据中心相关的埋点 */

// 全站数据报告
export const DATA_CENTER_REPORT = {
  // 学科作业板块 - 班级作业数据
  TAB_CLASS_HOMEWORK_DATA: '191c0ff1',
  // 学科作业板块 - 教师布置数据
  TAB_TEACHER_ASSIGN_DATA: '941ff523',
  // 教师成长板块
  TAB_TEACHER_GROWTH: '03c45059',
  // 学生心理版块
  TAB_STUDENTS_MENTAL: '066a2622',
  // 家庭教育板块
  TAB_FAMILY_EDUCATION: '778fbd2d',
};

// 开课啦数据报告
export const KKL_DATA_REPORT = {
  // 学期数据报告-生成报告按钮
  GENERATE_TERM_DATA_REPORT: 'c42e0f94',
  // 学员学情报告 - 查看该报告
  VIEW_ACADWMY_LEARNING_REPORT: 'b34411ef',
};

// 可视化大屏
export const NAV_MENU_VISUAL_SCREEN = 'db14fc2e';
