<template>
  <el-dialog
    v-if="dialogVisible"
    class="k-message"
    :style="{ width: `${width}px` }"
    :type="type"
    :show-title="showTitle ? 1 : 0"
    :visible.sync="dialogVisible"
    size="full"
    :modal="modal"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="0"
  >
    <el-button v-if="showClose" class="close-btn" @click="close">
      <i class="el-icon el-icon-close"></i>
    </el-button>
    <h3 v-if="isShowTitle">
      <i
        v-if="!hidnIcon"
        class="k-icon"
        :class="[icon, typeClass]"
        :style="{
          color: iconColor,
        }"
      ></i>
      {{ titleInfo }}
    </h3>

    <p
      v-if="!showDom"
      class="k-message-content"
      :style="{
        paddingTop: showTitle === false ? '0' : '',
      }"
    >
      {{ content }}
    </p>
    <!-- warn 通过dom形式插入 -->
    <div
      v-else
      ref="content"
      class="k-message-content"
      :style="{
        paddingTop: showTitle === false ? '0' : '',
      }"
    ></div>
    <div v-if="type === 'error' || type === 'warn'" slot="footer" class="dialog-footer">
      <k-button class="k-button btn-cancel" size="large" type="dialog-primary" @click="ok">
        {{ footerBtnText }}
      </k-button>
    </div>
    <div v-else-if="type === 'confirm'" slot="footer" class="dialog-footer two-btn">
      <k-button class="k-button btn-cancel" type="default" @click="deny">
        {{ cancelBtnText }}
      </k-button>
      <k-button class="k-button btn-cancel" type="primary" @click="ok">
        {{ okBtnText }}
      </k-button>
    </div>
  </el-dialog>
</template>

<script>
import kButton from '@/common/components/button';

const typeMap = {
  error: 'k-i-err',
  success: 'k-i-suc',
  warn: 'k-i-warn',
};

export default {
  name: 'KDialog',
  components: {
    kButton,
  },
  props: {
    value: [String, Number, Object],
    content: String,
    sure: Function,
    cancel: Function,
    title: String,
    sureText: String,
    cancelText: String,
    icon: String,
    dom: HTMLDivElement,
    iconColor: String,
    showClose: {
      type: Boolean,
      default: false,
    },
    footerBtnText: {
      type: String,
      default: '知道了',
    },
    showTitle: {
      type: Boolean,
      default: true,
    }, // 只有error warn confirm时有效
    hidnIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'success',
    },
    width: Number,
  },
  data() {
    const typeClass = typeMap[this.type] || '';

    return {
      typeClass,
      dialogVisible: true,
      isConfirm: this.type === 'confirm',
      okBtnText: this.sureText || '确定',
      cancelBtnText: this.cancelText || '取消',
    };
  },
  computed: {
    titleInfo() {
      const infoMap = {
        warn: '提示',
        error: '操作失败',
        success: '操作成功',
      };
      const info = this.title || infoMap[this.type] || '';
      return info;
    },
    hasConfirmTitle() {
      return this.isConfirm && this.title != null;
    },
    modal() {
      const arr = ['error', 'confirm', 'warn'];
      return arr.indexOf(this.type) > -1;
    },
    isShowTitle() {
      const types = ['error', 'confirm', 'warn'];
      const showTitle = types.indexOf(this.type) > -1 && this.showTitle;
      return this.type === 'success' || showTitle;
    },
    showDom() {
      if (['warn', 'confirm'].indexOf(this.type) > -1 && this.dom) {
        return true;
      }
      return false;
    },
  },
  watch: {
    dialogVisible(newValue) {
      this.$emit('update:value', newValue);
    },
  },
  mounted() {
    // 针对hint类型处理
    if (this.showDom) {
      setTimeout(() => {
        this.handleDom();
      }, 0);
    }
  },
  created() {
    this.autoClose();
  },
  beforeDestroy() {
    clearTimeout(this.switchId);
    this.close();
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    autoClose() {
      const arr = ['toast', 'success'];
      if (arr.indexOf(this.type) > -1) {
        this.switchId = setTimeout(() => {
          this.close();
        }, 2000);
      }
    },
    handleDom() {
      const { content } = this.$refs;
      console.log(content, this.dom);
      if (content) {
        content.appendChild(this.dom);
      }
    },
    // handleClose(done) {
    //     this.$confirm('确认关闭？')
    //     .then(() => {
    //         done();
    //     })
    //     .catch(() => {});
    // },
    deny() {
      this.close();
      this.cancel && this.cancel();
      // this.$emit('cancel');
    },
    ok() {
      this.close();
      this.sure && this.sure();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import './style.less';
</style>
<style lang="less">
.k-message {
  // .el-dialog {
  //   box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  // }
  &[type='success'],
  &[type='toast'] {
    .el-dialog--full {
      width: auto;
      min-width: 240px;
      height: 60px;
    }
  }

  &[type='error'],
  &[type='warn'],
  &[type='confirm'] {
    .el-dialog {
      position: relative;
    }

    .el-dialog--full {
      height: auto;
    }
    // 针对复杂message 没有title body不小于39px;
    &[show-title='0'] .el-dialog__body {
      min-height: 39px;
    }
  }

  .el-dialog__header {
    display: none;
  }

  .close-btn {
    padding: 0;
    border: none;
    outline: none;
    float: right;
    background-color: transparent;
    cursor: pointer;
  }

  .el-dialog__body {
    padding: 21px 21px 60px;
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }
}
</style>
