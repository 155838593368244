<template>
  <router-view v-if="loaded" id="app" v-loading="showLoading"></router-view>
</template>

<script>
import { mapGetters } from 'vuex';

import favicon from '@/common/mixins/favicon';
import title from '@/common/mixins/title';
import { getToken } from '@/common/scripts/utils/business/login';

import { checkMentalArchive } from './components/business/MentalArchiveCheckDialog/utils';

export default {
  name: 'App',
  mixins: [favicon, title],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getResultRouters']),
    showLoading() {
      if (this.$route.path === '/' || this.$route.path === '/index') {
        return false;
      }
      if (this.loaded) {
        return false;
      }
      return true;
    },
  },
  async created() {
    if (this.$route.path === '/system_info') {
      this.loaded = true;
      return;
    }
    try {
      if (getToken()) {
        await this.$store.dispatch('initApp');
      }
    } catch (err) {}

    this.loaded = true;

    window.$_EVENT.on('mental-archive-check', this.onCheck);
  },

  beforeDestroy() {
    window.$_EVENT.off('mental-archive-check', this.onCheck);
  },

  methods: {
    async onCheck(callback = () => {}) {
      const canGoNext = await checkMentalArchive();

      if (canGoNext) {
        callback();
      }
    },
  },
};
</script>
