<template>
  <Layout>
    <template v-slot:header>
      <WlxHeader>
        <div class="nav-area">
          <el-menu
            v-if="showNav"
            mode="horizontal"
            :default-active="menuActive.activeIndex"
            class="top__menu"
            @select="select"
          >
            <el-menu-item
              v-for="item in topMenuList"
              :key="item.name"
              :index="item.name"
              :data-role="item.name"
            >
              {{ item.meta.menuName }}
            </el-menu-item>
          </el-menu>
        </div>
      </WlxHeader>
    </template>
    <div
      v-if="showContent"
      ref="main"
      class="main"
      :class="{ 'main__no-left-nav': !showLeftMenu }"
    >
      <div v-if="showLeftMenu" class="left-nav">
        <div class="nav-list">
          <NavList
            ref="leftNavNew"
            :active="menuActive.leftActiveIndex"
            :active-parent-index="menuActive.leftActiveParentIndex"
            :openeds="menuActive.leftOpeneds"
            :has-new-list="user.hasNewList"
            :data="leftMenuList.children"
            @select="leftSelect"
            @open="onMenuOpen"
            @close="onMenuClose"
          />
        </div>
        <LeftNavBottom />
      </div>

      <article
        class="content"
        :class="{
          'not-found-area': !showMenu,
        }"
      >
        <router-view class="view-area"></router-view>
      </article>
    </div>

    <!-- <homeworkRenameGuide
      v-if="showContent && showGuidePage"
      :showGuidePage.sync="showGuidePage"
    ></homeworkRenameGuide> -->
  </Layout>
</template>

<script>
// import KTab from '@/common/components/tab';
import { mapState, mapActions, mapMutations } from 'vuex';
import appStore, { SET_LOADING_STATUS } from '@/store/modules/app';

import store, { SET_USER_HASNEWLIST } from '@/store/modules/user';

import homeworkStore from '@/store/modules/homework';
// import permissionStore from '@/store/modules/permission';
// import KDialog from '@/common/components/dialog';

import WlxHeader from './WlxHeader.vue';

import LeftNavBottom from './LeftNavBottom.vue';
import NavList from './NavList.vue';

// import UpdateContentDialog from './UpdateContentDialog.vue';

// import GuideDialog from './GuideDialog.vue';

import { menuClick } from '@/fetchs/aischool/UserClickRecordFacade';

import Layout from './Layout';

import { getRedictPageName } from '@/common/scripts/utils/business/menu.js';

// const noFrameRoutes = {
//   trainingLessonPlay: true,
//   trainingLessonExpand: true,
//   campus_reader_play: true,
//   liveroom: true,
//   videoroom: true,
// };

export default {
  name: 'LoginLayout',
  components: {
    Layout,
    // KTab,
    // KDialog,
    // homeworkRenameGuide,
    WlxHeader,
    // UpdateContentDialog,
    // GuideDialog,
    LeftNavBottom,
    NavList,
  },
  data() {
    // let currentNav = null;
    // let currentLeftNav = null;
    return {
      // currentNav,
      // currentLeftNav,
      leftNav: [],
      // showGuidePage: true,
      // currentPage: 'index',
      // 左边菜单列表
      showContent: false,
      // content: '新增"班级易错题"功能，整理了近期练习中您班级的学生易错的题目和相关知识点，点击查看',
      // keyName: 'wrong-menu',
      leftMenuList: [],
      menuActive: {
        activeIndex: null,
        leftActiveIndex: null,
        leftActiveParentIndex: null,
        leftOpeneds: [],
      },
    };
  },
  computed: {
    ...mapState(store.name, {
      user: (state) => state,
    }),
    // ...mapState(appStore.name, {
    //   loading: state => state.loading,
    // }),
    // ...mapState(permissionStore.name, {
    //   teacherManage: state => state.teacherManage,
    // }),
    showMenu() {
      return this.$route.name !== 'not-found';
    },

    showNav() {
      // const { path } = this.$route;
      // return (
      //   path.indexOf('/teachingCenter') === -1 &&
      //   path.indexOf('/schoolRunning') === -1
      // );
      return true;
    },
    // navList() {
    //   let { navs } = this;
    //   return navs;
    // },

    memType() {
      return this.$getCache('memType');
    },
    menuList() {
      return this.user.resultRouters;
    },
    topMenuList() {
      const routers = this.menuList;
      return routers.filter((item) => !item.meta.hideInMenu);
    },

    showLeftMenu() {
      const {
        name,
        meta: { hasSideMenu = true },
      } = this.$route;
      return this.leftMenuList && name !== 'noAuth' && hasSideMenu;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.initMenu();
        // this.routeChangeSetMenu(to);
        this.modifyHasNewList();
      },
    },
    // teacherManage(flag) {
    //   if (!flag) {
    //     this.navs[2].show = false;
    //   }
    // },
  },
  created() {
    this.topMenuRedictPageMap = {};
    this.init();
    window.addEventListener('beforeunload', this.saveUserStoreInfo);
    this.$emit('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.saveUserStoreInfo);
    });
  },

  methods: {
    ...mapActions(homeworkStore.name, [
      'saveStoreToCache',
      'setStoreFromCache',
    ]),
    ...mapMutations(appStore.name, {
      setLoadingStatus: SET_LOADING_STATUS,
    }),
    ...mapMutations(store.name, {
      setHasNewList: SET_USER_HASNEWLIST,
    }),

    async modifyHasNewList() {
      const { leftActiveIndex } = this.menuActive;
      const [, roleId] = leftActiveIndex.split('-');
      const { hasNewList } = this.user;
      if (!roleId) return;

      if (hasNewList.includes(roleId)) {
        try {
          await menuClick(roleId);
          this.setHasNewList(hasNewList.filter((item) => item !== roleId));
        } catch (e) {}
      }
    },

    async init() {
      this.setLoadingStatus(true);
      this.initMenu();
      this.setStoreFromCache(this.user.id);
      this.showContent = true;
      this.setLoadingStatus(false);
      // 页面刷新缓存homewordStore数据
      window.addEventListener('beforeunload', () => {
        this.saveStoreToCache(this.user.id);
      });
    },

    saveUserStoreInfo() {
      this.saveStoreToCache(this.user.id);
    },
    // 新增
    initMenu() {
      this.routeChangeSetMenu(this.$route);

      const leftMenuList =
        this.menuList.filter(
          (item) => item.name === this.menuActive.activeIndex,
        )[0] ?? null;

      if (leftMenuList?.meta?.directLink) {
        this.leftMenuList = null;
      } else {
        this.leftMenuList = leftMenuList;
      }

      let hasGetActiveIndex = false;
      let {
        leftOpeneds = [],
        leftActiveParentIndex,
        leftActiveIndex: leftActiveIndexNow,
      } = this.menuActive;
      leftMenuList?.children?.forEach((item) => {
        const {
          name,
          meta = { roleId, open, hasSubmenu },
          children = [],
        } = item;
        const { roleId, open, hasSubmenu } = meta;
        const index = `${name}-${roleId}`;

        if (hasSubmenu && !hasGetActiveIndex) {
          const hasIndex = this.hasOpenIndex(children, leftActiveIndexNow);

          if (hasIndex) {
            leftActiveParentIndex = index;
            !leftOpeneds.includes(index) && leftOpeneds.push(index);
            hasGetActiveIndex = true;
          }
        }

        if (open && !leftOpeneds.includes(open)) {
          leftOpeneds.push(index);
        }
      });

      this.menuActive.leftActiveParentIndex = leftActiveParentIndex;
      this.menuActive.leftOpeneds = leftOpeneds;
    },

    hasOpenIndex(menuList, activeIndex) {
      const l = menuList.length;

      for (let i = 0; i < l; i++) {
        const item = menuList[i];
        const {
          name,
          meta: { roleId },
        } = item;

        const index = `${name}-${roleId}`;
        if (index === activeIndex) {
          return true;
        }
      }

      return false;
    },

    setMenu() {
      const {
        menuList,
        menuActive: { activeIndex },
      } = this;

      const leftMenuList = menuList.filter(
        (item) => item.name === activeIndex,
      )[0];

      const { outLink, statId, directLink } = leftMenuList.meta;

      if (directLink) {
        this.$router.push({ name: activeIndex });
        this.leftMenuList = null;
        return;
      }

      if (outLink) {
        statId &&
          window.KKLbigdata.send({
            type: 'click',
            click_id: statId,
            u: outLink,
          });
        window.open(outLink, '_blank');
      } else {
        let {
          name,
          meta: { roleId },
        } = leftMenuList.children[0];
        const redictPageName = this.topMenuRedictPageMap[name];
        if (redictPageName) {
          name = redictPageName;
        } else {
          name = getRedictPageName(leftMenuList.children);
        }

        this.$router.push({ name });
        this.menuActive.leftActiveIndex = `${name}-${roleId}`;
      }

      this.leftMenuList = leftMenuList;
    },

    getRoute({ indexPath, menuList }) {
      if (!indexPath.length) {
        return menuList;
      }

      const index = indexPath[0];

      const list = menuList.children.filter((menu) => {
        return `${menu.name}-${menu.meta.roleId}` === index;
      });

      indexPath.shift();

      return this.getRoute({ indexPath, menuList: list[0] });
    },

    setLeftMenu(index, indexPath) {
      const menuList = JSON.parse(JSON.stringify(this.leftMenuList));

      const routeItem = this.getRoute({
        indexPath,
        menuList,
      });

      if (!routeItem) return;

      const {
        // name = '',
        meta: { outLink, statId },
      } = routeItem;

      // 可视化大屏的链接地址需要从store中拿
      // if (name === 'panel') {
      //   outLink = this.user.dataViewUrl;
      // }

      statId &&
        window.KKLbigdata.send({
          type: 'click',
          click_id: statId,
          u: outLink,
        });

      if (outLink) {
        window.open(outLink, '_blank');
      }
    },

    // setActiveIndexCache() {
    //   this.$setCache('menuActive', this.menuActive, 'sessionStorage');
    // },

    select(index, indexPath) {
      this.menuActive.activeIndex = index;
      this.setMenu();
    },

    async leftSelect(index, indexPath) {
      !indexPath?.length && (indexPath = [index]);
      const [name] = index.split('-');

      // if (name !== 'panel') {
      this.$router.push({ name });
      this.menuActive.leftActiveIndex = index;
      this.menuActive.leftActiveParentIndex = indexPath[0];
      // }

      this.setLeftMenu(index, indexPath);
    },

    onMenuOpen(index, indexPath) {
      const { leftOpeneds } = this.menuActive;

      !leftOpeneds.includes(index) && this.menuActive.leftOpeneds.push(index);
    },

    onMenuClose(index, indexPath) {
      this.menuActive.leftOpeneds = this.menuActive.leftOpeneds.filter(
        (item) => item !== index,
      );
    },

    routeChangeSetMenu(to) {
      const { matched } = to;
      const l = matched.length;
      const {
        name,
        meta: { roleId },
      } = matched[l - 2];

      this.menuActive = {
        ...this.menuActive,
        activeIndex: matched[1].name,
        leftActiveIndex: `${name}-${roleId}`,
      };

      // this.setActiveIndexCache();
    },
  },
};
</script>

<style lang="less" scoped>
.left-nav {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  // margin-right: 16px;
  width: 224px;
  height: 100%;
  padding-top: 16px;
  vertical-align: top;
  background-color: #fff;
  border-right: 1px solid #e9eaf1;
}

.content {
  box-sizing: border-box;
  // margin-left: 228px;
  height: 100%;
  overflow: auto;

  .view-area {
    max-width: 100%;
  }
}

.main {
  position: relative;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  overflow: auto;

  &__no-left-nav {
    /deep/ .content {
      margin: 0;
    }

    /deep/ .content-layout {
      padding: 25px 100px;
      margin-left: 0;
    }
  }
}

.no-frame {
  .menu {
    display: none;
  }

  .header {
    display: none;
  }

  .content {
    height: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .left-nav {
    display: none;
  }

  .main {
    width: 100%;
    height: 100%;
  }
}

.nav-area {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  justify-self: center;
  text-align: center;
}

.nav-list {
  flex: 1;
  overflow: auto;
}

.not-found-area {
  margin-left: 25px;
}

.top__menu {
  border-bottom: none;

  /deep/ li {
    position: relative;
    height: 48px;
    font-size: 16px;
    line-height: 48px;
    color: #333;
  }

  /deep/ & > .el-menu-item {
    border-bottom: none;
    transition: none;

    &.is-active {
      font-size: 18px;
      font-weight: bold;
      color: #2f75f6;
      border-bottom: none;

      &::after {
        position: absolute;
        bottom: 6px;
        left: 50%;
        width: 38px;
        height: 4px;
        content: '';
        background-color: @g-primary-color;
        border-radius: 3px;
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
