<template>
  <el-dialog
    v-if="visible"
    custom-class="update-dialog"
    :visible.sync="visible"
    width="525px"
    append-to-body
    :close-on-click-modal="false"
    :before-close="onBeforeClose"
  >
    <header class="update-dialog__header">
      <h2 class="header-name">未来校更新公告</h2>
      <time class="update-time">{{ updateTime }}</time>
    </header>
    <div class="content-body">
      <h4>更新内容:</h4>
      <div class="content-text" v-html="content"></div>
    </div>
  </el-dialog>
</template>

<script>
import { replaceStateByMemberId } from '@/fetchs/aischool/ReplaceFacade';

import { dateFormat } from '@/common/scripts/utils/date';

export default {
  name: 'update-content-dialog',

  data() {
    return {
      visible: false,
      title: '',
      content: '',
      updateTime: '',
    };
  },

  created() {
    this.getUpdateContent();
  },

  methods: {
    getUpdateContent() {
      replaceStateByMemberId().then(res => {
        const { title = '', content = '', replaceDate } = res;

        if (title && content) {
          this.visible = true;
        }
        this.updateTime = replaceDate
          ? dateFormat(new Date(replaceDate)).replace(
              /(\d{4})-(\d{2})-(\d{2})/,
              '$1年$2月$3日'
            )
          : '';
        this.title = title;
        this.content = content;
      });
    },

    onBeforeClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .update-dialog {
  .el-dialog__headerbtn {
    top: 14px;
    right: 14px;
    font-size: 25px;
    z-index: 1;
  }
  &__header {
    margin: -60px -20px 0;
    padding-top: 40px;
    height: 102px;
    text-align: center;
    background: url('~@/assets/dialog/update-dialog-bg.png') no-repeat;
    background-size: 100%;
    .header-name {
      margin-bottom: 8px;
      color: @g-primary-color;
      font-size: 24px;
      font-weight: bold;
    }
    .update-time {
      color: #7b8fb1;
      font-size: 16px;
    }
  }
  .content-body {
    padding: 15px 0 0;
    color: #7b8fb1;
    font-size: 14px;
    > h4 {
      font-weight: bold;
    }
  }
}
</style>
