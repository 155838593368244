<template>
  <div class="qrcode">
    <el-popover
      ref="icon-popover"
      popper-class="icon-popover"
      placement="right"
      width="308"
      trigger="hover"
    >
      <ul class="qrcode-list">
        <li
          v-for="{ title, imgUrl, alt } in qrcodeList"
          :key="title"
          class="item"
        >
          <p class="name">{{ title }}</p>
          <img class="img" :src="imgUrl" :alt="alt" />
        </li>
      </ul>
    </el-popover>

    <p v-popover:icon-popover class="qrcode-title">
      <img
        class="qrcode-icon"
        src="@/assets/side-bar/icon-qrcode.png"
        alt="icon"
      />
      {{ qrcodeTitle }}
    </p>
  </div>
</template>

<script>
import { getHostData } from './enums';

export default {
  name: 'IconPopover',

  data() {
    return {
      qrcodeTitle: '',
      qrcodeList: [],
    };
  },

  created() {
    const { qrcodeTitle, qrcodeList } = getHostData();

    this.qrcodeTitle = qrcodeTitle;
    this.qrcodeList = qrcodeList;
  },
};
</script>

<style lang="less" scoped>
.qrcode {
  flex-shrink: 0;
  padding: 8px 0;
  text-align: center;

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #7b8fb1;
    cursor: pointer;
  }
}
</style>

<style lang="less">
.icon-popover.icon-popover {
  width: auto !important;
  border: none;
  border-radius: 5px;

  .qrcode-list {
    display: flex;
    padding: 4px 6px 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      & + .item {
        margin-left: 5px;
      }

      .img {
        width: 80px;
        height: 80px;
      }

      .name {
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        color: @primary-text-2;
      }
    }
  }
}

.icon-popover.el-popover[x-placement^='right'] .popper__arrow {
  border: none;
  // &::after {
  //   content: none;
  // }
}
</style>
