<template>
  <div
    id="app"
    ref="app"
    v-loading.fullscreen.lock="loading"
    class="layout"
    :class="{ 'no-frame': noFrame }"
  >
    <slot name="header"></slot>
    <slot></slot>

    <!-- 更新内容弹窗 -->
    <UpdateContentDialog v-if="memType === 2" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import { IS_YUCAI } from '@/common/enums/hostConfig';
import { noviceGuideStateByMemberId } from '@/fetchs/aischool/NoviceGuideFacade';
import appStore, { SET_LOADING_STATUS } from '@/store/modules/app';
import guideStore, { SET_GUIDE_DIALOG_VISIBLE } from '@/store/modules/guide';

import UpdateContentDialog from './UpdateContentDialog.vue';

const noFrameRoutes = {
  trainingLessonPlay: true,
  trainingLessonExpand: true,
  campus_reader_play: true,
  liveroom: true,
  videoroom: true,
};

export default {
  name: 'Layout',

  components: {
    UpdateContentDialog,
  },

  props: {
    guideDialogVisible: {
      type: Boolean,
    },
  },

  data() {
    return {
      // 无框架页面判定
      noFrame: false,

      dialogVisible: false,
      isYucai: IS_YUCAI,
    };
  },

  computed: {
    ...mapState(appStore.name, {
      loading: (state) => state.loading,
    }),

    memType() {
      return this.$getCache('memType');
    },
  },

  watch: {
    $route(to, from) {
      this.noFrame = noFrameRoutes[this.$route.name];
    },

    // guideDialogVisible(visible) {
    //   this.dialogVisible = visible;
    // },
  },

  created() {
    this.memType === 2 && this.getNoticeState();
  },

  mounted() {
    this.app = this.$refs.app;
    this.noFrame = noFrameRoutes[this.$route.name];
    if (!this.app) return;
  },

  methods: {
    ...mapMutations(appStore.name, {
      setLoadingStatus: SET_LOADING_STATUS,
    }),

    ...mapMutations(guideStore.name, {
      setGuideDialogVisible: SET_GUIDE_DIALOG_VISIBLE,
    }),

    getNoticeState() {
      noviceGuideStateByMemberId().then((res) => {
        res && this.setGuideDialogVisible(true);
      });
    },
  },
};
</script>

<style lang="less" src="../global.less"></style>

<style lang="less" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  height: 100%;
  overflow: hidden;
  background-color: @g-bg-body;
}
</style>
