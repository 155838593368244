/*
 * @Author: your name
 * @Date: 2021-12-17 16:39:30
 * @LastEditTime: 2024-04-02 11:52:59
 * @LastEditors: linghongxiang linghongxiang@mistong.com
 * @Description: 直播开课
 * @FilePath: \kkl-online-school\src\router\modules\liveCourse.js
 */

export const LiveCourseMenuName = '直播教学';
import playbackIcon from '@/assets/nav-icon/直播回放-1@2x.png';
import playbackActiveIcon from '@/assets/nav-icon/直播回放-2@2x.png';
import courseIcon from '@/assets/nav-icon/直播开课-1@2x.png';
import courseActiveIcon from '@/assets/nav-icon/直播开课-2@2x.png';

export default [
  {
    path: '/liveCourse',
    name: 'teacherLiveCourse',
    component: () => import('@/views/liveCourse/entry.vue'),
    meta: {
      role: 'livecenter',
      roleId: '11111',
      menuName: LiveCourseMenuName,
    },
    children: [
      {
        path: 'courseSchedule',
        name: 'courseSchedule',
        role: 'livecenter.course',
        roleId: '11112',
        redirect: '/liveCourse/courseSchedule/index',
        component: () => import('@/views/liveCourse/courseSchedule/entry.vue'),
        meta: {
          menuName: '直播课表',
          icon: courseIcon,
          activeIcon: courseActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'courseScheduleIndex',
            role: 'livecenter.course.wait',
            roleId: '11115',
            component: () =>
              import('@/views/liveCourse/courseSchedule/index/entry.vue'),
            meta: {
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'courseManage',
        name: 'courseManage',
        role: 'livecenter.course',
        roleId: '11112',
        redirect: '/liveCourse/courseManage/index',
        component: () => import('@/views/liveCourse/courseManage/entry.vue'),
        meta: {
          menuName: '课程管理',
          icon: courseIcon,
          activeIcon: courseActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'courseManageIndex',
            role: 'livecenter.course.live',
            roleId: '11113',
            component: () =>
              import('@/views/liveCourse/courseManage/index/entry.vue'),
            meta: {
              hideInMenu: true,
            },
          },
          {
            path: 'lesson',
            name: 'courseLessonDetail',
            role: 'livecenter.course',
            roleId: '11112',
            component: () =>
              import('@/views/liveCourse/courseManage/lesson/entry.vue'),
            meta: {
              hideInMenu: true,
            },
          },
          {
            path: 'readme',
            name: 'courseReadme',
            component: () =>
              import('@/views/liveCourse/courseManage/readme/entry.vue'),
            meta: {
              hideInMenu: true,
            },
            children: [],
          },
        ],
      },
      {
        path: 'playbackList',
        name: 'playbackList',
        role: 'livecenter.course',
        roleId: '11112',
        redirect: '/liveCourse/playbackList/index',
        component: () => import('@/views/liveCourse/playbackList/entry.vue'),
        meta: {
          menuName: '直播回放',
          icon: playbackIcon,
          activeIcon: playbackActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'playbackListIndex',
            role: 'livecenter.course.video',
            roleId: '11114',
            component: () =>
              import('@/views/liveCourse/playbackList/index/entry.vue'),
            meta: {
              hideInMenu: true,
            },
            children: [],
          },
          {
            path: 'playbackRoom',
            name: 'playbackRoom',
            role: 'livecenter.course.video',
            roleId: '11114',
            component: () =>
              import('@/views/liveCourse/playbackList/playbackRoom/entry.vue'),
            meta: {
              hideInMenu: true,
              hasSideMenu: false,
            },
            children: [],
          },
        ],
      },
    ],
  },
];
