<template>
  <div
    ref="questionRichText"
    class="question-rich-text"
    :class="{ inline }"
    v-html="content"
  ></div>
</template>

<script>
export default {
  name: 'QuestionRichText',
  props: {
    content: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    content() {
      this.renderMath();
    },
  },
  mounted() {
    this.renderMath();
  },
  methods: {
    async renderMath() {
      this.$nextTick(() => {
        window.renderMathInElement(this.$refs.questionRichText, {
          delimiters: [
            { left: '$$', right: '$$', display: true },
            { left: '$', right: '$', display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: false },
          ],
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.question-rich-text {
  line-height: 20px;
  //   font-size: 14px;
  color: #333;

  /deep/ img {
    max-width: 100%;
    height: auto !important;
  }

  &.inline {
    display: inline;
  }
}
</style>
