export function getRequest(fullPath) {
  let url = fullPath;
  let jsonList = {};
  if (url.indexOf('?') > -1) {
    let str = url.slice(url.indexOf('?') + 1);
    let strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      jsonList[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]); //如果出现乱码的话，可以用decodeURI()进行解码
    }
  }
  return jsonList;
}
