const getServiceName = str => `aischool.NoviceGuideFacade.${str}`;

/**
 * 是否显示新手引导
 * @returns 
 */
export const noviceGuideStateByMemberId = () => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('noviceGuideStateByMemberId'),
      param: {},
    },
    noHint: true
  });
};