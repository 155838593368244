export const ADD_HOMEWORK_LESSON_KNOWLEDGE = 'ADD_HOMEWORK_LESSON_KNOWLEDGE'; // 添加视频知识点
export const REMOVE_HOMEWORK_LESSON_KNOWLEDGE =
  'REMOVE_HOMEWORK_LESSON_KNOWLEDGE'; // 移除视频知识点
export const ADD_HOMEWORK_LESSON = 'ADD_HOMEWORK_LESSON'; // 添加视频
export const ADD_HOMEWORK_LESSON_COMMODITY = 'ADD_HOMEWORK_LESSON_COMMODITY'; // 添加视频商品
export const REMOVE_HOMEWORK_LESSON = 'REMOVE_HOMEWORK_LESSON'; // 移除视频
export const REMOVE_ALL_HOMEWORK_LESSON = 'REMOVE_ALL_HOMEWORK_LESSON'; // 移除所有视频
export const ADD_HOMEWORK_LESSON_INFO = 'ADD_HOMEWORK_LESSON_INFO'; // 添加视频信息
export const REMOVE_HOMEWORK_LESSON_INFO = 'REMOVE_HOMEWORK_LESSON_INFO'; // 移除视频信息
export const PLUS_HOMEWORK_COMMODITY_LESSON_LENGTH =
  'PLUS_HOMEWORK_COMMODITY_LESSON_LENGTH'; // 增加课程包选中课节数
export const MINUS_HOMEWORK_COMMODITY_LESSON_LENGTH =
  'MINUS_HOMEWORK_COMMODITY_LESSON_LENGTH'; // 减少课程包选中课节数
export const INIT_HOMEWORK_ALL_COMMODITY_LESSON_LENGTH =
  'INIT_HOMEWORK_ALL_COMMODITY_LESSON_LENGTH'; // 初始化课程包选中课节数
export const CHANGE_HOMEWORK_LESSON = 'CHANGE_HOMEWORK_LESSON'; // 替换课程包
export const ADD_CURRENT_HOMEWORK_LESSON = 'ADD_CURRENT_HOMEWORK_LESSON'; // 增加当前课程包
