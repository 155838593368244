/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2018-05-29 17:15:50
 */

import { requestFn } from '../utils';

const getServeName = (str) => `aischool.TeacherV2Facade.${str}`;

/**
 * 根据用户名获取手机号接口
 * @export
 * @returns promise
 */
export function getMobile({ loginName }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.MemberInfoFacade.getMobile',
      param: {
        teacherDTO: {
          loginName,
        },
      },
    },
  });
}

/**
 * 5.老师是否有教师管理权限
 * @export
 * @returns promise
 */
export function getAA() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.getAA',
      param: {},
    },
  });
}

/**
 * 根据手机号获取登录名
 * @export
 * @returns promise
 */
export function getLoginName({ mobileNO }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.MemberInfoFacade.getLoginName',
      param: {
        teacherDTO: {
          mobileNO,
        },
      },
    },
  });
}

/**
 * 保存老师信息接口
 * @export
 * @returns promise
 */
export function saveTeacherAuth({
  admin,
  dutyIdList,
  memberId,
  mobileNO,
  schoolId,
  loginName,
  firstName,
  subjectId,
  schoolName,
  manageYear,
  teachClass,
  manageClassList,
  subjectName,
  phase,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.saveTeacherAuth',
      param: {
        teacherAuthDTO: {
          admin,
          dutyIdList,
          memberId,
          mobileNO,
          schoolId,
          loginName,
          firstName,
          subjectId,
          schoolName,
          manageYear,
          teachClass,
          manageClassList,
          subjectName,
          phase,
        },
      },
    },
  });
}

/**
 * 修改老师信息接口
 * @export
 * @returns promise
 */
export function updateTeacherAuth({
  id,
  admin,
  dutyIdList,
  memberId,
  mobileNO,
  schoolId,
  loginName,
  firstName,
  subjectId,
  schoolName,
  manageYear,
  teachClass,
  manageClassList,
  subjectName,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.updateTeacherAuth',
      param: {
        teacherAuthDTO: {
          id,
          admin,
          dutyIdList,
          memberId,
          mobileNO,
          schoolId,
          loginName,
          firstName,
          subjectId,
          schoolName,
          manageYear,
          teachClass,
          manageClassList,
          subjectName,
        },
      },
    },
  });
}

/**
 * 获取老师信息接口
 * @export
 * @returns promise
 */
export function getTeacherAuthAndMemberInfo(teacherId) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.getTeacherAuthAndMemberInfo',
      param: {
        teacherId,
      },
    },
  });
}

/**
 * 删除老师
 */
export function deleteTeacherAuth({ teacherAuthId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.deleteTeacherAuth',
      param: {
        teacherAuthId,
      },
    },
  });
}

/**
 * 获取老师列表
 */
export function listTeacherAuth({
  dutyId,
  subjectId,
  keyword,
  year = null,
  phase = null,
  pageIndex = 1,
  pageSize = 30,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    noHint,
    data: {
      serviceName: 'aischool.TeacherV2Facade.listTeacherAuth',
      param: {
        teacherListParamDTO: {
          phase,
          year,
          dutyId,
          subjectId,
          keyword,
        },
        pageDTO: {
          pageIndex,
          pageSize,
        },
      },
    },
  });
}

/*
 * 根据学校、入学年份、班级查询班级任课老师情况
 * @export
 * @returns promise
 */
export function queryClassTeacher({ schoolId, enrollmentYear, classParam }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryClassTeacher',
      param: {
        schoolId,
        enrollmentYear,
        classParam,
      },
    },
  });
}

/**
 * 班级详情年级级联筛选接口
 * @export
 * @returns promise
 */
export function queryGradeAndClassByTeacherId() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryGradeAndClassByTeacherId',
    },
  });
}

/** 
 * 查询当前用户 作业/德育/家长学院 布置范围 
 * @param { Number } param.type [
1:班级，2:小组，其他任何值或者null值为全部  ]
 */
export const queryReleaseClassesPermission = (param) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryReleaseClassesPermission',
      param,
    },
  });
};

/**
 * 老师具有管理权限的班级列表
 * @export
 * @returns promise
 */
export function queryManagerGradeAndClassByAuth() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryManagerGradeAndClassByAuth',
    },
  });
}

/**
 * rap: http://rap.ikuko.com/workspace/myWorkspace.do?projectId=118#1011
 * 老师名下班级
 * @export
 * @returns promise
 */
export function queryGradeAndClassHasStudentByTeacherId() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.TeacherV2Facade.queryGradeAndClassHasStudentByTeacherId',
    },
  });
}

/*
 * 判断用户是否有开课啦精选共享权限
 * @export
 * @returns promise
 */
export function isTeacherHaveKKLSharePower() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.isTeacherHaveKKLSharePower',
    },
  });
}

/**
 * .获取权限内老师列表
 * @export
 * @returns promise
 */
export function listTeacher() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.listTeacher',
      param: {},
    },
  });
}

/**
 * 添加任课老师
 * @export
 * @returns promise
 */
export function addTeach({ teacherId, subjectId, schoolId, year, schClass }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.addTeach',
      param: {
        bparam: {
          teacherId,
          subjectId,
          schoolId,
          year,
          schClass,
        },
      },
    },
  });
}

/**
 * 添加班主任
 * @export
 * @returns promise
 */
export function addManage({ teacherId, schoolId, year, schClass }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.addManage',
      param: {
        bparam: {
          teacherId,
          schoolId,
          year,
          schClass,
        },
      },
    },
  });
}

/**
 * [searchTeachAuth 授课班级教师权限搜索]
 * @param  {Number} [pageCurrent=1] [description]
 * @param  {Number} [pageSize=10]   [description]
 * @param  {[type]} [keyword}]      [description]
 * @return {[type]}                 [description]
 */
export function searchTeachAuth({
  pageCurrent = 1,
  pageSize = 10,
  keyword,
  subjectId,
  year,
  schClass,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.searchTeachAuth',
      param: {
        bparam: {
          pageCurrent,
          pageSize,
          keyword,
          subjectId,
          year,
          schClass,
        },
      },
    },
  });
}

/**
 * [searchTeachAuth 管理班级教师权限搜索]
 * @param  {Number} [pageCurrent=1] [description]
 * @param  {Number} [pageSize=10]   [description]
 * @param  {[type]} [keyword}]      [description]
 * @return {[type]}                 [description]
 */
export function searchManageAuth({
  pageCurrent = 1,
  pageSize = 10,
  keyword,
  manageYear,
  manageClass,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.searchManageAuth',
      param: {
        bparam: {
          pageCurrent,
          pageSize,
          keyword,
          manageYear,
          manageClass,
        },
      },
    },
  });
}

/**
 * [delTeach 解除任课老师]
 * @param  {[type]} teacherId [description]
 * @param  {[type]} schoolId  [description]
 * @param  {[type]} year      [description]
 * @param  {[type]} schClass  [description]
 * @param  {[type]} subjectId [description]
 * @return {[type]}           [description]
 */
export function delTeach({ teacherId, schoolId, year, schClass, subjectId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.delTeach',
      param: {
        bparam: {
          teacherId,
          schoolId,
          year,
          schClass,
          subjectId,
        },
      },
    },
  });
}

/**
 * @description 获取当前用户可以看到的小组信息
 */
export function queryTeacherGroupByTeacherId() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryTeacherGroupByTeacherId',
      param: {},
    },
  });
}

/**
 * @description 获取全部小组信息，有教师角色的用户才能看到
 */
export function queryAllGroupByTeacherId() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.TeacherV2Facade.queryAllGroupByTeacherId',
    },
  });
}

/**
 * @description 查询当前用户 作业/德育/家长学院 布置范围（不校验权限）
 * @param {*} type 1:班级，2:小组，其他任何值或者null值为全部
 */
export const queryGradeAndClasses = (type) => {
  const serviceName = getServeName('queryGradeAndClasses');
  const param = { type };
  return requestFn(serviceName, param);
};

export const getLikeTeacherNameByMemberId = (teacherName) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.MemberInfoFacade.getLikeTeacherNameByMemberId',
      param: {
        teacherName,
      },
    },
  });
};
