const state = {
  curPageTitle: '自选试题',
  sourceType: 0, // 作业布置类型 1为自动  0为手动  2 为错题本
  version: 1, // 当前题库的版本，1为老版，2为新版，3为校本资源作业；其中1，2是同一个意思都为学科作业
  isResetCache: false, // 是否重置缓存 需要重置为true 重置后改为false
  resetCacheKey: '',
  subject: {
    // 科目信息
    id: null,
    name: '',
    knowledgeDisabled: null,
  },
  subjectQuestionTypeList: [], // 所选科目包含的试题类型列表
  homeworkStatus: false, // 作业是否发布成功
  subjectList: [],
  scheme: {
    id: null, // 作业方案id
    title: '', // 作业方案title
    shareStatus: false, // 是否分享作业方案
    termId: 3, // 学段id
    degree: 0, // 难度（0:无；1:基础；2:中等；3:较难；）
    planStatus: 1, // 状态（0:未上架；1:已上架）
    description: '', // 作业方案描述
    releaseCount: 0, // 方案被使用次数
    shareTypes: [], // 分享类型
    knowledgeNames: [], // 作业方案知识点列表
    workCreator: false, // 当前用户是否为该方案制作者
    abnormalityQuestionNum: 0, // 不正常试题数
    collection: false,
  },
  modifyScheme: false, // 使用作业方案但是没有更改 true 为修改了
  cart: {
    lessonIds: [], // 视频作业id list
    lessonMap: {}, // 视频信息map
    lessonCommodityMap: {}, // 商品和视频映射map
    selectedCommodityLessonLengthMap: {}, // 所有 有被选中课节的课程包中选中课节的个数map
    lessonKnowledgeMap: {}, // 视频知识点map
    questionIds: [], // 试题作业id list
    questionMap: {}, // 试题信息map
    selectedKnowledgeQuestionLengthMap: {}, // 所有 有被选中试题的知识点中选中试题的个数map
    listenIds: [], // 听口试卷idlist
    listenMap: {}, // 听口试卷信息
    schoolPaperIds: [], // 学校试卷idlist
    schoolPaperMap: {}, // 学校试卷map
    orderStatus: false,
  },

  questionList: [],
  finish: {
    id: null,
    type: 1,
    title: '',
    endTime: '',
    lessonSum: 0,
    precisionLessonSum: 0, // 精讲视频个数（重难点突破方案中的视频数量）
    groupList: [],
    classList: [],
    batch: false,
    failIdList: [],
    successList: [],
    description: '',
    questionSum: 0,
    isTimed: false,
    publishTime: '',
    reviewEndTime: '', // 主观题批改截止时间
    subjectiveCount: 0, // 主观题个数
    totalListenQuestion: 0,
    totalSchoolPaperQuestion: 0, // 校本作业个数
  },
  isTabTipShow: true, // 是否显示智能组卷布置时数量不够的提示
};
export default state;
