<template>
  <div class="k-breadcrumb">
    <!-- separator-class="el-icon-arrow-right" -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(item, index) in title"
        :key="index"
        :to="item.clickFn ? '' : getItemTo(item.to)"
        :replace="!!item.replace"
        @click.native="item.clickFn && item.clickFn()"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
/**
 * 面包屑组件
 * @displayName KBreadcrumb组件
 */
export default {
  name: 'KBreadcrumb',

  props: {
    /**
     * @description 面包屑数组
     * @typedef {Object<string, any>} ITitle
     * @property {String} name 显示文案
     * @property {Object} to 路由跳转对象，规则同 el-breadcrumb （即 vue-router 的 to）
     * @property {Boolean} replace 同el-breadcrumb的 replace
     * @property {Function} clickFn 点击面包屑的执行函数，有此参数时，to失效
     *
     * @type {Array<ITitle>} title
     */

    title: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    jump(item, query) {
      if (item.url) {
        this.$router.push({
          query,
          name: item.url,
        });
        return;
      }
      this.$router.push({
        query,
        path: item.path,
      });
    },

    getItemTo(to) {
      if (!to) {
        return;
      }

      if (typeof to === 'string') {
        return to;
      }

      const { name, path, ...other } = to;
      let params = other;
      if (name) {
        params = { ...params, name };
      }

      if (path) {
        params = { ...params, path };
      }
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.k-breadcrumb {
  padding: 20px;
  background-color: #fff;

  ::v-deep {
    .el-breadcrumb {
      line-height: 20px;
    }

    .el-breadcrumb__inner {
      font-size: @font-size-base;
      color: #999;

      &.is-link,
      &a {
        font-weight: normal;
      }
    }

    .el-breadcrumb__separator {
      margin: 0 5px 0 3px;
      font-weight: normal;
      color: #999;
    }

    .el-breadcrumb__item:last-child {
      .el-breadcrumb__inner {
        color: @primary-text;
        // font-weight: bold;
        &:hover,
        a,
        a:hover {
          color: @primary-text;
        }
      }
    }
  }
}
</style>
