import automatic from '@/store/modules/homework/mutations/automatic.js';
import lesson from './lesson.js';
import listen from './listen.js';
import question from './question.js';
import homework from './homework.js';
import finish from './finish.js';
import scheme from './scheme.js';
import subject from './subject.js';
import schoolPaper from './schoolPaper.js';

export default {
  ...scheme,
  ...finish,
  ...lesson,
  ...listen,
  ...subject,
  ...homework,
  ...question,
  ...schoolPaper,
  ...automatic,
};
