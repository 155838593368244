/* eslint-disable simple-import-sort/imports */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import '@/common/components/ElementUI';
import './global.less';

import EventBus from 'events';
import LayerPrompt from '@/common/components/LayerPrompt';
import '@/common/components/LayerPrompt/observer';
import difficulty from '@/common/filters/difficulty';

import { install as bigDataInstall } from '@/common/scripts/utils/business/bigDataLog';
import { localStorageTools } from '@/common/scripts/utils/localStorage';

import { setStore } from '@/common/scripts/utils/business/store';

import skynetVue from '@kkl/skynet-vue';
import * as shenyu from '@kkl/shenyu-api';
import { errorHandler } from '@/common/scripts/utils/ajax';

// 通用工具
import KKL from './kkl';
import App from './app.vue';
import router from './router';
import store from './store';
import cutString from '@/common/filters/cutString';
import { localeTimeFormat } from '@/common/scripts/utils/date';
import {
  tableHeaderCellStyle,
  tableCellStyle,
} from '@/common/scripts/utils/tableStyle';

import { setGlobalComponents } from '@/components/index';

import VueClipboard from 'vue-clipboard2';

import '@/directives/index';

Vue.use(VueClipboard);

skynetVue(Vue);
sync(store, router);
Vue.prototype.$eventBus = new EventBus();
window.$_EVENT = Vue.prototype.$eventBus;
Vue.prototype.$config = ENV_CONFIG;

Vue.prototype.tableCellStyle = tableCellStyle; // 新版表格 单元格样式
Vue.prototype.tableHeaderCellStyle = tableHeaderCellStyle; // 新版表格 表头单元格样式

const isDebugMode = process.env.NODE_ENV !== 'production';
Vue.config.debug = isDebugMode;
Vue.config.devtools = isDebugMode;
Vue.config.productionTip = isDebugMode;

LayerPrompt.install();
LayerPrompt.instanceMultiple();

setStore(store);
window.KKL = KKL;

Vue.use(localStorageTools);

import lifecycle from 'page-lifecycle';
Vue.prototype.$lifecycle = lifecycle;
window.lifecycle = lifecycle;

// 添加大数据相关脚本
bigDataInstall(Vue, router);

window.$shenyu = shenyu.init({
  env: process.env.npm_config_env,
  mock: process.env.npm_config_mock,
  successCallback(data) {},
  errorCallback(data) {
    errorHandler(data);
  },
});
Vue.prototype.$sApi = shenyu.api;
window.$sApi = shenyu.api;

window.app = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});

setGlobalComponents(Vue);
Vue.filter('cutString', cutString);
Vue.filter('localeTimeFormat', localeTimeFormat);
Vue.filter('difficulty', difficulty);

// localStore 的兼容库
import storeJs from 'store';
window.$_STORE = storeJs;

window.FormulaRender = function (DOM) {
  window.renderMathInElement(DOM, {
    delimiters: [
      { left: '$$', right: '$$', display: true },
      { left: '$', right: '$', display: false },
      { left: '\\(', right: '\\)', display: false },
      { left: '\\[', right: '\\]', display: false },
    ],
  });
};

export const { $eventBus } = Vue.prototype;
