<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="432px"
    :show-close="false"
    :close-on-click-modal="false"
    :before-close="onClose"
  >
    <h4 class="tw-text-center tw-text-[20px] tw-font-bold tw-text-[#333]">
      为了数据安全，请完成身份验证
    </h4>
    <div class="tw-mt-[40px] tw-text-center tw-text-kgray-500">
      即将给您的手机号{{ hiddenMobile }}发送验证码
    </div>
    <el-form
      ref="formRef"
      class="check-form tw-mx-auto tw-mt-[8px] tw-w-[312px]"
      :model="form"
    >
      <el-form-item prop="code">
        <div
          class="tw-flex tw-h-[48px] tw-items-center tw-overflow-hidden tw-rounded-[26px] tw-bg-[#FAFAFA] tw-px-[20px]"
        >
          <el-input
            v-model="form.code"
            autocomplete="off"
            placeholder="请输入验证码"
            :minlength="codeLength"
            :maxlength="codeLength"
          ></el-input>

          <el-button
            v-if="!isCountDown"
            type="text"
            class="tw-shrink-0"
            @click="getCode"
            >获取验证码</el-button
          >
          <CountDown v-else :time="time" format="SS" @finish="onFinish">
            <template #default="{ timeData }">
              <span class="tw-shrink-0 tw-whitespace-nowrap tw-text-[#2f75f6]"
                >{{ getCountTime(timeData) }}s</span
              ></template
            >
          </CountDown>
        </div>
        <div
          class="tw-mt-[8px] tw-h-[20px] tw-pl-[27px] tw-leading-[1] tw-text-danger"
        >
          <template v-show="checkError">{{ checkError }}</template>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="-tw-mt-[40px] tw-pb-[20px] tw-text-center">
        <el-button class="tw-w-[150px]" round @click="onClose">取 消</el-button>
        <el-button
          class="tw-w-[150px]"
          :class="{ 'is-disabled': !canSubmit }"
          type="primary"
          :loading="isSubmit"
          :disabled="isSubmit"
          round
          @click="onConfirm"
          >确 定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
export default {
  name: 'MentalArchiveCheckDialog',
};
</script>
<script setup lang="ts">
import { Message } from 'element-ui';
import { computed, ref } from 'vue';

import { DAY, HOUR, MINUTE, SECOND } from '@/common/scripts/utils/time';
import CountDown from '@/components/base/count-down/index.vue';
import {
  checkMobileCodeByMentalArchive,
  sendMobileCodeByMentalArchive,
} from '@/fetchs/aischool/MemberCmdFacade';
import store from '@/store/modules/user';

const codeLength = 6;

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update:show', 'success', 'cancel']);

const dialogVisible = ref(true);
const form = ref({
  code: '',
});
const formRef = ref();
const rules = ref({
  code: [
    { required: true, message: '验证码不能为空' },
    { pattern: /^\d{6}$/, message: `验证码必须为${codeLength}位数字值` },
  ],
});

const isGettedCode = ref(false);
const time = ref(0);
const isCountDown = ref(false);
const checkError = ref('');
const isSubmit = ref(false);

const mobile = computed(() => store.state.mobileNo);

const hiddenMobile = computed(() =>
  mobile.value.replace(/(\d{3})\d{4}(\d+)/, '$1****$2'),
);

const canSubmit = computed(() => /^\d{6}$/.test(form.value.code));

const getCode = () => {
  if (isCountDown.value) {
    return;
  }
  sendMobileCodeByMentalArchive()
    .then(({ time: t = 120 }) => {
      isGettedCode.value = true;
      Message.success('短信验证码已发送');
      isCountDown.value = true;
      time.value = t * 1000;
    })
    .catch((e) => {
      Message.error(e.message);
      isGettedCode.value = false;
    });
};
const getCountTime = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    (days * DAY + hours * HOUR + minutes * MINUTE + seconds * SECOND) / SECOND
  );
};

const onFinish = () => {
  isCountDown.value = false;
};

const onClose = () => {
  emits('update:show', false);
  emits('cancel');
};

const onConfirm = () => {
  if (isSubmit.value) return;

  if (!canSubmit.value) {
    checkError.value = '验证码错误';
    return;
  }

  isSubmit.value = true;
  checkMobileCodeByMentalArchive(form.value.code)
    .then(() => {
      checkError.value = '';
      emits('update:show', false);
      emits('success');
    })
    .catch((e: any) => {
      checkError.value = e.message;
    })
    .finally(() => {
      isSubmit.value = false;
    });
};
</script>

<style lang="less" scoped>
.check-form {
  ::v-deep {
    .el-input__inner {
      padding-left: 5px;
      background-color: transparent;
      border: none;
    }

    .el-form-item__error {
      padding-top: 8px;
      margin-left: 27px;
      font-size: 14px;
      color: #ff0015;
    }
  }
}
</style>
