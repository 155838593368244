import vacationIcon from '@/assets/nav-icon/假期数据1@2x.png';
import vacationActiveIcon from '@/assets/nav-icon/假期数据2@2x.png';
import wholeStationDataIcon from '@/assets/nav-icon/全站数据报告1@2x.png';
import wholeStationDataActiveIcon from '@/assets/nav-icon/全站数据报告2@2x.png';
import panelIcon from '@/assets/nav-icon/可视化大屏1@2x.png';
import panelActiveIcon from '@/assets/nav-icon/可视化大屏2@2x.png';
import kklDataIcon from '@/assets/nav-icon/开课啦数据报告1@2x.png';
import kklDataActiveIcon from '@/assets/nav-icon/开课啦数据报告2@2x.png';
import { IS_YUCAI } from '@/common/enums/hostConfig';
import { NAV_MENU_VISUAL_SCREEN } from '@/stat-id/data-center';

export default [
  {
    path: '/data',
    name: 'data',
    redirect: '/data/dataCenter',
    component: () => import('@/views/data/entry.vue'),
    meta: {
      role: 'dataCenter',
      roleId: '700',
      menuName: '数据管理',
    },
    children: [
      {
        path: 'dataCenter',
        name: 'dataCenter',
        redirect: '/data/dataCenter/index',
        component: () => import('@/views/data/data-center/entry.vue'),
        meta: {
          role: 'dataCenter.wholeStationData',
          roleId: '701',
          menuName: '数据报告',
          icon: wholeStationDataIcon,
          activeIcon: wholeStationDataActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'dataCenterIndex',
            component: () => import('@/views/data/data-center/entry.vue'),
            meta: {
              role: 'dataCenter.wholeStationData.list',
              roleId: '702',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'kklData',
        name: 'kklData',
        redirect: '/data/kklData/index',
        component: () => import('@/views/data/kkl-data/entry.vue'),
        meta: {
          role: 'dataCenter.KKLData',
          roleId: '703',
          menuName: '开课啦数据报告',
          icon: kklDataIcon,
          activeIcon: kklDataActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'kklDataIndex',
            component: () => import('@/views/data/kkl-data/entry.vue'),
            meta: {
              role: 'dataCenter.KKLData.list',
              roleId: '704',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'dataPanel',
        name: 'panel',
        redirect: '/data/dataPanel/index',
        component: () => import('@/views/data/data-panel/entry.vue'),
        meta: {
          role: 'dataCenter.panel',
          roleId: '705',
          menuName: '数据大屏',
          statId: NAV_MENU_VISUAL_SCREEN,
          icon: panelIcon,
          activeIcon: panelActiveIcon,
          // outLink: '',
        },
        children: [
          {
            path: 'index',
            name: 'dataPanelIndex',
            component: () => import('@/views/data/data-panel/entry.vue'),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'evaluationDataPanel',
        name: 'evaluationDataPanel',
        redirect: '/data/evaluationDataPanel/index',
        component: () => import('@/views/data/evaluation-data-panel/entry.vue'),
        meta: {
          role: 'dataCenter.evaluation',
          roleId: '11019',
          menuName: '测评数据大屏',
          icon: panelIcon,
          activeIcon: panelActiveIcon,
          // outLink: '',
        },
        children: [
          {
            path: 'index',
            name: 'evaluationDataPanelIndex',
            component: () =>
              import('@/views/data/evaluation-data-panel/entry.vue'),
            meta: {
              role: 'dataCenter.evaluation.list',
              roleId: '11020',
              hideInMenu: true,
            },
          },
        ],
      },
      // 数据大屏
      {
        path: 'dataV',
        name: 'bigDataCenterDataV',
        redirect: '/data/dataV/main',
        component: () => import('@/views/data/dataV/entry.vue'),
        meta: {
          role: 'dataCenter.panel2',
          roleId: '11151',
          menuName: '数据大屏',
          hasSubmenu: true,
          icon: panelIcon,
          activeIcon: panelActiveIcon,
        },
        children: [
          {
            path: 'main',
            name: 'bigDataCenterDataVMain',
            redirect: '/data/dataV/main/index',
            component: () => import('@/views/data/dataV/main/entry.vue'),
            meta: {
              menuName: '主数据大屏',
              role: 'dataCenter.panel2.mainyyc',
              roleId: '11152',
            },
            children: [
              {
                path: 'index',
                name: 'bigDataCenterDataVMainIndex',
                component: () => import('@/views/data/dataV/main/entry.vue'),
                meta: {
                  hideInMenu: true,
                },
                children: [],
              },
            ],
          },
          {
            path: 'psychology',
            name: 'bigDataCenterDataVPsychology',
            redirect: '/data/dataV/psychology/index',
            component: () => import('@/views/data/dataV/psychology/entry.vue'),
            meta: {
              menuName: '心理数据大屏',
              role: 'dataCenter.panel2.psyyyc',
              roleId: '11153',
            },
            children: [
              {
                path: 'index',
                name: 'bigDataCenterDataVPsychologyIndex',
                component: () =>
                  import('@/views/data/dataV/psychology/entry.vue'),
                meta: {
                  hideInMenu: true,
                },
                children: [],
              },
            ],
          },
        ],
      },
      {
        path: 'yucaiVacationData',
        name: 'yucaiVacationData',
        redirect: '/data/yucaiVacationData/homework',
        component: () => import('@/views/data/yucai-vacation-data/entry.vue'),
        meta: {
          role: 'dataCenter.ycSummerHomework',
          roleId: '11173',
          menuName: IS_YUCAI ? '育才假期数据' : '假期数据',
          hasSubmenu: true,
          icon: vacationIcon,
          activeIcon: vacationActiveIcon,
        },
        children: [
          {
            path: 'homework',
            name: 'yucaiVacationHomeworkData',
            redirect: '/data/yucaiVacationData/homework/index',
            component: () =>
              import('@/views/data/yucai-vacation-data/homework/entry.vue'),
            meta: {
              menuName: '假期作业数据',
              role: 'dataCenter.ycSummerHomework.summerHomework',
              roleId: '11174',
            },
            children: [
              {
                path: 'index',
                name: 'yucaiVacationHomeworkDataIndex',
                component: () =>
                  import('@/views/data/yucai-vacation-data/homework/entry.vue'),
                meta: {
                  hideInMenu: true,
                  role: 'dataCenter.ycSummerHomework.summerHomework.list',
                  roleId: '11175',
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
