import noFoundImg from '@/assets/no-data/404.png';
import netErrorImg from '@/assets/no-data/net-error.png';
import noAuthImg from '@/assets/no-data/no-auth.png';
import noDataImg from '@/assets/no-data/no-data.png';
import serverErrorImg from '@/assets/no-data/server-error.png';
import successImg from '@/assets/no-data/success.png';

export const noDataImgMap = {
  404: noFoundImg,
  noData: noDataImg,
  serverError: serverErrorImg,
  netError: netErrorImg,
  noAuth: noAuthImg,
  success: successImg, // 只针对发布用
};
