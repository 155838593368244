/*
 * @Author: sanqi
 * @Date:   2017-09-21 14:24:52
 * @Last Modified by: sanqi.zgz
 * @Last Modified time: 2017-10-30 20:56:44
 */
// 通过覆盖element-ui 样式 来达到目的
import '@/common/components/dropdown';

import message from '@/common/components/message/index.js';
import { Ajax } from '@/common/scripts/utils/ajax';
import cookie from '@/common/scripts/utils/cookie';
import { goproxy } from '@/common/scripts/utils/goproxy';
import localStorage from '@/common/scripts/utils/localStorage';
const kapi = ({ serviceName, param, noHint, mustSend, extraConfig }) => {
  return Ajax({
    type: 'post',
    data: {
      serviceName,
      param,
    },
    noHint,
    mustSend,
    extraConfig,
  });
};
const KKL = {
  Ajax,
  goproxy, // go接口请求
  cookie,
  message,
  localStorage,
  kapi, // api接口请求
};
export default KKL;
