<template>
  <div>
    <span class="bg-block">
      <img class="bg" :src="imgUrl" />
      <StorageTip v-if="isShowStorageTip" class="storage-tip" placement="right" :autoSet="false" :needStorage="false">
        <div class="tip-content">
          <span class="tip-prefix">您可能想知道：</span>
          <i class="el-icon-close" @click="isShowStorageTip = false"></i>
        </div>
        <div class="tip-text" @click="onLinkToHelpCenter({ id: randomQuestionMap.id })">
          {{ randomQuestionMap.content }}
        </div>
      </StorageTip>
    </span>

    <div v-clickOutside="clickOutside" class="help" :class="{ 'help--higher': !isShowQrcode }" data-role="help">
      <div class="search">
        <el-input v-model="content" placeholder="有问题来找我吧" @input="onSearchInput" @focus="onSearchFocus">
          <template v-slot:suffix>
            <i class="el-input__icon  el-icon-search"></i>
          </template>
        </el-input>
      </div>
      <IconPopover v-if="isShowQrcode" />
      <template v-else>
        <div v-loading="loading">
          <ul v-if="questionList.length" class="search-list">
            <li
              v-for="item in questionList"
              :key="item.id"
              class="search-item"
              :class="{ active: selectedId === item.id }"
              @click="onSelectQuestion(item)"
            >
              {{ item.content }}
            </li>
          </ul>
          <div v-else class="no-data">
            暂没有内容
            <div>
              去
              <el-button class="btn__help-center" type="text" @click="onLinkToHelpCenter()">
                帮助中心
              </el-button>
              搜搜看
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import IconPopover from '@/common/components/popover/iconPopover.vue';
import StorageTip from '@/components/base/storage-tip';
import dabai1 from '@/assets/side-bar/dabai1.png';
import dabai2 from '@/assets/side-bar/dabai2.png';
import { debounce } from 'throttle-debounce';

import { listHelpCenterByContent, queryHelpCenter } from '@/fetchs/aischool/HelpCenterFacade';

import clickOutside from '@/common/directives/clickOutside';

import axios from 'axios';
const CancelToken = axios.CancelToken;

const delay = 800;

export default {
  name: 'Help',

  components: {
    IconPopover,
    StorageTip,
  },

  data() {
    return {
      content: '',
      isShowStorageTip: false,
      isShowQrcode: true,
      loading: false,
      questionList: [],
      selectedId: null,
      randomQuestionMap: null,
      canQueryHelpCenter: false,
    };
  },

  directives: {
    clickOutside,
  },

  computed: {
    imgUrl() {
      const { isShowStorageTip } = this;
      return isShowStorageTip ? dabai2 : dabai1;
    },
  },

  watch: {
    questionList(val) {
      if (this.questionList.length) {
        this.isShowQrcode = false;
      }
    },

    $route: {
      immediate: true,
      handler: function(route) {
        this.clearAllTimer();
        this.canQueryHelpCenter = false;
        this.queryHelpCenterApiCancel && this.queryHelpCenterApiCancel();
        this.isShowStorageTip = false;

        const {
          fullPath,
          meta: { roleId },
        } = route;

        if (this.fullPath !== fullPath && roleId) {
          this.timer1 = setTimeout(() => {
            this.canQueryHelpCenter = true;
          }, 30000);
          this.fullPath = fullPath;
        }
      },
    },

    isShowStorageTip(val) {
      if (val) {
        this.clearTimeout(this.timer2);
        this.timer2 = setTimeout(() => {
          this.isShowStorageTip = false;
        }, 15000);
      }
    },

    canQueryHelpCenter(val) {
      // console.log('canQueryHelpCenter', val);
      const {
        meta: { roleId },
      } = this.$route;
      val && roleId && this.queryHelpCenter(roleId);
    },
  },

  beforeDestroy() {
    this.clearAllTimer();
  },

  methods: {
    clickOutside() {
      this.isShowQrcode = true;
    },

    clearAllTimer() {
      const { timer1, timer2 } = this;
      this.canQueryHelpCenter = false;
      this.clearTimeout(timer1);
      this.clearTimeout(timer2);
    },

    clearTimeout(timer) {
      this.timer && clearTimeout(timer);
    },

    queryHelpCenter(jurisdictionId) {
      this.queryHelpCenterApiCancel && this.queryHelpCenterApiCancel();

      queryHelpCenter(jurisdictionId, {
        cancelToken: new CancelToken((c) => {
          this.queryHelpCenterApiCancel = c;
        }),
      }).then((res) => {
        if (res && res.content) {
          this.randomQuestionMap = res;
          this.isShowStorageTip = true;
        }
      });
    },

    onSearchFocus() {
      const { questionList } = this;
      if (questionList.length) {
        this.isShowQrcode = false;
      }
    },

    onSearchInput: debounce(delay, function(value) {
      if (!value) {
        this.questionList = [];
        this.isShowQrcode = true;
        return;
      }
      this.isShowQrcode = false;
      this.loading = true;
      listHelpCenterByContent(value)
        .then((res) => {
          this.questionList = res;
        })
        .finally((this.loading = false));
    }),

    onSelectQuestion({ id }) {
      this.selectedId = id;

      const query = {
        id,
      };
      this.onLinkToHelpCenter(query);
    },

    onLinkToHelpCenter(query) {
      let options = {
        path: '/help-center',
      };

      query &&
        (options = {
          ...options,
          query,
        });

      const route = this.$router.resolve(options);
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
// .help-block {
//   padding: 5px 5px 0;
//   background: linear-gradient(180deg, #fff 0%, #e4eeff 100%);
// }
.bg-block {
  position: relative;
  .bg {
    height: 139px;
    transition: all 0.3s ease-in-out;
  }
  .storage-tip {
    left: calc(100% - 15px);
  }

  .tip-content {
    display: flex;
    width: 160px;
    align-items: center;
    justify-content: space-between;
  }
  .tip-prefix {
    color: #333;
    font-size: 12px;
  }
  .tip-text {
    .text-break();
    white-space: normal;
    cursor: pointer;
  }
  .el-icon-close {
    flex-shrink: 0;
    cursor: pointer;
  }
}
.help {
  position: relative;
  margin-top: -30px;
  padding: 5px 7px 0;
  height: 69px;
  background-color: #eef4ff;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s ease-in-out;
  &--higher {
    margin-top: -60px;
    height: 195px;
  }
}
.search {
  font-size: 12px;
  /deep/ .el-input__inner {
    height: 24px;
    line-height: 24px;
    border: none;
  }
  .el-icon-search {
    color: #d7d7d7;
    font-weight: bold;
    line-height: 24px;
  }
  &-list {
    margin-top: 6px;
    height: 160px;
    color: #7b8fb1;
    font-size: 12px;
  }
  &-item {
    padding: 6px;
    .text-ellipsis();
    cursor: pointer;
    &.active,
    &:hover {
      color: #fff;
      background: @g-primary-color;
      border-radius: 4px;
      &:hover {
        color: #fff;
      }
    }
  }
}
.no-data {
  display: flex;
  height: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #7b8fb1;
  .btn__help-center {
    text-decoration: underline;
  }
}
</style>
