const getServiceName = str => `aischool.ReplaceFacade.${str}`;

/**
 * 判断用户是否看过最新的更新记录
 * @returns 
 */
export const replaceStateByMemberId = () => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('replaceStateByMemberId'),
      param: {},
    },
  });
}