import parentsCollegeIcon from '@/assets/nav-icon/家长学院1@2x.png';
import parentsCollegeActiveIcon from '@/assets/nav-icon/家长学院2@2x.png';

export default [
  {
    path: '/homeAndSchool',
    name: 'homeAndschool',
    redirect: '/homeAndschool/parentCollege',
    component: () => import('@/views/homeAndSchool/entry.vue'),
    meta: {
      role: 'homeSchool',
      roleId: '500',
      menuName: '家校共育',
    },
    children: [
      {
        path: '',
        name: 'parentCollege',
        redirect: '/homeAndSchool/parentCollege/list',
        component: () => import('@/views/homeAndSchool/parent-college/entry.vue'),
        meta: {
          role: 'homeSchool.parentsCollege.index',
          roleId: '11005',
          menuName: '家长学院',
          hasSubmenu: true,
          icon: parentsCollegeIcon,
          activeIcon: parentsCollegeActiveIcon,
        },
        children: [
          {
            path: 'parentCollege',
            name: 'parentCollege',
            redirect: '/homeAndSchool/parentCollege/list',
            component: () => import('@/views/homeAndSchool/parent-college/entry.vue'),
            meta: {
              role: 'homeSchool.parentsCollege',
              roleId: '501',
              menuName: '视频课程',
            },
            children: [
              {
                path: 'list',
                name: 'parentCollegeList',
                component: () => import('@/views/homeAndSchool/parent-college/list/entry.vue'),
                meta: {
                  role: 'homeSchool.parentsCollege.list',
                  roleId: '502',
                  hideInMenu: true,
                },
              },
              {
                path: 'courseDetail',
                name: 'parentCollegeCourseDetail',
                component: () => import('@/views/homeAndSchool/parent-college/course_detail/entry.vue'),
                meta: {
                  role: 'homeSchool.parentsCollege.detail',
                  roleId: '503',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrange',
                name: 'parentCollegeArrange',
                component: () => import('@/views/homeAndSchool/parent-college/arrange/entry.vue'),
                meta: {
                  role: 'homeSchool.parentsCollege.assign',
                  roleId: '504',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeSuccess',
                name: 'parentCollegeArrangeSuccess',
                component: () => import('@/views/homeAndSchool/parent-college/arrange_success/entry.vue'),
                meta: {
                  role: 'homeSchool.parentsCollege.assignSuccess',
                  roleId: '505',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'parentCollegeRecord',
            name: 'parentCollegeRecord',
            redirect: '/homeAndschool/parentCollegeRecord/list',
            component: () => import('@/views/homeAndSchool/parent-college/entry.vue'),
            meta: {
              role: 'homeSchool.assignRecord',
              roleId: '506',
              menuName: '布置记录',
            },
            children: [
              {
                path: 'list',
                name: 'parentCollegeRecordList',
                component: () => import('@/views/homeAndSchool/parent-college/record/entry.vue'),
                meta: {
                  role: 'homeSchool.assignRecord.list',
                  roleId: '507',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'parentCollegeRecordDetail',
                component: () => import('@/views/homeAndSchool/parent-college/record_detail/entry.vue'),
                meta: {
                  role: 'homeSchool.assignRecord.detail',
                  roleId: '508',
                  hideInMenu: true,
                },
              },
              {
                path: 'againArrange',
                name: 'againArrange',
                component: () => import('@/views/homeAndSchool/parent-college/arrange/entry.vue'),
                meta: {
                  role: 'homeSchool.assignRecord.assign',
                  roleId: '509',
                  hideInMenu: true,
                },
              },
              {
                path: 'againArrangeSuccess',
                name: 'againArrangeSuccess',
                component: () => import('@/views/homeAndSchool/parent-college/arrange_success/entry.vue'),
                meta: {
                  role: 'homeSchool.assignRecord.assignSuccess',
                  roleId: '510',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
