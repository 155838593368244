const getNoHideMenuName = (menuList) => {
  const l = menuList?.length;
  if (!l) {
    return;
  }

  return menuList[0].name;
};

export const getRedictPageName = (menuList) => {
  if (!menuList.length) {
    return;
  }

  const l = menuList.length;

  for (let i = 0; i < l; i++) {
    const item = menuList[i];
    const {
      children,
      // name,
      meta: { hasSubmenu, hideInMenu },
    } = item;

    if (hasSubmenu) {
      const name = getRedictPageName(children);
      if (name) {
        return name;
      } else {
        continue;
      }
    } else if (hideInMenu) {
      continue;
    } else {
      const name = getNoHideMenuName(children);
      if (name) {
        return name;
      } else {
        continue;
      }
    }
  }

  return;
};
