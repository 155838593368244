const getServiceName = str => `aischool.HelpCenterFacade.${str}`;

/**
 * 帮助中心左侧菜单
 * @returns 
 */
export const getAllQuestion = () => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('getAllQuestion'),
      param: {},
    },
  });
};

/**
 * 帮助中心详情
 * @param {Number} id 
 * @returns 
 */
export const queryHelpCenterById = (id) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('queryHelpCenterById'),
      param: { id },
    },
  })
}

/**
 * 用户评价
 * @returns 
 */
export const helpCenterStateById = (param) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('helpCenterStateById'),
      param,
    },
  })
};

/**
 * 帮助中心搜索
 * @param {String} content 
 * @returns 
 */
export const listHelpCenterByContent = (content) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('listHelpCenterByContent'),
      param: { bparam: { content } },
    },
  })
};

/**
 * 通过权限点Id，随机获取一个问题
 * @param {Number} jurisdictionId 
 * @returns 
 */
export const queryHelpCenter = (jurisdictionId, extraConfig) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('queryHelpCenter'),
      param: { bparam: { jurisdictionId } },
    },
    extraConfig
  })
};

/**
 * 用户访问问题数量
 * @param {Number} id 
 */
export const userPvByMemberId = (id) => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('userPvByMemberId'),
      param: { id },
    },
  })
}
