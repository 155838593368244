/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: zgz
 * @Last Modified time: 2018-10-29 15:19:47
 */

const getServiceName = (str) => `aischool.ConfigFacade.${str}`;

/**
 * 根据学科获取对应的题型列表,顺序代表试题题型排序 http://rap.ikuko.com/workspace/myWorkspace.do?projectId=162#1870
 * @export
 * @returns promise
 */
export function listSubjectCategories({ subjectId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.ConfigFacade.listSubjectCategories',
      param: {
        subjectId,
      },
    },
  });
}

/**
 * 批改截止时间段 24h、3天、7天、 14天 、30天 http://rap.ikuko.com/workspace/myWorkspace.do?projectId=162#1892
 * @export [{key: '24h',value: '24小时'},{key: '3d',value: '3天'},{key: '7d',value: '7天'},{key: '14d',value: '14天'},{key: '30d',value: '30天'}],
 * @returns promise
 */
export function listReviewHomeworkTimeLimitOptions() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.ConfigFacade.listReviewHomeworkTimeLimitOptions',
      param: {},
    },
  });
}

/**
 * 获取无（其他选项）的科目列表
 * @export 新网关
 * @returns promise
 */

export function listSubjectWithOutOther({ provinceId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.ConfigFacade.listSubjectWithOutOther',
      param: {
        provinceId,
      },
    },
  });
}

/**
 * 获取留言默认信息
 * @export 新网关
 * @returns promise
 */

export function listHomeworkDesc() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.ConfigFacade.listHomeworkDesc',
      param: {},
    },
  });
}

/**
 * 查询教材版本列表（配置中心配置）
 * @returns
 */
export const listBookVersionByConfig = () => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: getServiceName('listBookVersionByConfig'),
      param: {
        bparam: {},
      },
    },
  });
};
