/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: zgz
 * @Last Modified time: 2018-06-14 10:01:41
 */

/**
 * 保存信息
 * @export
 * @returns promise
 */
export function upsertByMemberAndKey({ key, value, event }) {
  return KKL.Ajax({
    type: 'post',
    mustSend: !!event,
    data: {
      serviceName: 'aischool.FrontendMetadataFacade.upsertByMemberAndKey',
      param: {
        frontendMetadataDTO: {
          key,
          value,
        },
      },
    },
  });
}

/**
 * 查询保存的信息
 * @export
 * @returns promise
 */
export function queryByMemberAndKey({ key }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.FrontendMetadataFacade.queryByMemberAndKey',
      param: {
        key,
      },
    },
  });
}

/**
 * @description 获取大数据提供的学生的作业详情列表，生成excel并上传oss，然后返回oss文件地址
 * @param { string } homeworkId 作业id
 */
export const downloadHomeworkStudentExcel = (homeworkId = '') => {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.HomeworkStudentV2Facade.downloadHomeworkStudentExcel',
      param: {
        homeworkId,
      },
    },
  });
};
