/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-02-07 15:45:17
 * @LastEditors: linghongxiang linghongxiang@mistong.com
 * @LastEditTime: 2024-08-16 16:04:14
 * @FilePath: \kkl-online-school\src\common\mixins\favicon.js
 * @Description: 动态加载 favicon
 */
import { getFavicon } from '@/common/enums/hostConfig';

export default {
  data() {
    return {
      faviconURL: getFavicon(),
    };
  },
  created() {
    this.setFavicon();
  },
  methods: {
    setFavicon() {
      const link =
        document.querySelector('link[rel*="icon"]') ||
        document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.faviconURL;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
  },
};
