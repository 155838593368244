<template>
  <span class="k-link" :type="type" @click="$emit('click')"><slot></slot></span>
</template>

<script>
export default {
  name: 'k-link',
  props: {
    type: {
      default: 'default',
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import './style.less';
</style>
