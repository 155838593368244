import { SET_HOMEWORK } from '../mutationTypes';

const cacheKey = 'homework-info';

const getStore = key => {
  let homework = KKL.localStorage.getItem(key) || '{}';
  try {
    homework = JSON.parse(homework);
  } catch (err) {
    console.log(err);
    return {};
  }
  return homework;
};

const setStore = (key, data) => {
  console.log('setStore');
  KKL.localStorage.setItem(key, JSON.stringify(data));
};
/**
 * 保存store到cache
 * @param {*} param0.store - homeworkstore
 * @param {number} param1.userId - 用户id
 */
function saveStoreToCache({ state }, userId) {
  let homework = JSON.parse(JSON.stringify(state));
  homework.subjectList = [];
  let data = getStore(cacheKey);
  data[userId] = homework;
  setStore(cacheKey, data);
}

/**
 * 设置homework 如果缓存中有数据 则使用该数据
 * @param {*} param0
 * @param {number} param1.userId - 用户id
 */
function setStoreFromCache({ commit }, userId) {
  let data = getStore(cacheKey);
  let homework = data[userId];
  if (homework) {
    homework.subjectList = [];
    commit(SET_HOMEWORK, homework);
  }
}

export default {
  saveStoreToCache,
  setStoreFromCache, // 在src/loginLayout中被调用
};
