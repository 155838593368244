/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-02-07 17:24:36
 * @LastEditors: linghongxiang linghongxiang@mistong.com
 * @LastEditTime: 2024-08-16 15:59:10
 * @FilePath: \kkl-online-school\src\common\mixins\title.js
 * @Description:
 */
import { getTitle } from '@/common/enums/hostConfig';

export default {
  data() {
    const { hostname } = window.location;
    return {
      title: getTitle(),
    };
  },
  created() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      document.title = this.title;
    },
  },
};
