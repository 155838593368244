<template>
  <div v-if="status" :class="['default', layoutNormal ? 'normal' : '']" :style="styles">
    <div class="default__img">
      <img :src="showImg || statusEnum[status]" />
    </div>
    <div v-if="msg" class="default__msg" v-html="msg"></div>
    <slot></slot>
  </div>
</template>

<script>
import { noDataImgMap } from '@/components/base/no-data/constant';
/**
 * 缺省组件
 * @displayName default组件
 */
export default {
  name: 'Default',
  props: {
    /**
     * 替换默认图片
     * @values 图片地址 require('@/assets/...')
     */
    showImg: {
      type: String,
      default: () => '',
    },
    /**
     * 提示文案
     * @values 网络错误
     */
    msg: {
      type: String,
      default: '',
    },
    /**
     * 缺省状态
     * @values 404,noData,serverError,netError,noAuth,success
     */
    status: {
      type: String,
      default: '',
    },
    /**
     * 自定义样式
     * @values css样式 {width: '14px'}
     */
    styles: {
      type: Object,
      default: () => ({}),
    },

    layoutNormal: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      statusEnum: noDataImgMap,
    };
  },
};
</script>

<style lang="less" scoped>
.default {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  text-align: center;
  &.normal {
    position: relative;
    left: 0;
    top: 0;
    transform: inherit;
    padding: 10px 0 0 0;
  }

  &__img {
    margin-bottom: 24px;

    img {
      width: 200px;
    }
  }

  &__msg {
    font-size: 14px;
    color: #5c5c5c;
    text-align: center;
    margin-bottom: 15px;
  }
}
</style>
