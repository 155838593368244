import Vue from 'vue';
import {
  queryByMemberAndKey,
  upsertByMemberAndKey,
} from '@/fetchs/aischool/FrontendMetadataFacade';

/**
 * 判断是否是对象
 *
 * @export {function}
 * @param {Any} x
 * @returns {Boolean}
 */

export function isObj(x) {
  const type = typeof x;
  return x !== null && (type === 'object' || type === 'function');
}

/**
 * 判断对象自身属性为空
 *
 * @export {function}
 * @param {Any} x
 * @returns {Boolean}
 */

export function isObjHasOwnProperty(x) {
  const obj = isObj(x);
  return obj && Object.getOwnPropertyNames(x).length;
}

/**
 * 将参数转为对象
 *
 * @export {function}
 * @param {String|Object} message
 * @returns {Object}
 */

export function parseOptions(message) {
  return isObj(message) ? message : { message };
}

/**
 * 判断是否命中缓存
 * 先检测localstorage是否有cacheKey配置对象缓存
 * 若没则请求api网关检测redis是否已缓存cacheKey配置对象
 *
 * @export {function}
 * @param {Object} cacheKey web端配置cacheKey缓存键值对
 * @param {Boolean} storageSettingsDev 是否启用缓存、方便调试
 * @param {Boolean} storageSettingsLocal 是否仅启用本地缓存
 * @returns {Boolean}
 */

export async function isHitCache(
  cacheKey,
  storageSettingsDev,
  storageSettingsLocal
) {
  const { key, value } = cacheKey;
  const localCached = localStorage.getItem(key);
  // 开发调试模式、一直显示组件、不走缓存策略
  if (!storageSettingsDev) {
    return false;
  }
  if (!localCached) {
    // 只设置localStorage缓存、不走redis缓存
    if (storageSettingsLocal) {
      return false;
    }
    const serverCached = await queryByMemberAndKey(cacheKey);
    const isServerCached = !!serverCached.value;
    // 本地保存一份cacheKey值
    // TODO: 1、后期可优化localStorage存储cacheKey值的方式、序列化存储数据或采用第三方范式化数据框架
    isServerCached && localStorage.setItem(key, value);
    return isServerCached;
  }
  return true;
}

/**
 * 用户点击缓存cacheKey值
 *
 * @export {function}
 * @param {Object} cacheKey web端配置cacheKey缓存键值对
 * @param {Boolean} storageSettingsDev 是否启用缓存、方便调试
 * @param {Boolean} storageSettingsLocal 是否仅启用本地缓存
 * @returns {Void}
 */

export async function syncCacheStatus(
  cacheKey,
  storageSettingsDev,
  storageSettingsLocal
) {
  const { key, value } = cacheKey;
  // 开发调试模式、不走缓存策略
  if (!storageSettingsDev) {
    return false;
  }
  if (!storageSettingsLocal) {
    // redis缓存cacheKey值
    await upsertByMemberAndKey(cacheKey);
  }
  // 本地都保存一份cacheKey值
  localStorage.setItem(key, value);
}

/**
 * 将组件实例插入参数配置的dom位置
 *
 * @export {function}
 * @param {Object} options 组件调用时传入参数配置
 * @param {Object} instance 组件实例
 * @param {Object} defaultOptions 组件默认参数配置
 */

export function insertAfterParentNode(options, instance, defaultOptions) {
  const fn = () => {
    const element = document.getElementById(options.parentNode);
    // nextTick机制可能无法获取dom
    if (!element) {
      throw new Error('element parentNode not exist');
    }
    const position = options.position || defaultOptions.position;
    const type = options.type || defaultOptions.type;
    const { width, height } = options;
    const { size } = defaultOptions;
    // 多行新手引导建议使用feature类型组件
    if (height && type === 'operator') {
      console.warn('type `operator` can set height property, but not suggest');
    }
    // 距离父容器距离: 上下15px、左右20px
    switch (`${position}`) {
      case 'top':
        height && (instance.$el.style.top = `-${height + 15}px`);
        instance.$el.style.left = `${(element.offsetWidth -
          (width || size[type].width)) /
          2}px`;
        break;
      case 'bottom':
        height && (instance.$el.style.bottom = `-${height + 15}px`);
        instance.$el.style.left = `${(element.offsetWidth -
          (width || size[type].width)) /
          2}px`;
        break;
      case 'right':
        width && (instance.$el.style.right = `-${width + 20}px`);
        instance.$el.style.top = `${(element.offsetHeight -
          (height || size[type].height)) /
          2}px`;
        break;
      case 'left':
        width && (instance.$el.style.left = `-${width + 20}px`);
        instance.$el.style.top = `${(element.offsetHeight -
          (height || size[type].height)) /
          2}px`;
        break;
      default:
        break;
    }
    // 对已经脱离文档流的元素进行判断
    const positionComputedStyle = window.getComputedStyle(element).position;
    if (positionComputedStyle !== 'absolute') {
      element.style.position = 'relative';
    }
    element.appendChild(instance.$el);
  };
  // TODO: 捕获无法获取dom错误、后期优化机制
  Vue.nextTick(() => {
    try {
      fn();
    } catch (e) {
      setTimeout(fn, 500);
    }
  });
}
