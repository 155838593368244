<template>
  <span v-if="$scopedSlots.default">
    <!-- @slot -->
    <slot :time-data="timeData"></slot>
  </span>
  <span v-else>{{ formatedData }}</span>
</template>
<script>
import { parseTimeData, parseTimeFormat } from '@/common/scripts/utils/time.js';

/**
 * 倒计时组件
 * @displayName CountDown组件
 */
export default {
  name: 'CountDown',

  props: {
    /** @description 目前只支持毫秒形式的时间 */
    time: {
      type: [Number, String],
      default: 0,
    },

    /**
     * @description 时间格式
     * 目前这块的功能还不完善
     */
    format: {
      type: String,
      default: 'HH:MM:SS',
    },
  },

  data() {
    return {
      remain: Number(this.time),
    };
  },

  computed: {
    timeData() {
      return parseTimeData(this.remain);
    },

    formatedData() {
      return parseTimeFormat(this.format, this.timeData);
    },
  },

  watch: {
    time: {
      immediate: true,
      handler: 'reset',
    },
  },

  methods: {
    reset() {
      this.cancel();

      this.remain = Number(this.time);

      // 开始倒计时
      this.start();
    },

    cancel() {
      this.counting = false;
      if (this.timeId) {
        clearTimeout(this.timeId);
      }
    },

    start() {
      if (this.counting) {
        return;
      }

      this.counting = true;
      this.endTime = Date.now() + this.remain;

      this.countDown();
    },

    countDown() {
      // this.timeId = requestAnimationFrame(() => {
      //   if (!this.counting) {
      //     return;
      //   }

      //   const remain = Math.max(this.endTime, 0);

      //   this.remain = remain;

      //   if (remain === 0) {
      //     this.cancel();
      //     this.$emit('finish');

      //     return;
      //   }

      //   this.countDown();
      // });
      this.timeId = setTimeout(() => {
        if (!this.counting) {
          return;
        }

        const remain = Math.max(this.endTime - Date.now(), 0);

        this.remain = remain;

        if (this.remain === 0) {
          this.cancel();
          this.$emit('finish');

          return;
        }

        this.remain > 0 && this.countDown();
      }, 1000);
    },
  },
};
</script>
