import type { Component } from 'vue';
import Vue from 'vue';

import QuestionRichText from '@/common/components/business/QuestionRichText.vue';
import KButton from '@/common/components/button/index.vue';
import ContentLayout from '@/common/components/ContentLayout.vue';
import ElImage from '@/common/components/ElImage/index.vue';
import KLink from '@/common/components/link/index.vue';
/**
 * NoData 的第一个版本
 */
import NoData from '@/common/components/NoData.vue';
import PageTitle from '@/common/components/PageTitle/index.vue';
import BreadcrumbContentLayout from '@/components/base/breadcrumb-content-layout/index.vue';
/**
 * NoData 的第二个版本
 */
import Default from '@/components/base/default/index.vue';
/**
 * NoData 现在使用的版本
 * 现在使用的版本没有在全局引用
 */
// import NoData from '@/components/base/no-data/index.vue';
import HeaderTile from '@/components/base/HeaderTile.vue';
import KBreadcrumb from '@/components/base/k-breadcrumb/index.vue';
import KTable from '@/components/base/k-table/index.vue';

const components: Record<string, Component> = {
  ElImage,
  KButton,
  KLink,
  ContentLayout,
  BreadcrumbContentLayout,
  NoData,
  PageTitle,
  QuestionRichText,
  KBreadcrumb,
  Default,
  HeaderTile,
  KTable,
};

export function setGlobalComponents() {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
  });
}
