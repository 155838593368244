import schoolMateKKLIcon from '@/assets/nav-icon/校长开课啦1@2x.png';
import schoolMateKKLActiveIcon from '@/assets/nav-icon/校长开课啦2@2x.png';

export default [
  {
    path: '/schoolRunning',
    name: 'schoolRunning',
    redirect: '/schoolRunning/headmasterKkl',
    component: () => import('@/views/school-running/entry.vue'),
    meta: {
      role: 'schoolMateKKL',
      roleId: '11006',
      menuName: '校长开课啦',
    },
    children: [
      {
        path: 'headmasterKkl',
        name: 'headmasterKkl',
        redirect: '/schoolRunning/headmasterKkl/list',
        component: () => import('@/views/school-running/headmaster/entry.vue'),
        meta: {
          role: 'schoolWork.schoolMateKKL',
          roleId: '601',
          menuName: '校长开课啦',
          icon: schoolMateKKLIcon,
          activeIcon: schoolMateKKLActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'headmasterKklList',
            component: () =>
              import(
                '@/views/school-running/headmaster/headmaster-kkl/entry.vue'
              ),
            meta: {
              role: 'schoolWork.schoolMateKKL.list',
              roleId: '602',
              hideInMenu: true,
            },
          },
          {
            path: 'detail',
            name: 'headmasterKklDetail',
            component: () =>
              import('@/views/school-running/headmaster/live-detail/entry.vue'),
            meta: {
              role: 'schoolWork.schoolMateKKL.detail',
              roleId: '603',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '/teachingCenter/teacherGrowup',
        name: 'teacherGrowup',
        redirect: '/teachingCenter/teacherGrowup/home',
        component: () =>
          import('@/views/teaching-center/teacher_growup/entry.vue'),
        meta: {
          role: 'teachingCenter.teacherGrowth',
          roleId: '327',
          menuName: '教师成长',
          icon: schoolMateKKLIcon,
          activeIcon: schoolMateKKLActiveIcon,
        },
        children: [
          {
            path: 'home',
            name: 'teacherGrowupHome',
            component: () =>
              import('@/views/teaching-center/teacher_growup/home/entry.vue'),
            meta: {
              role: 'teachingCenter.teacherGrowth.list',
              roleId: '328',
              hideInMenu: true,
            },
          },
          {
            path: 'liveroom',
            name: 'liveroom',
            component: () =>
              import(
                '@/views/teaching-center/teacher_growup/liveroom/entry.vue'
              ),
            meta: {
              role: 'teachingCenter.teacherGrowth.live',
              roleId: '330',
              hideInMenu: true,
            },
          },
          {
            path: 'videoroom',
            name: 'videoroom',
            component: () =>
              import(
                '@/views/teaching-center/teacher_growup/videoroom/entry.vue'
              ),
            meta: {
              role: 'teachingCenter.teacherGrowth.playback',
              roleId: '331',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];
