/*
 * @Author: linghongxiang linghongxiang@mistong.com
 * @Date: 2024-08-15 17:39:19
 * @LastEditors: linghongxiang linghongxiang@mistong.com
 * @LastEditTime: 2024-08-16 14:32:55
 * @FilePath: \kkl-online-school\src\common\enums\hostConfig.js
 * @Description: 域名相关配置（title、favicon、icp）
 */
const mainDomain = location.hostname.match(/[^.]*(.com)?(.cn)?$/)[0];

/** 未来校主域名 */
export const WLX_MAIN_DOMAIN = 'wlxiao.com';
/** 云育才主域名 */
export const YC_MAIN_DOMAIN = 'yunyucai.cn';

/** 是否是云育才（根据主域名判断） */
export const IS_YUCAI = mainDomain === YC_MAIN_DOMAIN;
/** 是否是未来校（根据主域名判断） */
export const IS_WLXIAO = mainDomain === WLX_MAIN_DOMAIN;

const copyright = '由开课啦提供技术支持';
const icpMap = {
  [YC_MAIN_DOMAIN]: { copyright, icpId: '浙ICP备2022011371号-1' },
  [WLX_MAIN_DOMAIN]: { copyright, icpId: '浙ICP备14032235号-4' }, // 默认配置
};

const yucaiFavicon =
  'https://festatic.estudy.cn/assets/kkl-online-school/public/yunyucai.ico';
const wlxiaoFavicon =
  'https://festatic.estudy.cn/assets/kkl-online-school/public/onlineSchool.ico';

const faviconMap = {
  [YC_MAIN_DOMAIN]: yucaiFavicon,
  [WLX_MAIN_DOMAIN]: wlxiaoFavicon, // 默认配置
};

const titleMap = {
  [YC_MAIN_DOMAIN]: '育才云朵朵',
  [WLX_MAIN_DOMAIN]: '未来校', // 默认配置
};

/**
 * @description: 网站title
 * @return {string}
 */
export function getTitle() {
  return titleMap[mainDomain] || titleMap[WLX_MAIN_DOMAIN];
}

/**
 * @description: 网站favicon
 * @return {string}
 */
export function getFavicon() {
  return faviconMap[mainDomain] || faviconMap[WLX_MAIN_DOMAIN];
}

/**
 * @description: 网站备案信息
 * @return {object} { copyright, icpId }
 */
export function getIcp() {
  return icpMap[mainDomain] || icpMap[WLX_MAIN_DOMAIN];
}
