/*
 * @Author: sanqi
 * @Date:   2017-09-13 11:50:33
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-01-21 11:10:54
 */

/**
 * 作业方案列表翻页查询
 * @export
 * @returns promise
 */
export function queryPagePlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  shareStatus = false,
  subjectId,
  planType,
  title,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryPagePlanTemplate',
      param: {
        pagePlanTemplateQueryIn: {
          pageSize,
          subjectId,
          pageCurrent,
          shareStatus,
          planType,
          title,
        },
      },
    },
    noHint,
  });
}

/**
 * 学段列表查询
 * @export
 * @returns promise
 * @param {number} selectType -选择类型 （1:视频作业）（2：试题作业）
 */
export function queryTerm({ subjectId, selectType = 1 }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryTerm',
      param: {
        termQueryIn: {
          subjectId,
          selectType,
        },
      },
    },
  });
}

/**
 * 根据主键获取作业模板详情
 * @export
 * @returns promise
 */
export function queryTemplateById(id) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryTemplateById',
      param: {
        templateIn: {
          id: id,
        },
      },
    },
  });
}

/**
 * 检查作业名称重复接口
 * @export
 * @returns promise
 */
export function checkTemplate({ name, status }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.checkTemplate',
      param: {
        templateCheckIn: {
          name,
          status,
        },
      },
    },
  });
}

/**
 * 根据条件分页查询试题列表
 * @export
 * @returns promise
 */
export function queryPageQuestion({
  type,
  cate,
  termId,
  subjectId,
  chapterId,
  searchType,
  textbookId,
  difficulty,
  knowledgeId,
  homeworkId,
  version = 1, // 1位老版数据  2为新版 如新版菁优
  pageSize = 10,
  pageCurrent = 1,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryPageQuestion',
      param: {
        pageQuestionQueryIn: {
          type,
          cate,
          termId,
          pageSize,
          subjectId,
          chapterId,
          searchType,
          difficulty,
          textbookId,
          knowledgeId,
          homeworkId,
          pageCurrent,
          homeworkVersion: version,
        },
      },
    },
  });
}

/**
 * 试题列表搜索接口http://rap.ikuko.com/workspace/myWorkspace.do?projectId=162#1907
 * @export
 * @returns promise
 */
export function queryPageQuestionBySearchText({
  subjectId,
  searchText,
  // cates = [0],
  version = 1, // 1位老版数据  2为新版 如新版菁优
  pageSize = 10,
  pageCurrent = 1,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.HomeworkTemplateFacade.queryPageQuestionBySearchText',
      param: {
        query: {
          // cates,
          pageSize,
          subjectId,
          searchText,
          pageCurrent,
          homeworkVersion: version,
        },
      },
    },
    noHint,
  });
}

/**
 * 删除作业方案
 * @export
 * @returns promise
 */
export function deleteTemplate(id) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.deleteTemplate',
      param: {
        templateDeleteIn: {
          id,
        },
      },
    },
  });
}
/**
 * 保存作业方案
 * @export
 * @returns promise
 */
export function saveTemplate({
  title,
  version,
  subjectId,
  relationId,
  shareStatus,
  description,
  selectLessonIds,
  selectTestPapers,
  selectQuestionIds,
  shareTypes,
  buildType,
  tag,
  tagId,
  degree,
  termId,
  planStatus,
  provinceCode,
  cityCode,
  countyCode,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.saveTemplate',
      param: {
        templateSaveIn: {
          title,
          version,
          subjectId,
          relationId,
          shareStatus,
          description,
          selectLessonIds,
          selectTestPapers,
          selectQuestionIds,
          shareTypes,
          buildType,
          tag,
          tagId,
          degree,
          termId,
          planStatus,
          provinceCode,
          cityCode,
          countyCode,
        },
      },
    },
  });
}
/**
 * 布置作业
 * @export
 * @returns promise
 */
export function releaseTemplate({
  type = 1,
  title,
  endTime,
  isTimed,
  version,
  subjectId,
  relationId,
  publishTime,
  groupIdList,
  description,
  selectClasses,
  selectPaperIds,
  selectLessonIds,
  selectTestPapers,
  selectQuestionIds,
  finishReleaseAnswer,
  reviewEndTimeInterval,
  buildType,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.releaseTemplate',
      param: {
        templateReleaseIn: {
          type,
          title,
          isTimed,
          version,
          endTime,
          subjectId,
          relationId,
          description,
          publishTime,
          selectClasses,
          selectPaperIds,
          selectLessonIds,
          selectTestPapers,
          selectQuestionIds,
          finishReleaseAnswer,
          reviewEndTimeInterval,
          selectedGroupList: groupIdList,
          buildType,
        },
      },
    },
  });
}

/**
 * 获取章节目录
 * @export
 * @returns promise
 */
export function queryChapter({ termId, subjectId, textbookId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryChapter',
      param: {
        taiChapterQueryIn: {
          termId,
          subjectId,
          textbookId,
        },
      },
    },
  });
}

/**
 * 获取试题解析接口
 * @export
 * @returns promise
 */
export function queryQuestionAnalysis({ subjectId, questionId, version }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.queryQuestionAnalysis',
      param: {
        subjectId,
        questionId,
        homeworkVersion: version,
      },
    },
  });
}

/**
 * 获取当前试题库版本号
 * @export
 * @returns promise
 */
export function getCurrentQuestionLibVersion() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.HomeworkTemplateFacade.getCurrentQuestionLibVersion',
      param: {},
    },
  });
}

/**
 * 生成批量布置作业preid
 * @export
 * @returns promise
 */
export function getPreparedHomeworkIds({ num }) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.getPreparedHomeworkIds',
      param: {
        num,
      },
    },
  });
}

/**
 * 批量布置作业
 * @export
 * @returns promise
 */
export function batchReleaseTemplate({
  type = 1,
  title,
  endTime,
  isTimed,
  publishTime,
  groupIdList,
  description,
  preparedIds,
  relationIds,
  selectClasses,
  finishReleaseAnswer,
  reviewEndTimeInterval,
  releaseHomeworkDTOList,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.batchReleaseTemplate',
      param: {
        param: {
          type,
          title,
          isTimed,
          endTime,
          relationIds,
          publishTime,
          preparedIds,
          description,
          selectClasses,
          finishReleaseAnswer,
          reviewEndTimeInterval,
          releaseHomeworkDTOList,
          selectedGroupList: groupIdList,
        },
      },
    },
  });
}

/**
 * @desc 课后作业 撤回
 * @export
 * @returns promise
 */
export function revoke({ homeworkId }) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.revoke',
      param: {
        homeworkId,
      },
    },
  });
}
/**
 * 保存方案编辑修改
 * @export
 * @returns promise
 */
export function saveEditTemplate({
  description,
  homeworkId,
  shareTypes,
  title,
  tag,
  selectLessonIds,
  selectQuestionIds,
  subjectId,
  tagId,
  degree,
  termId,
  planStatus,
  provinceCode,
  cityCode,
  countyCode,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.saveEditTemplate',
      param: {
        templateSaveIn: {
          description,
          homeworkId,
          shareTypes,
          title,
          tag,
          selectLessonIds,
          selectQuestionIds,
          subjectId,
          tagId,
          degree,
          termId,
          planStatus,
          provinceCode,
          cityCode,
          countyCode,
        },
      },
    },
  });
}

/**
 * 添加收藏
 * @export
 * @returns promise
 */
export function addCollectionTemplate({
  collectionSource,
  homeworkId,
  subjectId,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.addCollectionTemplate',
      param: {
        collectionDTO: {
          collectionSource,
          homeworkId,
          subjectId,
        },
      },
    },
  });
}

/**
 * @desc 课后作业 修改
 * @export
 * @returns promise
 */
export function update({
  homeworkId,
  title,
  description,
  endTime,
  pushTime,
  reviewEndTimeInterval,
}) {
  return KKL.Ajax({
    noHint: true,
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.update',
      param: {
        homeworkUpdate: {
          homeworkId,
          title,
          description,
          endTime,
          pushTime,
          reviewEndTimeInterval,
        },
      },
    },
  });
}

/**
 * 取消收藏
 * @export
 * @returns promise
 */
export function deleteCollectionTemplate({
  collectionSource,
  homeworkId,
  subjectId,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.deleteCollectionTemplate',
      param: {
        collectionDTO: {
          collectionSource,
          homeworkId,
          subjectId,
        },
      },
    },
  });
}

/**
 * 修改作业方案
 * @export
 * @returns promise
 */
export function modifyTemplate({
  description,
  relationId,
  selectQuestionIds,
  shareStatus,
  shareTypes,
  subjectId,
  title,
  version,
  selectLessonIds,
  selectTestPapers,
  buildType,
  tag,
  tagId,
  degree,
  termId,
  planStatus,
  homeworkId,
  provinceCode,
  cityCode,
  countyCode,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.modifyTemplate',
      param: {
        templateSaveIn: {
          description,
          relationId,
          selectLessonIds,
          selectQuestionIds,
          selectTestPapers,
          shareStatus,
          shareTypes,
          subjectId,
          title,
          version,
          buildType,
          tag,
          tagId,
          degree,
          termId,
          planStatus,
          homeworkId,
          provinceCode,
          cityCode,
          countyCode,
        },
      },
    },
  });
}

/**
 *
 * 作业记录 获取待批改作业数 http://rap.ikuko.com/workspace/myWorkspace.do?projectId=162#1869
 * @export
 * @param {deadlineStatus groupId、memclass、subjectId、type、 year} 截止状态((默认-> -1， 查询全部； 0, 查询未截止；1，查询已截止))
 * @returns   Promise
 */
export function countNoReviewHomeWork({
  type,
  year,
  groupId,
  memclass,
  subjectId,
  deadlineStatus,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.countNoReviewHomeWork',
      param: {
        taiQueryConditionDTO: {
          type,
          year,
          groupId,
          memclass,
          subjectId,
          deadlineStatus,
        },
      },
    },
  });
}

/**
 *
 * 接口详情 根据方案id获取试题详情列表接口（含校验试题查询结果是否删改） http://rap.ikuko.com/workspace/myWorkspace.do?projectId=175#2093
 * @export
 * @param {homeworkId} 作业方案id
 * @returns   Promise
 */
export function queryQuestionListByTemplateId(homeworkId) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.HomeworkTemplateFacade.queryQuestionListByTemplateId',
      param: {
        homeworkId,
      },
    },
  });
}

/**
 *
 * 首页获取数据
 * @export
 * @param
 * @returns   Promise
 */
export function countPlanTemplate() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.countPlanTemplate',
      param: {
        bparam: {},
      },
    },
  });
}

/**
 *
 * 从开课啦精选库移除
 * @export
 * @param
 * @returns   Promise
 */
export function removeTemplateFromKklPlan({ homeworkId }) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.removeTemplateFromKklPlan',
      param: {
        homeworkId,
      },
    },
  });
}

/**
 *
 * 作业记录分页查询
 */
export function pageHistoryByCondition({
  deadlineStatus,
  grade,
  type,
  groupId,
  memclass,
  subjectId,
  year,
  pageSize = 10,
  pageCurrent = 1,
  teacherId = null,
  title = null,
  publishStartTime = null,
  publishEndTime = null,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.pageHistoryByCondition',
      param: {
        condition: {
          deadlineStatus,
          grade,
          type,
          groupId,
          memclass,
          subjectId,
          year,
          pageSize,
          pageCurrent,
          teacherId,
          title,
          publishStartTime,
          publishEndTime,
        },
      },
    },
    noHint,
  });
}

/**
 * 分页查询开课啦精选作业方案
 * @export
 * @returns promise
 */
export function pageKKLPlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  areaCode,
  subjectId,
  title,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.pageKKLPlanTemplate',
      param: {
        bparam: {
          pageSize,
          pageCurrent,
          areaCode,
          subjectId,
          title,
        },
      },
    },
    noHint,
  });
}

/**
 * 分页查询本校错题集作业方案
 * @export
 * @returns promise
 */
export function pageSchoolWrongPlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  subjectId,
  grade = null,
  classId = null,
  startDate,
  endDate,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName:
        'aischool.HomeworkTemplateFacade.pageSchoolWrongPlanTemplate',
      param: {
        bparam: {
          pageSize,
          pageCurrent,
          subjectId,
          grade,
          classId,
          startDate,
          endDate,
        },
      },
    },
    noHint,
  });
}

/**
 * 分页查询我的收藏作业方案
 * @export
 * @returns promise
 */
export function pageCollectionPlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  subjectId,
  title,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.pageCollectionPlanTemplate',
      param: {
        bparam: {
          pageSize,
          pageCurrent,
          subjectId,
          title,
        },
      },
    },
  });
}

/**
 * 分页查询我的组卷作业方案
 * @export
 * @returns promise
 */
export function pageMyPlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  subjectId,
  title,
  noHint = false,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.pageMyPlanTemplate',
      param: {
        bparam: {
          pageSize,
          pageCurrent,
          subjectId,
          title,
        },
      },
    },
    noHint,
  });
}

/**
 * 分页查询本校共享作业方案
 * @export
 * @returns promise
 */
export function pageSchoolPlanTemplate({
  pageCurrent = 1,
  pageSize = 10,
  subjectId,
  title,
}) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.pageSchoolPlanTemplate',
      param: {
        bparam: {
          pageSize,
          pageCurrent,
          subjectId,
          title,
        },
      },
    },
  });
}

export function listGradeClass() {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.HomeworkTemplateFacade.listGradeClass',
      param: {},
    },
  });
}
