import { getMemberId } from '@/common/scripts/utils/business/login';

function getMemberIdKey(key) {
  return `${getMemberId()}_${key}`;
}

/**
 * 根据memberId,key查数据
 * @param {*} key
 * @returns
 */
export function queryByMemberAndKey(key) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'estudy.CommonKeyValueFacade.queryByMemberAndKey',
      param: {
        key: getMemberIdKey(key),
      },
    },
  });
}

/**
 * 根据memberI,key存数据
 * @param {*} key
 * @returns
 */
export function saveByMemberAndKey(key, value) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'estudy.CommonKeyValueFacade.saveByMemberAndKey',
      param: {
        commonKeyValueDTO: {
          key: getMemberIdKey(key),
          value,
        },
      },
    },
  });
}

/**
 * 根据memberId删除key
 * @param {*} key
 * @returns
 */
export function deleteByMemberAnyKey(key) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'estudy.CommonKeyValueFacade.deleteByMemberAnyKey',
      param: {
        key: getMemberIdKey(key),
      },
    },
  });
}

/**
 * 通过错题ID数组查题目内容
 * @param {number} wqStatIds
 * @returns
 */
export function getListQuestionByIds(wqStatIds) {
  return KKL.Ajax({
    type: 'post',
    data: {
      serviceName: 'aischool.WrongQuestionStatQueryFacade.listQuestionByIds',
      param: {
        wqStatIds,
      },
    },
  });
}
