<template>
  <el-dialog
    class="k-dialog"
    :visible.sync="dialogVisible"
    size="full"
    :class="[type]"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="0"
  >
    <h3 v-if="(type === 'confirm' || type === 'hint') && showTitle" class="confirm-title">
      <i v-if="showIcon" class="icon el-icon-warning"></i>
      {{ title || '提示' }}
    </h3>
    <p v-if="type === 'confirm' && showContent && content" class="confirm-content">
      {{ content }}
    </p>
    <slot></slot>
    <div class="dialog-footer" :type="type">
      <k-button v-if="type === 'confirm'" class="k-button btn-cancel" plain @click="cancel">
        {{ cancelText }}
      </k-button>
      <k-button v-if="type === 'hint'" class="k-button btn-cancel" plain @click="cancel">
        知道了
      </k-button>
      <k-button
        v-if="type !== 'hint'"
        class="k-button btn-sure"
        type="primary"
        :disabled="confirmDisabled"
        @click="sure"
      >
        {{ okText ? okText : '确定' }}
      </k-button>
    </div>
  </el-dialog>
</template>

<script>
import kButton from '@/common/components/button';

export default {
  name: 'KDialog',
  components: {
    kButton,
  },
  props: {
    value: Boolean,
    // 针对确认时可能需要校验的情况进行处理, 如果该方法执行返回false 则点击确认按钮时不关闭弹窗
    beforeClose: Function,
    // 如果确认时需先调接口，则须传该属性为true
    beforeCloseIsPromise: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'confirm',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    title: String,
    showTitle: Boolean,
    content: String,
    showContent: Boolean,
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    okText: String,
  },
  data() {
    return {
      dialogVisible: true,
    };
  },
  watch: {
    dialogVisible(newValue) {
      this.$emit('update:visible', newValue);
    },
    value(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    // handleClose(done) {
    //     this.$confirm('确认关闭？')
    //     .then(() => {
    //         done();
    //     })
    //     .catch(() => {});
    // },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    sure() {
      // 针对确认时可能需要校验的情况进行处理
      if (this.beforeClose && typeof this.beforeClose === 'function') {
        if (this.beforeCloseIsPromise) {
          return this.beforeClose().then((res) => {
            if (res) {
              this.close();
              this.$emit('sure');
            }
          });
        }
        if (!this.beforeClose()) return;
      }
      this.close();
      this.$emit('sure');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import './style.less';
</style>
<style lang="less">
.k-dialog.el-dialog__wrapper {
  .el-dialog__header {
    box-sizing: border-box;
    height: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding: 24px 24px 16px;
    border-radius: 8px;
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  .dialog-footer {
    font-size: 0;
    text-align: right;

    .k-button {
      margin-left: 32px;
    }
    // alert弹窗
    &[type='alert'] {
      .k-button {
        &[type='default'] {
          display: none;
        }
      }
    }
    // 提示弹窗
    &[type='hint'] {
      .k-button {
        &[type='primary'] {
          display: none;
        }
      }
    }
    // 确认弹窗
    &[type='confirm'] {
      .k-button {
      }
    }
  }
}

.k-dialog {
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-left: 0;

  > .el-dialog {
    top: 50%;
    width: 366px;
    height: auto;
    border-radius: 8px;
    transform: translateY(-50%);
  }
}
</style>
