import {
  ADD_HOMEWORK_QUESTION,
  REMOVE_HOMEWORK_QUESTION,
  ADD_HOMEWORK_QUESTION_INFO,
  REMOVE_ALL_HOMEWORK_QUESTION,
  REMOVE_HOMEWORK_QUESTION_INFO,
  PLUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH,
  MINUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH,
  INIT_HOMEWORK_ALL_KNOWLEDGE_QUESTION_LENGTH,
  CHANGE_HOMEWORK_QUESTION,
  CHANGE_HOMEWORK_QUESTION_ORDER,
  ADD_CURRENT_HOMEWORK_QUESTION,
  ORDER_HOMEWORK_QUESTION,
  CHANGE_ORDER_STATUS,
} from '../mutationTypes';

export default {
  [ADD_HOMEWORK_QUESTION](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    let questionList = [];
    for (let i in store.cart.questionMap) {
      if (store.cart.questionMap[i]) {
        questionList.push(store.cart.questionMap[i]);
      }
    }
    let choiceQuestionIds = questionList
      .filter(question => question.questionType === 1)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(choiceQuestion => choiceQuestion.id);
    let fillQuestionIds = questionList
      .filter(question => question.questionType === 4)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(fillQuestion => fillQuestion.id);
    let answerQuestionIds = questionList
      .filter(question => question.questionType === 5)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(answerQuestion => answerQuestion.id);
    let questionIds = [
      ...choiceQuestionIds,
      ...fillQuestionIds,
      ...answerQuestionIds,
    ];
    store.cart.questionIds = questionIds;
    if (newIds.length) {
      store.modifyScheme = true;
    }
  },
  [REMOVE_HOMEWORK_QUESTION](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.questionIds = store.cart.questionIds.filter(
      id => newIds.indexOf(id) === -1
    );
    store.modifyScheme = true;
  },
  [REMOVE_ALL_HOMEWORK_QUESTION](store) {
    store.cart.questionIds = [];
    store.modifyScheme = true;
  },
  [ADD_HOMEWORK_QUESTION_INFO](store, { id, item }) {
    store.cart.questionMap[id] = item;
  },
  [REMOVE_HOMEWORK_QUESTION_INFO](store, id) {
    delete store.cart.questionMap[id];
  },
  [PLUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH](store, ids) {
    let { selectedKnowledgeQuestionLengthMap } = store.cart;
    ids.forEach(id => {
      if (selectedKnowledgeQuestionLengthMap[id] === undefined) {
        selectedKnowledgeQuestionLengthMap[id] = 0;
      }
      selectedKnowledgeQuestionLengthMap[id] += 1;
    });
  },
  [MINUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH](store, ids) {
    let { selectedKnowledgeQuestionLengthMap } = store.cart;
    ids.forEach(id => {
      selectedKnowledgeQuestionLengthMap[id] -= 1;
    });
  },
  [INIT_HOMEWORK_ALL_KNOWLEDGE_QUESTION_LENGTH](store) {
    let { selectedKnowledgeQuestionLengthMap } = store.cart;
    Object.keys(selectedKnowledgeQuestionLengthMap).forEach(key => {
      delete selectedKnowledgeQuestionLengthMap[key];
    });
  },
  [CHANGE_HOMEWORK_QUESTION](store, { id, changeId }) {
    store.cart.questionIds.forEach((item, index) => {
      if (id === item) {
        store.cart.questionIds.splice(index, 1, changeId);
      }
    });
    store.modifyScheme = true;
  },
  [CHANGE_HOMEWORK_QUESTION_ORDER](store, { prevIndex, currentIndex }) {
    let questionIds = store.cart.questionIds;
    let cur = questionIds[prevIndex];
    let repObj = questionIds[currentIndex];
    // console.log('cur', prevIndex, cur, currentIndex, repObj);
    // console.log('before:', questionIds);
    questionIds.splice(currentIndex, 1, cur);
    questionIds.splice(prevIndex, 1, repObj);
    // console.log('after:', questionIds);
    store.modifyScheme = true;
  },
  [ADD_CURRENT_HOMEWORK_QUESTION](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.questionIds.forEach((item, index) => {
      if (ids === item) {
        store.cart.questionIds.splice(index, 1, ids);
      }
    });
    // store.cart.lessonIds.push(...newIds);
    if (newIds.length) {
      store.modifyScheme = true;
    }
  },
  [ORDER_HOMEWORK_QUESTION](store) {
    let questionList = [];
    let questionIds = store.cart.questionIds;
    questionIds.forEach(id => {
      store.cart.questionMap[id] &&
        questionList.push(store.cart.questionMap[id]);
    });
    let choiceQuestionIds = questionList
      .filter(question => question.questionType === 1)
      .map(choiceQuestion => choiceQuestion.id);
    let fillQuestionIds = questionList
      .filter(question => question.questionType === 4)
      .map(fillQuestion => fillQuestion.id);
    let answerQuestionIds = questionList
      .filter(question => question.questionType === 5)
      .map(answerQuestion => answerQuestion.id);
    questionIds = [
      ...choiceQuestionIds,
      ...fillQuestionIds,
      ...answerQuestionIds,
    ];
    store.cart.orderStatus = false;
    store.cart.questionIds = questionIds;
  },
  [CHANGE_ORDER_STATUS](store, payload) {
    store.cart.orderStatus = payload;
  },
};
