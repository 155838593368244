import { listSubjectWithOutOther } from '@/fetchs/aischool/ConfigFacade';

import { SET_HOMEWORK_SUBJECTLIST } from '../mutationTypes';

async function getSubjectList({ commit }, provinceId) {
  const subjectList = (await listSubjectWithOutOther({ provinceId })) || [];
  commit(SET_HOMEWORK_SUBJECTLIST, subjectList);
}

export default {
  getSubjectList,
};
