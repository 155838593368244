import Vue from 'vue';

import store from '@/store/index';

Vue.directive('role', {
  inserted(el, { value }) {
    if (process.env.npm_config_mock) return;
    //
    const permissionList = store.getters['user/getPermissions'];
    if (permissionList.indexOf(value) < 0 && value !== '') {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.prototype.$role = function (value) {
  if (process.env.npm_config_mock) return true;
  //
  const permissionList = store.getters['user/getPermissions'];
  return permissionList?.indexOf(value) > -1 ? true : false;
};
