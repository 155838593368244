/*
<el-table
  ref="resourceTable"
  v-loading="tableLoading"
  class="resource-table"
  :data="tableData"
  element-loading-text="数据正在加载中..."
  element-loading-spinner="el-icon-loading"
  row-key="resourceId"
  row_key="resourceId"
  :cell-style="tableCellStyle"
  :header-cell-style="tableHeaderCellStyle"
></el-table>
*/
/**
 * 新版表格 表头单元格样式，全局定义的方法，可直接使用
 * 使用方式 如上
 * http://static.xstable.kaikela.cn/kkl-ui/prod/550/web/1645412301291_76519132_441/index.html#s0 UI设计包子
 */
const tableHeaderCellStyle = ({ row, column, rowIndex, columnIndex }) => {
  let _headerCellStyle = {
    backgroundColor: '#FAFAFA',
    fontSize: '14px',
    color: '#333333',
    textAlign: 'left',
    borderBottom: 'none',
  };
  if (columnIndex === 0) {
    // 第一列特有样式
    _headerCellStyle.paddingLeft = '10px';
  }
  return _headerCellStyle;
};
/**
 * 新版表格 单元格样式，全局定义的方法，可直接使用
 * 使用方式 如上
 * http://static.xstable.kaikela.cn/kkl-ui/prod/550/web/1645412301291_76519132_441/index.html#s0 UI设计包子
 */
const tableCellStyle = ({ row, column, rowIndex, columnIndex }) => {
  let _cellStyle = {
    textAlign: 'left',
    color: '#666666',
    fontSize: '14px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #EEEEEE',
  };
  if (columnIndex === 0) {
    // 第一列特有样式
    _cellStyle.paddingLeft = '10px';
  }

  return _cellStyle;
};
export { tableCellStyle, tableHeaderCellStyle };
