/*
 * @Author: your name
 * @Date: 2022-02-28 17:15:34
 * @LastEditTime: 2022-02-28 17:15:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \kkl-online-school\src\store\modules\liveCourse\modules\course.js
 */
const state = {
  currentCourseInfo: {}
};

const mutations = {
  setCurrentCourseInfo(state, data) {
    state.currentCourseInfo = data;
  },
}

const store = {
  namespaced: true,
  state,
  mutations,
};

export default store;