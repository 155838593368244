import Vue, { defineComponent, ref } from 'vue';

import Dialog from './Dialog.vue';

export default function useDialog() {
  const showDialog = ref(false);

  let thenFn: (value: any) => void = () => {};
  let catchFn: () => void = () => {};

  const wrapper = document.createElement('div');

  const app = new Vue({
    render: (h) => {
      return h(Dialog, {
        props: {
          show: showDialog.value,
        },
        on: {
          success: thenFn,
          cancel: () => {
            catchFn();
            close();
          },
          'update:show': (v: boolean) => {
            showDialog.value = v;
          },
        },
      });
    },
  }).$mount();

  wrapper.appendChild(app.$el);
  document.body.appendChild(wrapper);

  const close = () => {
    document.body.removeChild(wrapper);
    app.$destroy();
  };

  const show = async () => {
    showDialog.value = true;
    return new Promise((resolve, reject) => {
      thenFn = resolve;
      catchFn = reject;
    });
  };

  return new Promise((resolve, reject) => {
    show()
      .then(() => {
        close();
        resolve(true);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
