import {
  ADD_HOMEWORK_SCHOOL_PAPER,
  REMOVE_HOMEWORK_SCHOOL_PAPER,
  ADD_HOMEWORK_SCHOOL_PAPER_INFO,
  REMOVE_ALL_HOMEWORK_SCHOOL_PAPER,
  REMOVE_HOMEWORK_SCHOOL_PAPER_INFO,
} from '../mutationTypes';

export default {
  [ADD_HOMEWORK_SCHOOL_PAPER](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.schoolPaperIds.push(...newIds);
    if (newIds.length) {
      store.modifyScheme = true;
    }
  },
  [REMOVE_HOMEWORK_SCHOOL_PAPER](store, ids) {
    let newIds = ids;
    if (!Array.isArray(ids)) {
      newIds = [ids];
    }
    store.cart.schoolPaperIds = store.cart.schoolPaperIds.filter(
      id => newIds.indexOf(id) === -1
    );
    store.modifyScheme = true;
  },
  [REMOVE_ALL_HOMEWORK_SCHOOL_PAPER](store) {
    store.cart.schoolPaperIds = [];
    store.modifyScheme = true;
  },
  [ADD_HOMEWORK_SCHOOL_PAPER_INFO](store, { id, item }) {
    store.cart.schoolPaperMap[id] = item;
  },
  [REMOVE_HOMEWORK_SCHOOL_PAPER_INFO](store, id) {
    delete store.cart.schoolPaperMap[id];
  },
};
