const regex = {
  realName:
    /^(\s)*([a-zA-Z\u4e00-\u9fa5]+[．.·•]{0,1}[a-zA-Z\u4e00-\u9fa5]+)+(\s)*$/,
  cell: /^[1][2-9][0-9]{9}$/,
  smsCode: /^\d{4}$/,
  password: /^[0-9a-zA-Z]{6,16}$/,
  url: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
};

export default regex;
