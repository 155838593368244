import newsIcon from '@/assets/nav-icon/news@2x-1.png';
import newsActiveIcon from '@/assets/nav-icon/news@2x-2.png';
import archiveIcon from '@/assets/nav-icon/布置记录1@2x.png';
import archiveActiveIcon from '@/assets/nav-icon/布置记录2@2x.png';
import managerIcon from '@/assets/nav-icon/教师管理1@2x.png';
import managerActiveIcon from '@/assets/nav-icon/教师管理2@2x.png';
import calendarIcon from '@/assets/nav-icon/校本资源库1@2x.png';
import calendarActiveIcon from '@/assets/nav-icon/校本资源库2@2x.png';
import noticeIcon from '@/assets/nav-icon/通知1@2x.png';
import noticeActiveIcon from '@/assets/nav-icon/通知2@2x.png';

export default [
  {
    path: '/managementCenter',
    name: 'managementCenter',
    redirect: '/managementCenter/stuArchive',
    component: () => import('@/views/management-center/index.vue'),
    meta: {
      role: 'manager',
      roleId: '11119',
      menuName: '管理中心',
    },
    children: [
      {
        path: 'stuArchive',
        name: 'stuArchive',
        redirect: '/managementCenter/stuArchive/examImport',
        component: () =>
          import('@/views/management-center/stu-archive/entry.vue'),
        meta: {
          role: 'manager.archive',
          roleId: '11206',
          menuName: '学生档案',
          hasSubmenu: true,
          icon: archiveIcon,
          activeIcon: archiveActiveIcon,
        },
        children: [
          {
            path: '',
            name: 'exam',
            redirect: '/managementCenter/stuArchive/examImport',
            component: () =>
              import('@/views/management-center/stu-archive/exam/entry.vue'),
            meta: {
              menuName: '成绩导入',
              role: 'manager.archive.exam',
              roleId: '11207',
            },
            children: [
              {
                path: 'examImport',
                name: 'import',
                component: () =>
                  import(
                    '@/views/management-center/stu-archive/exam/entry.vue'
                  ),
                meta: {
                  role: 'manager.archive.exam',
                  roleId: '11207',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '',
            name: 'honor',
            redirect: '/managementCenter/stuArchive/honorSetting',
            component: () =>
              import('@/views/management-center/stu-archive/honor/entry.vue'),
            meta: {
              menuName: '荣誉设置',
              role: 'manager.archive.honor',
              roleId: '11208',
            },
            children: [
              {
                path: 'honorSetting',
                name: 'setting',
                component: () =>
                  import(
                    '@/views/management-center/stu-archive/honor/entry.vue'
                  ),
                meta: {
                  role: 'manager.archive.honor',
                  roleId: '11208',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'stuNotice',
        name: 'stuNotice',
        component: () =>
          import('@/views/management-center/stu-notice/entry.vue'),
        redirect: '/managementCenter/stuNotice/list',
        meta: {
          role: 'manager.notice',
          roleId: '11120',
          menuName: '学生通知管理',
          icon: noticeIcon,
          activeIcon: noticeActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'stuNoticeList',
            component: () =>
              import('@/views/management-center/stu-notice/list/entry.vue'),
            meta: {
              role: 'manager.notice.list',
              roleId: '11121',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '/administration',
        name: 'teachingManage',
        // redirect: '/administration/teacherManage/index',
        component: () =>
          import('@/views/teaching-center/teaching-manage/entry.vue'),
        meta: {
          roleId: '11002',
          role: 'teachingCenter.manager',
          menuName: '教学管理',
          hasSubmenu: true,
          icon: managerIcon,
          activeIcon: managerActiveIcon,
        },
        children: [
          {
            path: 'teacherManage',
            name: 'teacherManage',
            redirect: '/administration/teacherManage/index',
            component: () => import('@/views/administration/entry.vue'),
            meta: {
              role: 'administrative.teacher',
              roleId: '103',
              menuName: '教师管理',
            },
            children: [
              {
                path: 'index',
                name: 'teacherManageIndex',
                component: () =>
                  import('@/views/administration/teacher-manage/entry.vue'),
                meta: {
                  role: 'administrative.teacher.mng',
                  roleId: '104',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'classManage',
            name: 'classManage',
            redirect: '/administration/classManage/classIndex',
            component: () =>
              import('@/views/administration/class-manage/entry.vue'),
            meta: {
              role: 'administrative.class',
              roleId: '105',
              menuName: '班级管理',
            },
            children: [
              {
                path: 'classIndex',
                name: 'classIndex',
                component: () =>
                  import(
                    '@/views/administration/class-manage/class-index/entry.vue'
                  ),
                meta: {
                  role: 'administrative.general.class',
                  roleId: '106',
                  hideInMenu: true,
                },
              },
              {
                path: 'groupIndex',
                name: 'groupIndex',
                component: () =>
                  import(
                    '@/views/administration/group-manage/group_index/entry.vue'
                  ),
                meta: {
                  role: 'administrative.online.class',
                  roleId: '107',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'weekStudy',
            name: 'weekStudy',
            redirect: '/administration/weekStudy/onlineClassList',
            component: () =>
              import('@/views/administration/week-study/entry.vue'),
            meta: {
              menuName: '开通OMO课程',
              roleId: '11009',
              role: 'teachingCenter.manager.openweektrain',
            },
            children: [
              {
                path: 'onlineClassList',
                name: 'onlineClassList',
                component: () =>
                  import(
                    '@/views/administration/week-study/online-class-list/entry.vue'
                  ),
                meta: {
                  hideInMenu: true,
                },
              },
              {
                path: 'onlineClassDetail',
                name: 'onlineClassDetail',
                component: () =>
                  import(
                    '@/views/administration/week-study/online-class-detail/entry.vue'
                  ),
                meta: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'headmasterEmail',
            name: 'headmasterEmail',
            redirect: '/administration/headmasterEmail/index',
            component: () =>
              import('@/views/administration/headmaster-email/entry.vue'),
            meta: {
              role: 'administrative.schoolmate.letterbox',
              roleId: '108',
              menuName: '校长信箱',
            },
            children: [
              {
                path: 'index',
                name: 'headmasterEmailIndex',
                component: () =>
                  import('@/views/administration/headmaster-email/entry.vue'),
                meta: {
                  role: 'administrative.schoolmate.letterbox.mng',
                  roleId: '109',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'seasonActivity',
        name: 'seasonActivity',
        component: () =>
          import('@/views/management-center/season-activity/entry.vue'),
        redirect: '/managementCenter/seasonActivity/index',
        meta: {
          role: 'manager.season',
          roleId: '11254',
          menuName: '赛季活动',
          icon: managerIcon,
          activeIcon: managerActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'seasonActivityHome',
            component: () =>
              import(
                '@/views/management-center/season-activity/home/entry.vue'
              ),
            meta: {
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'admission',
        name: 'admission',
        component: () =>
          import('@/views/management-center/admission/index.vue'),
        redirect: '/managementCenter/admission/sourceManagement',
        meta: {
          role: 'manager.admission',
          roleId: '11255',
          menuName: '育才优生体验工具',
          icon: managerIcon,
          activeIcon: managerActiveIcon,
        },
        children: [
          {
            path: 'sourceManagement',
            name: 'sourceManagement',
            component: () =>
              import(
                '@/views/management-center/admission/views/source-management/index.vue'
              ),
            redirect: '/managementCenter/admission/sourceManagement/index',
            meta: {
              role: 'manager.admission.course',
              roleId: '11274',
              menuName: '体验项目管理',
            },
            children: [
              {
                path: 'index',
                name: 'sourceManagementIndex',
                component: () =>
                  import(
                    '@/views/management-center/admission/views/source-management/views/index/index.vue'
                  ),
                meta: {
                  role: 'manager.admission.course.list',
                  roleId: '11283',
                  hideInMenu: true,
                },
              },
              {
                path: 'create-source',
                name: 'sourceManagementCreateSource',
                component: () =>
                  import(
                    '@/views/management-center/admission/views/source-management/views/create-source/index.vue'
                  ),
                meta: {
                  role: 'manager.admission.course.manager.create',
                  roleId: '11275',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'studentsDataLib',
            name: 'studentsDataLib',
            component: () =>
              import(
                '@/views/management-center/admission/views/students-data-lib/index.vue'
              ),
            redirect: '/managementCenter/admission/studentsDataLib/index',

            meta: {
              role: 'manager.admission.database',
              roleId: '11256',
              menuName: '学生数据总库',
            },
            children: [
              {
                path: 'index',
                name: 'studentsDataLibIndex',
                component: () =>
                  import(
                    '@/views/management-center/admission/views/students-data-lib/index.vue'
                  ),
                meta: {
                  hideInMenu: true,
                  role: 'manager.admission.database.list',
                  roleId: '11257',
                },
              },
            ],
          },
          {
            path: 'studentsDataManagement',
            name: 'studentsDataManagement',
            component: () =>
              import(
                '@/views/management-center/admission/views/students-data-management/index.vue'
              ),
            redirect:
              '/managementCenter/admission/studentsDataManagement/index',
            meta: {
              role: 'manager.admission.track',
              roleId: '11262',
              menuName: '学生数据管理',
            },
            children: [
              {
                path: 'index',
                name: 'studentsDataManagementIndex',
                component: () =>
                  import(
                    '@/views/management-center/admission/views/students-data-management/index.vue'
                  ),
                meta: {
                  hideInMenu: true,
                  role: 'manager.admission.track.list',
                  roleId: '11263',
                },
              },
            ],
          },
          {
            path: 'studentData',
            name: 'admissionStudentData',
            component: () =>
              import(
                '@/views/management-center/admission/views/student-data/views/index/entry.vue'
              ),
            meta: {
              role: 'manager.admission.student.detail',
              roleId: '11268',
              hideInMenu: true,
            },
          },
          {
            path: 'updateRecord',
            name: 'studentDataUpdateRecord',
            component: () =>
              import(
                '@/views/management-center/admission/views/student-data/views/historical-update-record/entry.vue'
              ),
            meta: {
              hideInMenu: true,
            },
          },
          {
            path: 'customLabelManagement',
            name: 'customLabelManagement',
            component: () =>
              import(
                '@/views/management-center/admission/views/custom-label-management/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: () => import('@/views/management-center/calendar/index.vue'),
        redirect: '/managementCenter/calendar/list',
        meta: {
          role: 'manager.group.notice',
          roleId: '11284',
          menuName: '教师文件管理',
          icon: calendarIcon,
          activeIcon: calendarActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'calendarList',
            component: () =>
              import('@/views/management-center/calendar/views/list/index.vue'),
            meta: {
              role: 'manager.group.notice.list',
              roleId: '11285',
              hideInMenu: true,
            },
          },
          {
            path: 'detail',
            name: 'calendarDetail',
            component: () =>
              import(
                '@/views/management-center/calendar/views/detail/index.vue'
              ),
            meta: {
              role: 'manager.group.notice.list',
              roleId: '11285',
              hideInMenu: true,
            },
          },
          {
            path: 'create',
            name: 'createCalendar',
            component: () =>
              import(
                '@/views/management-center/calendar/views/create/index.vue'
              ),
            meta: {
              role: 'manager.group.notice.list.publish.page',
              roleId: '11287',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'officialNews',
        name: 'officialNews',
        component: () =>
          import('@/views/management-center/official-news/entry.vue'),
        redirect: '/managementCenter/officialNews/index',
        meta: {
          role: 'manager.website.news',
          roleId: '',
          menuName: '官网新闻管理',
          icon: newsIcon,
          activeIcon: newsActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'officialNewsHome',
            component: () =>
              import('@/views/management-center/official-news/home/entry.vue'),
            meta: {
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];
