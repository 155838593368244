export const CHANGE_HOMEWORK_QUESTION_ORDER = 'CHANGE_HOMEWORK_QUESTION_ORDER'; // 调整试题顺序
export const ADD_HOMEWORK_QUESTION = 'ADD_HOMEWORK_QUESTION'; // 添加试题
export const REMOVE_HOMEWORK_QUESTION = 'REMOVE_HOMEWORK_QUESTION'; // 移除试题
export const REMOVE_ALL_HOMEWORK_QUESTION = 'REMOVE_ALL_HOMEWORK_QUESTION'; // 移除所有试题
export const ADD_HOMEWORK_QUESTION_INFO = 'ADD_HOMEWORK_QUESTION_INFO'; // 添加试题信息
export const REMOVE_HOMEWORK_QUESTION_INFO = 'REMOVE_HOMEWORK_QUESTION_INFO'; // 移除试题信息
export const PLUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH =
  'PLUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH'; // 增加知识点选中试题
export const MINUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH =
  'MINUS_HOMEWORK_KNOWLEDGE_QUESTION_LENGTH'; // 减少知识点选中试题数
export const INIT_HOMEWORK_ALL_KNOWLEDGE_QUESTION_LENGTH =
  'INIT_HOMEWORK_ALL_KNOWLEDGE_QUESTION_LENGTH'; // 初始化知识点选中试题数
export const CHANGE_HOMEWORK_QUESTION = 'CHANGE_HOMEWORK_QUESTION'; // 替换当前试题
export const ADD_CURRENT_HOMEWORK_QUESTION = 'ADD_CURRENT_HOMEWORK_QUESTION'; // 新增当前试题
export const ORDER_HOMEWORK_QUESTION = 'ORDER_HOMEWORK_QUESTION'; // 题型排序
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
